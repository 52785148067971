import { Subscription } from 'rxjs';
import { PageService } from './../../../services/page.service';
import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy
} from '@angular/core';
import { IEvent } from '../../../interfaces/event';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { UuidHelper } from '../../../helpers/uuid.helper';
import * as moment from 'moment';

@Component({
  selector: 'app-side-nav-events',
  templateUrl: './events.component.html',
  styleUrls: ['../../tiles.scss', '../list.scss', './events.component.scss']
})
export class SideNavEventsComponent implements OnInit, OnDestroy {
  public events: Observable<IEvent[]>;
  public allowAdd = false;

  private _uid: string;
  private _eventsSub: Subscription;

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    private _router: Router,
    private _db: AngularFireDatabase,
    public pageService: PageService
  ) {
    this.allowAdd = pageService.allowAdminEdit;
  }

  public ngOnInit() {
    this.events = this._db
      .list<IEvent>('events', ref => ref.orderByChild('startDate'))
      .valueChanges();
  }

  public ngOnDestroy(): void {
    if (this._eventsSub) this._eventsSub.unsubscribe();
  }

  public onClickEvent(event: IEvent): void {
    this._router.navigate(['events', event.id]);

    if (this.pageService.isMobile) {
      this.close.emit();
    }
  }

  public onClickNewEvent(): void {
    const newDate = moment.utc();
    const newEvent: IEvent = {
      id: UuidHelper.uuid(),
      name: 'New Event',
      description: '',
      portrait: '',
      startDate: newDate.toISOString(),
      endDate: newDate.toISOString(),
      players: [],
      cost: 0,
      npcCost: 0,
      memberDiscount: 0,
      status: 'Active',
      xpRewarded: false,
      length: 0
    };

    this._db.object('events/' + newEvent.id).set(newEvent);

    this._router.navigate(['events', newEvent.id]);

    if (this.pageService.isMobile) {
      this.close.emit();
    }
  }
}
