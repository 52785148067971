import { PaypalHelper } from './../../../helpers/paypal.helper';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { AccountService } from '../../../services/account.service';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { IAccountInfo } from '../../../interfaces/accountInfo';
import FirebaseAuth from 'firebase/auth';
import { MembershipDialogComponent } from '../../membership-dialog/membership-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-side-nav-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['../../section.scss', '../../button.scss', './profile.component.scss']
})
export class SideNavProfileComponent implements OnInit, OnDestroy {
  public accountInfo: IAccountInfo;
  public profileDirty = false;
  public subscriptionStatus = '';
  public membershipTypeText = 'None';
  public nextBillingDate: Date;
  public lastPaidDate: Date;

  private _user: FirebaseAuth.User;
  private _accountRef: AngularFireObject<IAccountInfo>;
  private _dialogSub: Subscription;

  constructor(private _authService: AuthService, private _accountService: AccountService, private _db: AngularFireDatabase, private _dialog: MatDialog) { }

  ngOnInit() {
    this._accountService.accountInfo.subscribe(info => {
      this.accountInfo = info;
    });

    this._accountService.paypalSubscription.subscribe(paypalSub => {
      if (paypalSub) {
        const isActive = paypalSub.status === 'ACTIVE';
        this.subscriptionStatus = isActive ? 'Active' : 'None';

        if (isActive) {
          this.membershipTypeText = PaypalHelper.getMembershipNameByPlanId(paypalSub.plan_id);
        } else {
          this.membershipTypeText = 'None';
        }

        if (paypalSub.billing_info) {
          this.nextBillingDate = new Date(paypalSub.billing_info.next_billing_time);
          this.lastPaidDate = new Date(paypalSub.billing_info.last_payment.time);
        }
      }
    });

    this._authService.user.subscribe(user => {
      this._user = user;
    });
  }

  ngOnDestroy() {
    if (this._dialogSub) this._dialogSub.unsubscribe();
  }

  public onClickSaveAccountInfo(): void {
    this._accountRef = this._db.object('accounts/' + this._user.uid);
    this._accountRef.update(this.accountInfo).then(() => {
      this.profileDirty = false;
    });
  }

  public onClickMembership(): void {
    const dialogRef = this._dialog.open(MembershipDialogComponent, {
      width: '350px',
      height: '300px',
      data: {},
      panelClass: 'crux-dialog'
    });

    this._dialogSub = dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // Membership close here
      }
    });
  }

  public markProfileDirty(): void {
    this.profileDirty = true;
  }
}
