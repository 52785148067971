import { IProfession } from './../../../interfaces/profession';
import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip-profession',
  templateUrl: './profession.component.html',
  styleUrls: ['./profession.component.scss']
})
export class TooltipProfessionComponent implements OnInit, OnChanges {
  @Input() profession: IProfession;
  @Input() data: any;

  constructor() { }

  ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['profession']) {
    }
  }

}
