import { Component, OnInit, Input } from '@angular/core';
import * as marked from 'marked';

@Component({
  selector: 'app-tooltip-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss']
})
export class TooltipDescriptionComponent implements OnInit {
  @Input() data: any;
  @Input() public title: string;
  @Input() public description: string;

  public markedDescription: string;

  constructor() { }

  ngOnInit() {
    this.markedDescription = marked(this.description);
  }

}
