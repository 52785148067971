import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { IPrintSkill } from '../print.component';
import { SkillHelper } from '../../../../../helpers/skill.helper';

@Component({
  selector: 'app-character-card-print-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['../../../../../../print.css', './skill.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardPrintSkillComponent implements OnInit, OnChanges {
  @Input() printSkill: IPrintSkill;

  public verbalText = '';
  public poolText = '';
  public perText = '';
  public count = 0;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['printSkill'] && changes['printSkill'].currentValue) {
      if (this.printSkill.skill.verbal) {
        this.verbalText = SkillHelper.getVerbalText(this.printSkill.skill, this.printSkill.count);
      }

      if (this.printSkill.skill.pool) {
        this.poolText = SkillHelper.getPoolText(this.printSkill.skill, this.printSkill.count);
      }

      switch (this.printSkill.skill.actionType.toLowerCase()) {
        case 'once per reset':
          this.count = 1;
          break;

        case 'once per event':
          this.perText = 'e';
          this.count = 1;
          break;

        case 'passive':
        case 'pooled':
        case 'at-will':
          this.count = 0;
          break;

        default:
          this.count = this.printSkill.count;
          break;
      }
    }
  }

  public markForCheck(): void {
    this._cdr.markForCheck();
  }
}
