import { Pipe, PipeTransform } from '@angular/core';
import { IAttribute } from '../interfaces/attribute';

@Pipe({
  name: 'attribute'
})
export class AttributePipe implements PipeTransform {

  transform(attributes: string[], allAttributes: IAttribute[]): any {
    return allAttributes.filter(attribute => attributes.includes(attribute.id));
  }

}
