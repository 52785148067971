import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-character-card-print-skill-shield',
  templateUrl: './skill-shield.component.html',
  styleUrls: ['../../../../../../print.css', './skill-shield.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardPrintSkillShieldComponent implements OnInit {
  @Input() text: string;
  @Input() subText: string;
  @Input() bannerText: string;
  @Input() isLower: boolean;
  @Input() isLower2: boolean;
  @Input() isLeft: boolean;
  @Input() isRight: boolean;

  constructor(private _cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  public markForCheck(): void {
    this._cdr.markForCheck();
  }

}
