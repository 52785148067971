import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-tile-handbook',
  templateUrl: './handbook.component.html',
  styleUrls: ['./handbook.component.scss', '../../home.component.scss', '../../../tiles.scss', '../../../toolbar.scss', '../../../page.scss', '../../../button.scss']
})
export class HomeTileHandbookComponent implements OnInit {

  constructor(private _router: Router) { }

  ngOnInit() {
  }

  public onClickHandbook(): void {
    this._router.navigate(['handbook']);
  }
}
