import { ProfessionsService } from './../../../../../services/professions.service';
import { CharacterCardProfessionSlotComponent } from './../slot/slot.component';
import { IProfession } from './../../../../../interfaces/profession';
import { Component, OnInit, ViewChildren, QueryList, ChangeDetectorRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { Character } from '../../../../../interfaces/character';
import { IProfessionTier } from '../profession.component';

@Component({
  selector: 'app-character-card-profession-slot-dropdown',
  templateUrl: './slot-dropdown.component.html',
  styleUrls: ['./slot-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardProfessionSlotDropdownComponent implements OnInit {
  @Input() profTier: IProfessionTier;
  public professions: IProfession[];

  public character: Character;
  private _parent: any;

  @ViewChildren(CharacterCardProfessionSlotComponent) slotComponents: QueryList<CharacterCardProfessionSlotComponent>;

  constructor(private _cdr: ChangeDetectorRef, private _profsService: ProfessionsService) { }

  ngOnInit() { }

  public updateCharacter(character: Character, parent: any): void {
    this.character = character;
    this._parent = parent;

    this.initProfessions();
    this._cdr.detectChanges();

    this.updateSlots();
    this.markForCheck();
  }

  private initProfessions(): void {
    if (!this.character) return;

    this.professions = [];
    if (this.profTier.tier === 1) {
      this.professions.push(...this._profsService.allProfessions.filter(p => p.tier === 1));
    } else {
      const prevCharProf = this.character.data.professions.find(p => p.tier === this.profTier.tier - 1);
      if (prevCharProf) {
        const prevProf = this._profsService.allProfessions.find(p => p.id === prevCharProf.professionId);
        if (prevProf) {
          this.professions.push(...this._profsService.allProfessions.filter(p => prevProf.subProfessions.includes(p.id)));
        }
      }
    }
  }

  private updateSlots(): void {
    this.slotComponents.forEach(slot => {
      slot.updateCharacter(this.character, this);
    });
  }

  private checkSlots(): void {
    this.slotComponents.forEach(slot => {
      slot.markForCheck();
    });
  }

  public markForCheck(): void {
    this.initProfessions();
    this._cdr.detectChanges();

    this.checkSlots();
    this._cdr.markForCheck();
  }

  public updateProfessions(): void {
    this._parent.updateProfessions();
  }

  public changeProfession(): void {
    this._parent.changeProfession();
  }
}
