import { SkillsService } from './../services/skills.service';
import { Pipe, PipeTransform } from '@angular/core';
import { ISkill } from '../interfaces/skill';

@Pipe({
  name: 'skills'
})
export class SkillsPipe implements PipeTransform {
  constructor(private _skillsService: SkillsService) {
  }

  transform(skillIds: string[]): Promise<ISkill[]> {
    return new Promise<ISkill[]>((resolve, reject) => {
      this._skillsService.getAll().then(skills => {
        resolve(skills.filter(skill => skillIds.includes(skill.id)));
      }, err => reject(err));
    });
  }

}
