import { Injectable, OnDestroy } from '@angular/core';
import { IAccountInfo } from '../interfaces/accountInfo';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable()
export class AccountService implements OnDestroy {
  public accountInfo: BehaviorSubject<IAccountInfo> = new BehaviorSubject<IAccountInfo>(null);
  public paypalSubscription: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public isMember = false;

  private _sub: Subscription;

  constructor() {
    this._sub = this.paypalSubscription.subscribe(paypalSub => {
      this.isMember = paypalSub?.status === 'ACTIVE';
    });
  }

  public ngOnDestroy(): void {
    if (this._sub) this._sub.unsubscribe();
  }
}
