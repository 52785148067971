import { UuidHelper } from './../../../helpers/uuid.helper';
import { IEvent } from './../../../interfaces/event';
import { Subscription, Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { PageService } from './../../../services/page.service';
import { AuthService } from './../../../services/auth.service';
import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-drop-menu-events',
  templateUrl: './events.component.html',
  styleUrls: [
    '../../tiles.scss',
    '../../button.scss',
    './events.component.scss'
  ]
})
export class DropMenuEventsComponent implements OnInit, AfterViewInit {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild('eventsContainer', { static: false }) eventsElement: ElementRef;

  public events: Observable<IEvent[]>;
  public eventColumns = 1;

  private _minEventWidth = 250; // In pixels

  constructor(
    private _router: Router,
    private _authService: AuthService,
    public pageService: PageService,
    private _db: AngularFireDatabase
  ) { }

  ngOnInit() {
    const uid = this._authService.user.subscribe(user => {
      if (user) {
        this.events = this._db
          .list<IEvent>('events', ref => ref.orderByChild('startDate'))
          .valueChanges();
      }
    });
  }

  ngAfterViewInit() {
    this.calculateEventColumns();
  }

  private calculateEventColumns(): void {
    const currentWidth = parseInt(
      this.eventsElement.nativeElement['offsetWidth'],
      10
    );

    setTimeout(() => {
      this.eventColumns = Math.trunc(currentWidth / this._minEventWidth);
    }, 1);
  }

  public onResizeEvents(): void {
    this.calculateEventColumns();
  }

  public onClickEvent(event: IEvent): void {
    this._router.navigate(['events', event.id]);
    this.close.emit();
  }

  public onClickNewEvent(): void {
    const newDate = moment.utc();
    const newEvent: IEvent = {
      id: UuidHelper.uuid(),
      name: 'New Event',
      description: '',
      portrait: '',
      startDate: newDate.toISOString(),
      endDate: newDate.toISOString(),
      players: [],
      cost: 0,
      npcCost: 0,
      memberDiscount: 0,
      status: 'Active',
      xpRewarded: false,
      length: 0
    };

    this._db.object('events/' + newEvent.id).set(newEvent);

    this._router.navigate(['events', newEvent.id]);
    this.close.emit();
  }
}
