import { Component, OnInit, Input, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { IPrintSkill } from '../print.component';

@Component({
  selector: 'app-character-card-print-profession-group',
  templateUrl: './profession-group.component.html',
  styleUrls: ['../../../../../../print.css', './profession-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardPrintProfessionGroupComponent implements OnInit {
  @Input() professionName: string;

  constructor(private _cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {}

  public markForCheck(): void {
    this._cdr.markForCheck();
  }
}
