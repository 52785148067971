import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['../tiles.scss', './modal-dialog.component.scss']
})
export class ModalDialogComponent {
  public model: IModalDialogModel;

  constructor(
    public dialogRef: MatDialogRef<ModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.model = data;
  }

  public onClickOk(): void {
    this.dialogRef.close();
  }
}

export interface IModalDialogModel {
  title: string;
  text: string;
}
