import { IChatPlayerInfo, IChatChannel, IInvitePlayerData } from './../../../../interfaces/chat';
import { PlayersService } from './../../../../services/players.service';
import { Component, OnInit, ElementRef, ViewChild, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PageService } from '../../../../services/page.service';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { Subject, Subscription, Observable } from 'rxjs';
import { ChatService } from '../../../../services/chat.service';
import { AuthService } from '../../../../services/auth.service';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-invite-player',
  templateUrl: './invite-player.component.html',
  styleUrls: ['../../../section.scss', '../../../tiles.scss', '../../../character.scss', '../../../button.scss', './invite-player.component.scss']
})
export class InvitePlayerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filter', { static: false }) filterElementRef: ElementRef;

  public uid: string;

  private _allPlayers: IChatPlayerInfo[] = [];
  public players: IInvitePlayerData[] = [];
  public owners: IChatPlayerInfo[] = [];

  private _filterUpdate: Subject<string> = new Subject<string>();
  private _filterObs: Observable<string> = this._filterUpdate.asObservable();

  private _channelRef: AngularFireObject<IChatChannel>;
  private _channelSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<InvitePlayerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IChatChannel,
    private _db: AngularFireDatabase,
    public pageService: PageService,
    public playersService: PlayersService,
    private _chatService: ChatService,
    private _authService: AuthService
  ) { }

  ngOnInit() {
    this._authService.user.subscribe(user => {
      if (user) {
        this.uid = user.uid;

        this._channelRef = this._db.object('chat/channels/' + this._chatService.indexOfChannel(this._chatService.activeChannelName));
        this._channelSub = this._channelRef.valueChanges().subscribe(channel => {
          this.data = channel;
          this.getPlayerData();
        });
      }
    });

    this._filterObs.pipe(debounceTime(300)).subscribe(value => {
      const filter = value.trim().toLowerCase();
      const groupedPlayers = this.groupedPlayers();
      if (filter.length > 1) {
        this.players = groupedPlayers.filter(p => p.displayName.toLowerCase().indexOf(filter) > -1);
      } else {
        this.players = groupedPlayers;
      }
    });
  }

  public ngAfterViewInit(): void {
    this.filterElementRef.nativeElement.focus();
  }

  public ngOnDestroy(): void {
    if (this._channelSub) this._channelSub.unsubscribe();
  }

  private getPlayerData(): void {
    this.playersService.getAllProfilesForChat().then(players => {
      this._allPlayers = players; // .filter(u => u.uid !== this.uid);
      this.players = this.groupedPlayers();
      this.owners = this._allPlayers.filter(p => this._chatService.isChannelOwner(this.data, p.uid));
    });
  }

  private groupedPlayers(): IInvitePlayerData[] {
    const channelName = this._chatService.getChannelName(this.data.name);
    const mappedPlayers = this._allPlayers.filter(p => {
      return !!p.displayName;
    }).filter(p => !this._chatService.isChannelOwner(this.data, p.uid)).map(p => {
      let invited = false;
      if (p.invites && p.invites[channelName] && moment.utc().isSameOrBefore(p.invites[channelName].expirationDate)) {
        invited = true;
      }

      return {
        uid: p.uid,
        displayName: p.displayName,
        avatarUrl: p.avatarUrl,
        channels: p.channels,
        invited: invited,
        isOwner: this._chatService.isChannelOwner(this.data, p.uid),
        isModerator: this._chatService.isChannelModerator(this.data, p.uid),
        isMember: p.channels && p.channels.includes(channelName)
      };
    }) as IInvitePlayerData[];

    const moderators = mappedPlayers.filter(p => p.invited && p.isModerator).sort(this.sortByName);
    const members = mappedPlayers.filter(p => p.invited && !p.isModerator).sort(this.sortByName);
    const nonMembers = mappedPlayers.filter(p => !p.invited).sort(this.sortByName);
    return [...moderators, ...members, ...nonMembers];
  }

  private sortByName(a: IInvitePlayerData, b: IInvitePlayerData): number {
    const aName = a?.displayName?.toLowerCase() ?? '';
    const bName = b?.displayName?.toLowerCase() ?? '';

    if (aName > bName) return 1;
    if (aName < bName) return -1;
    return 0;
  }

  public onClickClose(): void {
    this.dialogRef.close();
  }

  public onInvitePlayer(player: IInvitePlayerData): void {
    this._chatService.invitePlayerToChannel(this.data, player.uid).then(channel => {
      this.data = channel;
      this.getPlayerData();
    });
  }

  public onUninvitePlayer(player: IInvitePlayerData): void {
    this._chatService.uninvitePlayerFromChannel(this.data, player.uid).then(channel => {
      this.data = channel;
      this.getPlayerData();
    });
  }

  public onRemovePlayer(player: IInvitePlayerData): void {
    this._chatService.removePlayerFromChannel(this.data, player.uid).then(channel => {
      this.data = channel;
    });
  }

  public onToggleModerator(player: IInvitePlayerData): void {
    if (this._chatService.isChannelModerator(this.data, player.uid)) {
      this._chatService.removePlayerAsModeratorFromChannel(this.data, player.uid).then(channel => {
        this.data = channel;
      });
    } else {
      this._chatService.addPlayerAsModeratorToChannel(this.data, player.uid).then(channel => {
        this.data = channel;
      });
    }
  }

  public onKeyupFilter(value: string): void {
    this._filterUpdate.next(value);
  }
}
