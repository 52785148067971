import { SpellsService } from './../../../services/spells.service';
import { ProfessionsService } from './../../../services/professions.service';
import { SpellSlotsService } from './../../../services/spell-slots.service';
import { SkillsService } from './../../../services/skills.service';
import { Subscription } from 'rxjs';
import { Character, ICharacter } from './../../../interfaces/character';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { IEvent, IEventPlayer } from './../../../interfaces/event';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { OriginsService } from '../../../services/origins.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-event-register-dialog',
  templateUrl: './register.component.html',
  styleUrls: ['../../section.scss', '../../tiles.scss', '../../character.scss', '../../button.scss', './register.component.scss']
})
export class EventRegisterDialogComponent implements OnInit, OnDestroy {
  public eventPlayer: IEventPlayer;
  public event: IEvent;

  public activeCharacterIndex = -1;
  public characters: Character[];

  private _isNew = false;
  private _eventPlayerRef: AngularFireObject<IEventPlayer>;

  private _charactersSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<EventRegisterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEventRegisterData,
    private _db: AngularFireDatabase,
    private _skillsService: SkillsService,
    private _spellSlotService: SpellSlotsService,
    private _spellsService: SpellsService,
    private _professionsService: ProfessionsService,
    private _originsService: OriginsService
  ) { }

  ngOnInit() {
    this.event = this.data.event;
    this.eventPlayer = this.data.eventPlayer;

    this._eventPlayerRef = this._db.object('events/' + this.event.id + '/players/' + this.data.uid);

    if (!this.eventPlayer) {
      this.eventPlayer = {
        playerUid: this.data.uid,
        characterId: '',
        dateRegistered: moment.utc().toISOString(),
        dateUpdated: moment.utc().toISOString(),
        datePaid: null,
        paidValue: 0,
        notes: '',
        housing: '',
        food: '',
        npcShift: false,
        status: 'Active'
      };

      this._isNew = true;
    }

    this._charactersSub = this._db
      .list<ICharacter>('characters/' + this.data.uid, ref => ref.orderByChild('name'))
      .valueChanges()
      .pipe(take(1))
      .subscribe(chars => {
        const list: Character[] = [];
        chars.forEach(char => {
          list.push(new Character(char, this._skillsService, this._spellSlotService, this._spellsService, this._professionsService, this._originsService));
        });

        this.characters = list;
        if (this.characters.length > 0) {
          this.activeCharacterIndex = 0;
          this.eventPlayer.characterId = this.characters[this.activeCharacterIndex].data.id;
        }
      });
  }

  public ngOnDestroy(): void {
    if (this._charactersSub) this._charactersSub.unsubscribe();
  }

  public onClickRegister(): void {
    let regPromise: Promise<any>;
    if (this._isNew) {
      regPromise = this._eventPlayerRef.set(this.eventPlayer).then(nep => {
        if (!this.event.players) this.event.players = [];
        this.event.players[this.data.uid] = this.eventPlayer;
      });
    } else if (this.eventPlayer.status !== 'Active') {
      this.eventPlayer.status = 'Active';
      this.eventPlayer.dateUpdated = moment.utc().toISOString();
      regPromise = this._eventPlayerRef.update(this.eventPlayer);
    }

    regPromise.then(results => {
      const dataResult: IEventRegisterDataResult = {
        event: this.event,
        eventPlayer: this.eventPlayer
      };

      const characterRef = this._db.object('characters/' + this.data.uid + '/' + this.eventPlayer.characterId);
      characterRef.valueChanges().pipe(take(1)).subscribe((character: ICharacter) => {
        if (!character.events) character.events = [];

        const charEvent = character.events.find(e => e.eventId === this.event.id);
        if (!charEvent) {
          character.events.push({ eventId: this.event.id });
          characterRef.update(character).then(() => {
            this.dialogRef.close(dataResult);
          });
        } else {
          this.dialogRef.close(dataResult);
        }
      });
    });
  }

  public onClickCancel(): void {
    this.dialogRef.close();
  }


  public onClickPrevious(): void {
    if (--this.activeCharacterIndex < 0) {
      this.activeCharacterIndex = this.characters.length - 1;
    }

    this.eventPlayer.characterId = this.characters[this.activeCharacterIndex].data.id;
  }

  public onClickNext(): void {
    if (++this.activeCharacterIndex >= this.characters.length) {
      this.activeCharacterIndex = 0;
    }

    this.eventPlayer.characterId = this.characters[this.activeCharacterIndex].data.id;
  }
}

export interface IEventRegisterData {
  uid: string;
  event: IEvent;
  eventPlayer: IEventPlayer;
}

export interface IEventRegisterDataResult {
  event: IEvent;
  eventPlayer: IEventPlayer;
}
