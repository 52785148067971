import { SpellsService } from './../../../services/spells.service';
import { FindCharacterDialogComponent } from './../../find-character-dialog/find-character-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UuidHelper } from './../../../helpers/uuid.helper';
import { ProfessionsService } from './../../../services/professions.service';
import { ICharacter, Character } from './../../../interfaces/character';
import { Subscription } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AuthService } from './../../../services/auth.service';
import { PageService } from './../../../services/page.service';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';
import { SkillsService } from '../../../services/skills.service';
import { SpellSlotsService } from '../../../services/spell-slots.service';
import { OriginsService } from '../../../services/origins.service';
import { AccountService } from '../../../services/account.service';
import { NewCharacterDialogComponent } from '../../new-character-dialog/new-character-dialog.component';

@Component({
  selector: 'app-drop-menu-characters',
  templateUrl: './characters.component.html',
  styleUrls: [
    '../../tiles.scss',
    '../../button.scss',
    './characters.component.scss'
  ]
})
export class DropMenuCharactersComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() preventClose: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('charactersContainer', { static: false }) charactersElement: ElementRef;

  public characters: Character[] = [];
  public characterColumns = 1;
  public viewStatus = 'Active';
  public maxCharacters = 0;

  private _allCharacters: Character[] = [];
  private _minCharacterWidth = 250; // In pixels
  private _charactersSub: Subscription;
  private _authSub: Subscription;
  private _dialogCloseSub: Subscription;
  private _accountSub: Subscription;
  private _uid: string;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _accountService: AccountService,
    public pageService: PageService,
    private _db: AngularFireDatabase,
    private _skillsService: SkillsService,
    private _spellSlotService: SpellSlotsService,
    private _spellsService: SpellsService,
    private _professionsService: ProfessionsService,
    private _originsService: OriginsService,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    this._authSub = this._authService.user.subscribe(user => {
      if (user) {
        this._uid = user.uid;
        this._charactersSub = this._db
          .list<ICharacter>('characters/' + user.uid, ref =>
            ref.orderByChild('name')
          )
          .valueChanges()
          .subscribe(chars => {
            const list: Character[] = [];
            chars.forEach(char => {
              const character = new Character(
                char,
                this._skillsService,
                this._spellSlotService,
                this._spellsService,
                this._professionsService,
                this._originsService
              );
              list.push(character);
            });

            this._allCharacters = list;
            this.characters = this._allCharacters.filter(
              c => c.data.status === this.viewStatus
            );
          });
      }
    });

    this._accountSub = this._accountService.accountInfo.subscribe(info => {
      if (info) {
        this.maxCharacters = info.profile.maxCharacters;
      }
    });
  }

  public ngAfterViewInit(): void {
    this.calculateCharacterColumns();
  }

  public ngOnDestroy(): void {
    if (this._authSub) this._authSub.unsubscribe();
    if (this._charactersSub) this._charactersSub.unsubscribe();
    if (this._dialogCloseSub) this._dialogCloseSub.unsubscribe();
    if (this._accountSub) this._accountSub.unsubscribe();
  }

  private calculateCharacterColumns(): void {
    const currentWidth = parseInt(
      this.charactersElement.nativeElement['offsetWidth'],
      10
    );
    setTimeout(() => {
      this.characterColumns = Math.trunc(currentWidth / this._minCharacterWidth);
    }, 1);
  }

  public onResizeCharacters(): void {
    this.calculateCharacterColumns();
  }

  public onClickCharacter(character: Character): void {
    this._router.navigate(['characters', character.data.id, this._uid]);
    this.close.emit();
  }

  public onClickNewCharacter(type: string): void {
    if (this.characters.length >= this.maxCharacters) return;

    this.preventClose.emit(true);
    const dialogRef = this._dialog.open(NewCharacterDialogComponent, {
      width: '350px',
      height: '156px',
      panelClass: 'crux-dialog',
      data: {
        characters: this._allCharacters
      }
    });

    this._dialogCloseSub = dialogRef.afterClosed().subscribe(async characterName => {
      // Yes, we have confirmed a delete request
      if (characterName) {
        const defaultOrigin = await this._originsService.get('human');

        const newCharacterData: ICharacter = {
          id: UuidHelper.uuid(),
          type: type,
          name: characterName,
          fullName: '',
          background: '',
          team: '',
          status: 'Active',
          portrait: 'data/origins/' + defaultOrigin.portraits[0],
          xp: 80,
          events: [],
          xpLog: [],
          vitality: 1,
          stamina: 1,
          arcane: 1,
          agility: 1,
          spirit: 1,
          originId: 'human',
          racialBonusAttributes: ['vitality'],
          skills: [],
          spellSlots: [],
          professions: [],
          crafting: {
            skills: [],
            lore: [],
            talents: []
          },
          archiveDate: '',
          isLocked: false
        };

        const newCharacter = new Character(
          newCharacterData,
          this._skillsService,
          this._spellSlotService,
          this._spellsService,
          this._professionsService,
          this._originsService
        );

        this._db
          .object('characters/' + this._uid + '/' + newCharacterData.id)
          .set(newCharacter.data);

        this.close.emit();
        this._router.navigate(['characters', newCharacterData.id, this._uid]);
      }

      this.preventClose.emit(false);
    });
  }

  public onClickChangeViewStatus(status: string): void {
    this.viewStatus = status;
    this.characters = this._allCharacters.filter(
      c => c.data.status === this.viewStatus
    );
  }

  public onClickFindPlayerCharacter(): void {
    this.preventClose.emit(true);
    const dialogRef = this._dialog.open(FindCharacterDialogComponent, {
      width: '400px',
      height: '600px',
      data: null,
      panelClass: 'crux-dialog'
    });

    this._dialogCloseSub = dialogRef.afterClosed().subscribe(data => {
      // Yes, we have confirmed a delete request
      if (data) {
        this.close.emit();
        this._router.navigate(['characters', data.characterId, data.uid]);
      } else {
        this.preventClose.emit(false);
      }
    });
  }
}
