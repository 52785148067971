import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MobileNavService {
  private _chatToggle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public chatToggle: Observable<boolean> = this._chatToggle.asObservable();

  private _charToggle: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public charToggle: Observable<boolean> = this._charToggle.asObservable();

  constructor() { }

  public toggleChat(value: boolean): void {
    this._chatToggle.next(value);
  }

  public toggleChar(value: boolean): void {
    this._charToggle.next(value);
  }
}
