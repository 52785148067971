import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { CharacterCardPrintSkillComponent } from '../skill/skill.component';

@Component({
  selector: 'app-character-card-print-skill-group',
  templateUrl: './skill-group.component.html',
  styleUrls: ['../../../../../../print.css', './skill-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardPrintSkillGroupComponent implements OnInit {
  @Input() skillGroup: any;
  @Input() headerText: string;
  @Input() isLower: boolean;

  @ViewChildren(CharacterCardPrintSkillComponent) skillShieldComponents: QueryList<CharacterCardPrintSkillComponent>;

  constructor(private _cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  public markForCheck(): void {
    this._cdr.markForCheck();

    this.skillShieldComponents.forEach(comp => {
      comp.markForCheck();
    });
  }
}
