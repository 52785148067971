import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-tile-questline',
  templateUrl: './questline.component.html',
  styleUrls: ['./questline.component.scss', '../../home.component.scss', '../../../tiles.scss', '../../../toolbar.scss', '../../../page.scss', '../../../button.scss']
})
export class HomeTileQuestlineComponent implements OnInit {

  constructor(private _router: Router) { }

  ngOnInit() {
  }

  public onClick(): void {
    window.open('https://questlinevtt.com', '_blank').focus();
  }
}
