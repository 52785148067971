import { ISkill } from '../interfaces/skill';
import { JsonService } from './json.service';
import { Injectable } from '@angular/core';
import { CraftingType, ICraftingSkill } from '../interfaces/crafting';

@Injectable()
export class CraftingService {
  private _allSkills: ICraftingSkill[] = [];

  private _loading = false;

  constructor(private _jsonService: JsonService) { }

  public getAll(): Promise<ICraftingSkill[]> {
    return new Promise((resolve, reject) => {
      if (this._loading) {
        setTimeout(() => {
          resolve(this.getAll());
        }, 100);
      } else {
        if (this._allSkills.length === 0) {
          this._loading = true;
          this._jsonService.get('crafting').then(crafting => {
            this._allSkills = crafting;
            this._loading = false;
            resolve(crafting);
          });
        } else {
          resolve(this._allSkills);
        }
      }
    });
  }

  public get(id: string): Promise<ICraftingSkill> {
    return new Promise((resolve, reject) => {
      if (this._loading) {
        setTimeout(() => {
          resolve(this.get(id));
        }, 100);
      } else {
        if (this._allSkills.length === 0) {
          this._jsonService.get('crafting').then(crafting => {
            this._allSkills = crafting;
            resolve(this.find(id));
          });
        } else {
          resolve(this.find(id));
        }
      }
    });
  }

  private find(id: string): ICraftingSkill {
    return this._allSkills.find(r => r.id === id && r.type === CraftingType.Skill);
  }
}
