import { IChatChannel, IChatMessage, IChannelMessages, IPlayerInvite, IInvitePlayerData, IAccountChatTimestampData } from './../interfaces/chat';
import { IAccountInfo, IAccountChatData } from './../interfaces/accountInfo';
import { AngularFireDatabase, AngularFireObject, AngularFireList } from '@angular/fire/compat/database';
import { AccountService } from './account.service';
import { AuthService } from './auth.service';
import { Injectable, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import firebaseAuth from 'firebase/auth';
import { take } from 'rxjs/operators';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable()
export class ChatService implements AfterViewInit, OnDestroy {
  public accountInfo: IAccountInfo;

  private _user: firebaseAuth.User;
  private _accountRef: AngularFireObject<IAccountInfo>;

  public activeChannel: IChatChannel;
  public isActiveModerator = false;
  public isActiveOwner = false;

  private _channelsRef: AngularFireObject<IChatChannel[]>;
  public channels: IChatChannel[] = [];

  private _messagesRef: any = {};
  private _entriesRefs: any = {};

  public timestamps: any = {};

  private _accountSub: Subscription;
  private _userSub: Subscription;
  private _channelsSub: Subscription;
  private _timestampSub: Subscription;
  private _entriesSubs: any = {};

  public entriesSnaps: any = {};
  public entriesCounts: any = {};
  public entriesCountsLast: any = {};
  public entriesCountsCurrent: any = {};

  private _newChatSoundAway: HTMLAudioElement;
  private _newChatSoundActive: HTMLAudioElement;
  private _lastChatSoundTime = 0;
  private _chatSoundWaitTime = 5000; // in milliseconds

  public isChatOpen = false;

  public get hasNewMessages(): boolean {
    for (const key in this.entriesCounts) {
      if (this.entriesCounts.hasOwnProperty(key)) {
        if (this.entriesCounts[key] > 0) {
          return true;
        }
      }
    }

    return false;
  }

  public get activeChannelName(): string {
    return this.activeChannel.name.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase();
  }

  public getChannelName(name: string): string {
    return name.replace(/[^a-zA-Z0-9]+/g, '-').toLowerCase();
  }

  constructor(
    private _authService: AuthService,
    private _accountService: AccountService,
    private _db: AngularFireDatabase,
    private _gas: GoogleAnalyticsService
  ) {
    this._accountSub = this._accountService.accountInfo.subscribe(info => {
      if (info) {
        this.accountInfo = info;

        // If we are removed from a channel and it's our currently active one then we need to select
        // our first channel
        if (this.activeChannel) {
          const activeChannelName = this.getChannelName(this.activeChannel.name);
          if (this.accountInfo.chatData.channels.indexOf(activeChannelName) === -1) {
            this.activeChannel = this.channels[0];
          }
        }

        this._channelsRef = this._db.object<IChatChannel[]>('chat/channels');
        this._channelsSub = this._channelsRef.valueChanges().subscribe((channels: IChatChannel[]) => {
          this.channels = channels;

          if (!this.activeChannel && channels.length > 0) {
            this.activeChannel = channels[0];
          }

          const ac = this.channels.filter(c => this.getChannelName(c.name) === this.activeChannelName);
          if (this.activeChannel && ac.length > 0) {
            this.activeChannel = ac[0];
            this.isActiveModerator = this.isChannelModerator(this.activeChannel, this._user.uid);
          }
        });

        this.initChannels();
      } else {
        this.unsubscribeAll();
      }

      this._newChatSoundAway = new Audio('../../assets/sounds/new_message_away.wav');
      this._newChatSoundAway.load();

      this._newChatSoundActive = new Audio('../../assets/sounds/new_message_active.wav');
      this._newChatSoundActive.load();

      this._lastChatSoundTime = new Date().getTime();
    });

    this._userSub = this._authService.user.subscribe(user => {
      this._user = user;
    });
  }

  public ngAfterViewInit(): void {
    this._gas.event('Chat', 'View');
  }

  public ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  private unsubscribeAll(): void {
    if (this._accountSub) this._channelsSub.unsubscribe();
    if (this._userSub) this._channelsSub.unsubscribe();
    if (this._timestampSub) this._timestampSub.unsubscribe();
    if (this._channelsSub) this._channelsSub.unsubscribe();
    this.clearEntrySubs();
  }

  private clearEntrySubs(): void {
    if (this._entriesSubs) {
      for (const key in this._entriesSubs) {
        if (this._entriesSubs.hasOwnProperty(key)) {
          this._entriesSubs[key].unsubscribe();
        }
      }
    }
  }

  private initChannels(): void {
    this.clearEntrySubs();
    this._entriesRefs = {};
    this.entriesSnaps = {};

    const timestampPath = 'accounts/' + this._user.uid + '/chatData/timestamps';
    const timestampRef = this._db.object<any>(timestampPath);
    this._timestampSub = timestampRef.valueChanges().subscribe(timestamps => {
      this.timestamps = timestamps;
    });

    for (const channelName of this.accountInfo.chatData.channels) {
      this._entriesRefs[channelName] = this._db.list<IChatMessage>('chat/messages/' + channelName + '/entries');
      const entriesRef = this._entriesRefs[channelName] as AngularFireList<IChatMessage>;

      this.entriesSnaps[channelName] = entriesRef.snapshotChanges();
      this._entriesSubs[channelName] = entriesRef.valueChanges().subscribe((messages: IChatMessage[]) => {
        this.entriesCountsCurrent[channelName] = messages.length;

        if (this.entriesCounts[channelName] === undefined) {
          this.entriesCounts[channelName] = 0;
          this.entriesCountsLast[channelName] = messages.length;
        } else {
          this.entriesCounts[channelName] = messages.length - this.entriesCountsLast[channelName];
          if (this.entriesCounts[channelName] > 0) {
            this.playNewMessageSound();
          }
        }
      });
    }
  }

  public changeChannel(channel: IChatChannel): void {
    this.activeChannel = channel;

    this.isActiveModerator = this.isChannelModerator(channel, this._user.uid);
    this.isActiveOwner = this.isChannelOwner(channel, this._user.uid);
  }

  public addChannel(channel: IChatChannel): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.channels.push(channel);
      this._channelsRef.set(this.channels).then(() => {
        const channelName = this.getChannelName(channel.name);
        this.accountInfo.chatData.channels.push(channelName);

        this._accountRef = this._db.object('accounts/' + this._user.uid);
        this._accountRef.update(this.accountInfo).then(() => {
          this.initChannels();
          this._gas.event('Chat', 'New Channel', `${channelName}`);
          resolve(channel);
        });
      });
    });
  }

  public removeChannel(channel: IChatChannel): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      const index = this.channels.findIndex(c => c.name === channel.name);
      if (index > -1) {
        const removePlayerPromises = [];
        this.channels[index].users.forEach(userId => {
          if (userId !== this._user.uid) {
            removePlayerPromises.push(this.removePlayerFromChannel(channel, userId, true));
          }
        });

        Promise.all(removePlayerPromises).then(() => {
          this.channels.splice(index, 1);
          const channelName = this.getChannelName(channel.name);
          this._entriesSubs[channelName].unsubscribe();
          this._entriesRefs[channelName].remove();

          delete this._entriesSubs[channelName];
          delete this._entriesRefs[channelName];

          this._channelsRef.set(this.channels).then(() => {
            const accountIndex = this.accountInfo.chatData.channels.indexOf(channelName);
            this.accountInfo.chatData.channels.splice(accountIndex, 1);
            this._accountRef = this._db.object('accounts/' + this._user.uid);
            this._accountRef.update(this.accountInfo).then(() => {
              this._gas.event('Chat', 'Remove Channel', `${channelName}`);
              resolve();
            });
          });
        });
      }
    });
  }

  public invitePlayerToChannel(channel: IChatChannel, uid: string): Promise<IChatChannel> {
    return new Promise<IChatChannel>((resolve, reject) => {
      const channelName = this.getChannelName(channel.name);
      const invitePath = 'accounts/' + uid + '/chatData/invites';
      this._db
        .object(invitePath)
        .valueChanges()
        .pipe(take(1))
        .subscribe(inviteData => {
          if (!inviteData) inviteData = {};
          if (!inviteData[channelName]) {
            const newInvite: IPlayerInvite = {
              expirationDate: moment
                .utc()
                .add(1, 'd')
                .toDate()
                .getTime()
            };

            const inviteRef = this._db.object(invitePath + '/' + channelName);
            inviteRef.set(newInvite).then(invite => {
              this._gas.event('Chat', 'Invite to Channel', `${channelName} (${uid})`);
              resolve(channel);
            });
          } else {
            resolve(channel);
          }
        });
    });
  }

  public uninvitePlayerFromChannel(channel: IChatChannel, uid: string): Promise<IChatChannel> {
    return new Promise<IChatChannel>((resolve, reject) => {
      const channelName = this.getChannelName(channel.name);
      const invitePath = 'accounts/' + uid + '/chatData/invites';
      this._db
        .object(invitePath)
        .valueChanges()
        .pipe(take(1))
        .subscribe(inviteData => {
          if (inviteData && inviteData[channelName]) {
            const inviteRef = this._db.object(invitePath + '/' + channelName);
            inviteRef.remove().then(() => {
              this._gas.event('Chat', 'Uninvite from Channel', `${channelName} (${uid})`);
              resolve(channel);
            });
          } else {
            resolve(channel);
          }
        });
    });
  }

  public removePlayerFromChannel(channel: IChatChannel, uid: string, skipChannelUpdate?: boolean): Promise<IChatChannel> {
    if (!skipChannelUpdate) skipChannelUpdate = false;

    return new Promise<IChatChannel>((resolve, reject) => {
      const index = channel.users.indexOf(uid);
      if (index > -1) {
        const accountChannelPath = 'accounts/' + uid + '/chatData';
        const channelName = this.getChannelName(channel.name);
        const chatDataRef = this._db.object<IAccountChatData>(accountChannelPath);
        chatDataRef
          .valueChanges()
          .pipe(take(1))
          .subscribe(chatData => {
            const channelIndex = chatData.channels.indexOf(channelName);
            chatData.channels.splice(channelIndex, 1);
            chatDataRef.update(chatData).then(() => {
              if (!skipChannelUpdate) {
                channel.users.splice(index, 1);

                const modIndex = channel.moderators.indexOf(uid);
                if (modIndex > -1) {
                  channel.moderators.splice(modIndex, 1);
                }

                const ref = this.findChannelRef(channelName);
                ref.set(channel).then(() => {
                  this._gas.event('Chat', 'Remove from Channel', `${channelName} (${uid})`);
                  resolve(channel);
                });
              } else {
                resolve(channel);
              }
            });
          });
      } else {
        reject('Player not a member of this channel.');
      }
    });
  }

  public addPlayerAsModeratorToChannel(channel: IChatChannel, uid: string): Promise<IChatChannel> {
    return new Promise<IChatChannel>((resolve, reject) => {
      const index = channel.moderators.indexOf(uid);
      if (index < 0) {
        channel.moderators.push(uid);
        const channelName = this.getChannelName(channel.name);

        const ref = this.findChannelRef(channelName);
        ref.set(channel).then(() => {
          this._gas.event('Chat', 'Promote to Moderator', `${channelName} (${uid})`);
          resolve(channel);
        });
      } else {
        reject('Player already a moderator.');
      }
    });
  }

  public removePlayerAsModeratorFromChannel(channel: IChatChannel, uid: string): Promise<IChatChannel> {
    return new Promise<IChatChannel>((resolve, reject) => {
      const index = channel.moderators.indexOf(uid);
      if (index > -1) {
        channel.moderators.splice(index, 1);
        const channelName = this.getChannelName(channel.name);

        const ref = this.findChannelRef(channelName);
        ref.set(channel).then(() => {
          this._gas.event('Chat', 'Demote as Moderator', `${channelName} (${uid})`);
          resolve(channel);
        });
      } else {
        reject('Player not moderator.');
      }
    });
  }

  public sendMessage(text: string): Promise<any> {
    const newEntry: IChatMessage = {
      key: null,
      userId: this._user.uid,
      text: text,
      timestamp: moment
        .utc()
        .toDate()
        .getTime()
    };

    this.entriesCountsCurrent[this.activeChannelName]++;
    this.markChannelRead(this.activeChannelName);
    return this._entriesRefs[this.activeChannelName].push(newEntry).then(() => {
      this._gas.event('Chat', 'Messages', `${this.activeChannelName}`);
    });
  }

  public removeMessage(message: IChatMessage): Promise<any> {
    return this._entriesRefs[this.activeChannelName].remove(message.key);
  }

  public markChannelRead(channelName: string): void {
    this.entriesCounts[channelName] = 0;
    this.entriesCountsLast[channelName] = this.entriesCountsCurrent[channelName];
  }

  public markNewMessagesRead(channelName: string): void {
    const timestampPath = 'accounts/' + this._user.uid + '/chatData/timestamps/' + channelName;
    const timestampRef = this._db.object<IAccountChatTimestampData>(timestampPath);
    timestampRef.update({
      lastViewedDate: moment.utc().valueOf()
    });
  }

  private playNewMessageSound(): void {
    const newTime = new Date().getTime();
    if (newTime - this._lastChatSoundTime <= this._chatSoundWaitTime) return;

    this._lastChatSoundTime = newTime;
    if (this.isChatOpen) {
      this._newChatSoundActive.play();
    } else {
      this._newChatSoundAway.play();
    }
  }

  public indexOfChannel(channelName: string): number {
    return this.channels.findIndex(c => this.getChannelName(c.name) === channelName);
  }

  private findChannelRef(channelName: string): AngularFireObject<IChatChannel> {
    return this._db.object<IChatChannel>('chat/channels/' + this.indexOfChannel(channelName));
  }

  public hasChannel(channel: IChatChannel, uid: string): boolean {
    if (!channel.users || channel.users.length === 0) return false;
    return channel.users.indexOf(uid) > -1;
  }

  public isChannelModerator(channel: IChatChannel, uid: string): boolean {
    if (!channel.moderators || channel.moderators.length === 0) return false;
    return channel.moderators.indexOf(uid) > -1;
  }

  public isChannelOwner(channel: IChatChannel, uid: string): boolean {
    if (!channel.owner) return false;
    return channel.owner === uid;
  }

  public acceptInvite(channelName: string, uid: string): Promise<IChatChannel> {
    return new Promise<IChatChannel>((resolve, reject) => {
      const invitePath = 'accounts/' + uid + '/chatData/invites';
      this._db
        .object(invitePath)
        .valueChanges()
        .pipe(take(1))
        .subscribe(inviteData => {
          if (inviteData && inviteData[channelName]) {
            const inviteRef = this._db.object(invitePath + '/' + channelName);
            inviteRef.remove().then(() => {
              const accountChannelPath = 'accounts/' + uid + '/chatData';
              const chatDataRef = this._db.object<IAccountChatData>(accountChannelPath);
              chatDataRef
                .valueChanges()
                .pipe(take(1))
                .subscribe(chatData => {
                  chatData.channels.push(channelName);
                  chatDataRef.update(chatData).then(() => {
                    const channel = this.channels.find(c => this.getChannelName(c.name) === channelName);
                    channel.users.push(uid);

                    const ref = this.findChannelRef(channelName);
                    ref.update(channel).then(() => {
                      this._gas.event('Chat', 'Accept Invite', `${channelName} (${uid})`);
                      resolve(channel);
                    });
                  });
                });
            });
          }
        });
    });
  }

  public declineInvite(channelName: string, uid: string): void {
    const invitePath = 'accounts/' + uid + '/chatData/invites';
    this._db
      .object(invitePath)
      .valueChanges()
      .pipe(take(1))
      .subscribe(inviteData => {
        if (inviteData && inviteData[channelName]) {
          const inviteRef = this._db.object(invitePath + '/' + channelName);
          inviteRef.remove().then(() => {
            this._gas.event('Chat', 'Decline Invite', `${channelName} (${uid})`);
          });
        }
      });
  }
}
