import { SkillsService } from './../../../../../services/skills.service';
import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChildren, QueryList, Input, ViewChild } from '@angular/core';
import { SpellsService } from '../../../../../services/spells.service';
import { ISpell, ISpellGroup, ISpellSlot } from '../../../../../interfaces/spell';
import { Character } from '../../../../../interfaces/character';
import { CharacterCardSpellsSpellComponent } from '../slot/slot.component';
import { ISkill } from '../../../../../interfaces/skill';
import { SpellSlotsService } from '../../../../../services/spell-slots.service';

@Component({
  selector: 'app-character-card-spells-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardSpellsGroupComponent implements OnInit {
  @Input() level: number;
  public character: Character;
  public spellGroup: ISpellGroup;
  public spellSlot: ISpellSlot;
  public spellSlotSkill: ISkill;

  @ViewChildren(CharacterCardSpellsSpellComponent) spellSlotComponents: QueryList<CharacterCardSpellsSpellComponent>;

  private _parent: any;

  constructor(
    private _spellsService: SpellsService,
    private _spellSlotsService: SpellSlotsService,
    private _skillsService: SkillsService,
    private _cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.spellGroup = this._spellsService.allSpellGroups.find(s => s.level === this.level);
    this.spellSlot = this._spellSlotsService.allSpellSlots.find(ss => ss.level === this.level);
    this._skillsService.get(this.spellGroup.parentSkill).then(spellSlotSkill => {
      this.spellSlotSkill = spellSlotSkill;
    });
  }

  public updateCharacter(character: Character, parent: any): void {
    this.character = character;
    this._parent = parent;

    this.spellSlotComponents.forEach(slot => {
      slot.updateCharacter(character, this);
    });

    this._cdr.markForCheck();
  }

  public markForCheck(): void {
    this._cdr.markForCheck();

    this.spellSlotComponents.forEach(slot => {
      slot.markForCheck();
    });
  }

  public updateSpellGroups(): void {
    this._parent.updateSpellGroups();
  }

  public checkPrint(): void {
    this._parent.checkPrint();
  }
}
