import { PaypalHelper } from './../../helpers/paypal.helper';
import { Subscription } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import FirebaseAuth from 'firebase/auth';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { IAccountInfo, IMembership } from '../../interfaces/accountInfo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ElementRef, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { loadScript } from '@paypal/paypal-js';
import { MembershipTypes } from '../../enums/membershipTypes';

@Component({
  selector: 'app-membership-dialog',
  templateUrl: './membership-dialog.component.html',
  styleUrls: ['../tiles.scss', './membership-dialog.component.scss', '../button.scss']
})
export class MembershipDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('paypalButton', { static: false }) paypalButtonElement: ElementRef;
  public MembershipTypes = MembershipTypes;

  public loading = true;
  public selectedMembershipType: MembershipTypes = MembershipTypes.SILVER;
  public isActive = false;
  public paypalSubscription = null;
  public membershipTypeText = 'None';
  public updating = false;

  private _user: FirebaseAuth.User;
  private _accountInfo: IAccountInfo;
  private _accountRef: AngularFireObject<IAccountInfo>;

  private _accountSub: Subscription;
  private _paypalSubscriptionSub: Subscription;
  private _paypalButton: any;
  private _buttonRendered = false;
  private _initializingButton = false;
  private _paypal = null;

  constructor(
    public dialogRef: MatDialogRef<MembershipDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _authService: AuthService,
    private _db: AngularFireDatabase,
    private _accountService: AccountService,
    private _fns: AngularFireFunctions,
    private _cdr: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    const clientId = environment.paypal.env === 'production' ? environment.paypal.client.production : environment.paypal.client.sandbox;
    loadScript({ 'client-id': clientId, intent: 'subscription', vault: true, 'disable-funding': 'credit,card' })
      .then((paypal) => {
        this._paypal = paypal;
        this._authService.user.subscribe(user => {
          this._user = user;
          if (user) {
            this._accountRef = this._db.object('accounts/' + user.uid);

            this._accountSub = this._accountService.accountInfo.subscribe(info => {
              this._paypalSubscriptionSub = this._accountService.paypalSubscription.subscribe(paypalSub => {
                this.paypalSubscription = paypalSub;

                const isActive = paypalSub && paypalSub.status === 'ACTIVE';
                if (isActive) {
                  this.membershipTypeText = PaypalHelper.getMembershipNameByPlanId(paypalSub.plan_id);
                } else {
                  this.membershipTypeText = 'None';
                }

                this._cdr.detectChanges();

                if (paypalSub && isActive) {
                  this.closePaypalButton();
                } else {
                  this.initPaypalButton();
                }
              });

              this._accountInfo = info;
              this.isActive = info.membership ? info.membership.isActive : false;

              this.loading = false;
            });
          }
        });
      })
      .catch((err) => {
        console.error('Failed to load the PayPal JS SDK script library.', err);
      });
    // PaypalHelper.loadScript('subscription');

  }

  public ngAfterViewInit(): void {

  }

  public ngOnDestroy(): void {
    if (this._accountSub) this._accountSub.unsubscribe();
    if (this._paypalSubscriptionSub) this._paypalSubscriptionSub.unsubscribe();
  }

  private closePaypalButton(): void {
    if (this._paypalButton && this._buttonRendered) {
      this._paypalButton.close();
      this._buttonRendered = false;
    }
  }

  private initPaypalButton(): void {
    if (!this._initializingButton && this.paypalButtonElement && this.paypalButtonElement.nativeElement) {
      this._initializingButton = true;
      this.closePaypalButton();

      this._paypalButton = this._paypal.Buttons({
        createSubscription: (data, actions) => {
          this.updating = true;
          return actions.subscription.create({
            plan_id: environment.paypal.plans[this.selectedMembershipType]
          });
        },
        onApprove: (data, actions) => {
          return new Promise((resolve, reject) => {
            const getSubscription = this._fns.httpsCallable('getSubscription');
            getSubscription({ subscriptionId: data.subscriptionID }).subscribe(results => {
              this._accountService.paypalSubscription.next(results);
              this.paypalSubscription = results;

              const membership: IMembership = {
                subscriptionId: results.id,
                isActive: true,
                lastPaidDate: ''
              };

              this._accountInfo.membership = membership;
              this._accountRef.update({ membership }).then(() => {
                this.updating = false;
              });
            });
          });
        }
      });

      this._paypalButton.render(this.paypalButtonElement.nativeElement).then(() => {
        this._buttonRendered = true;
        this._initializingButton = false;
      });
    }
  }

  public onClickType(type: MembershipTypes): void {
    this.selectedMembershipType = type;
  }

  public onClickSuspend(): void {
    this.updating = true;
    const suspendSubscription = this._fns.httpsCallable('suspendSubscription');
    suspendSubscription({ subscriptionId: this._accountInfo.membership.subscriptionId }).subscribe(results => {
      this._accountService.paypalSubscription.next(results);
      this.paypalSubscription = results;

      this._accountInfo.membership.isActive = false;
      this._accountRef.update({ membership: { ...this._accountInfo.membership } }).then(() => {
        this.updating = false;
      });
    });
  }

  public onClickReactivate(): void {
    this.updating = true;
    const reactivateSubscription = this._fns.httpsCallable('reactivateSubscription');
    reactivateSubscription({ subscriptionId: this._accountInfo.membership.subscriptionId }).subscribe(results => {
      this._accountService.paypalSubscription.next(results);
      this.paypalSubscription = results;

      this._accountInfo.membership.isActive = true;
      this._accountRef.update({ membership: { ...this._accountInfo.membership } }).then(() => {
        this.updating = false;
      });
    });
  }

  public onClickCancel(): void {
    this.updating = true;
    const cancelSubscription = this._fns.httpsCallable('cancelSubscription');
    cancelSubscription({ subscriptionId: this._accountInfo.membership.subscriptionId }).subscribe(results => {
      this._accountService.paypalSubscription.next(results);
      this.paypalSubscription = results;

      this._accountInfo.membership.subscriptionId = '';
      this._accountInfo.membership.isActive = false;
      this._accountInfo.membership.lastPaidDate = '';
      this._accountRef.update({ membership: { ...this._accountInfo.membership } }).then(() => {
        this.updating = false;
      });
    });
  }

  public onClickClose(): void {
    this.dialogRef.close();
  }
}
