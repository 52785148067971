import { DropMenuType } from './../enums/dropMenuType';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DropMenuService {
  private _dropMenuType: BehaviorSubject<DropMenuType> = new BehaviorSubject<DropMenuType>(null);
  public dropMenuType: Observable<DropMenuType> = this._dropMenuType.asObservable();

  constructor() { }

  public changeType(type: DropMenuType): void {
    this._dropMenuType.next(type);
  }
}
