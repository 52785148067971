import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { IChapter } from '../interfaces/chapter';

@Injectable({
  providedIn: 'root'
})
export class ChapterService {

  private _uid: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public chapters: IChapter[] = [];
  public isLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private _authService: AuthService, private _db: AngularFireDatabase) {
    this._authService.user.subscribe(user => {
      if (user) {
        this._uid.next(user.uid);
      }
    });

    this.getAll();
  }

  public getAll(): void {
    this._db.list<IChapter>('chapters').snapshotChanges().subscribe(snapshot => {
      this.chapters = snapshot.map(s => ({ id: s.key, ...s.payload.val() }));
      this.isLoaded.next(true);
    });
  }
}

const MAIN_CHAPTER_GUID = 'bdcf29f8-04a7-496f-a87e-98a8222717de';
export { MAIN_CHAPTER_GUID };
