import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-tile-origins',
  templateUrl: './origins.component.html',
  styleUrls: ['./origins.component.scss', '../../home.component.scss', '../../../tiles.scss', '../../../toolbar.scss', '../../../page.scss', '../../../button.scss']
})
export class HomeTileOriginsComponent implements OnInit {

  constructor(private _router: Router) { }

  ngOnInit() {
  }

  public onClickOrigins(): void {
    this._router.navigate(['origins']);
  }
}
