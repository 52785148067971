import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class MarkdownService {
  private _url = '';

  constructor(@Inject('BASE_URL') baseUrl: string, private _http: HttpClient) {
    this._url = baseUrl;
  }

  public getFromAssets(type: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this._http.get(this._url + 'assets/' + type + '.md', { responseType: 'text' }).subscribe(data => {
        resolve(data as string);
      });
    });
  }

  public getFromData(type: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this._http.get(this._url + 'data/' + type + '.md', { responseType: 'text' }).subscribe(data => {
        resolve(data as string);
      });
    });
  }

  public getFromHandbook(type: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this._http.get(this._url + 'assets/handbook/' + type + '.md', { responseType: 'text' }).subscribe(data => {
        resolve(data as string);
      });
    });
  }

  public getFromLogistics(type: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this._http.get(this._url + 'assets/logistics/' + type + '.md', { responseType: 'text' }).subscribe(data => {
        resolve(data as string);
      });
    });
  }
}
