import { SpellsService } from './../../../services/spells.service';
import { FindCharacterDialogComponent } from './../../find-character-dialog/find-character-dialog.component';
import { PageService } from './../../../services/page.service';
import { Router } from '@angular/router';
import { OriginsService } from './../../../services/origins.service';
import { Character } from './../../../interfaces/character';
import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  OnDestroy,
  EventEmitter,
  Output
} from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ICharacter } from '../../../interfaces/character';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable, Subscription } from 'rxjs';
import { SpellSlotsService } from '../../../services/spell-slots.service';
import { SkillsService } from '../../../services/skills.service';
import { MatDialog } from '@angular/material/dialog';
import { UuidHelper } from '../../../helpers/uuid.helper';
import { ProfessionsService } from '../../../services/professions.service';
import { AccountService } from '../../../services/account.service';
import { NewCharacterDialogComponent } from '../../new-character-dialog/new-character-dialog.component';

@Component({
  selector: 'app-side-nav-characters',
  templateUrl: './characters.component.html',
  styleUrls: [
    '../../tiles.scss',
    '../../character.scss',
    './characters.component.scss'
  ]
})
export class SideNavCharactersComponent
  implements OnInit, OnDestroy, AfterViewInit {
  public characters: Character[] = [];
  public viewStatus = 'Active';
  public maxCharacters = 0;

  private _uid: string;
  private _allCharacters: Character[] = [];
  private _charactersSub: Subscription;
  private _dialogCloseSub: Subscription;
  private _userSub: Subscription;
  private _accountSub: Subscription;

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _accountService: AccountService,
    private _db: AngularFireDatabase,
    private _skillsService: SkillsService,
    private _spellSlotService: SpellSlotsService,
    private _spellsService: SpellsService,
    private _professionsService: ProfessionsService,
    private _originsService: OriginsService,
    public pageService: PageService,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    this._userSub = this._authService.user.subscribe(user => {
      if (user) {
        this._uid = user.uid;
        this._charactersSub = this._db
          .list<ICharacter>('characters/' + user.uid, ref =>
            ref.orderByChild('name')
          )
          .valueChanges()
          .subscribe(chars => {
            const list: Character[] = [];
            chars.forEach(char => {
              list.push(
                new Character(
                  char,
                  this._skillsService,
                  this._spellSlotService,
                  this._spellsService,
                  this._professionsService,
                  this._originsService
                )
              );
            });

            this._allCharacters = list;
            this.characters = this._allCharacters.filter(
              c => c.data.status === this.viewStatus
            );
          });
      }
    });

    this._accountSub = this._accountService.accountInfo.subscribe(info => {
      if (info) {
        this.maxCharacters = info.profile.maxCharacters;
      }
    });
  }

  public ngAfterViewInit(): void { }

  public ngOnDestroy(): void {
    if (this._charactersSub) this._charactersSub.unsubscribe();
    if (this._dialogCloseSub) this._dialogCloseSub.unsubscribe();
    if (this._userSub) this._userSub.unsubscribe();
    if (this._accountSub) this._accountSub.unsubscribe();
  }

  public onClickCharacter(character: Character): void {
    this._router.navigate(['characters', character.data.id, this._uid]);

    if (this.pageService.isMobile) {
      this.close.emit();
    }
  }

  public onClickNewCharacter(type: string): void {
    if (this.characters.length >= this.maxCharacters) return;

    const dialogRef = this._dialog.open(NewCharacterDialogComponent, {
      width: '350px',
      height: '156px',
      panelClass: 'crux-dialog',
      data: {
        characters: this._allCharacters
      }
    });

    this._dialogCloseSub = dialogRef.afterClosed().subscribe(async characterName => {
      if (characterName) {
        const defaultOrigin = await this._originsService.get('human');

        const newCharacterData: ICharacter = {
          id: UuidHelper.uuid(),
          type: type,
          name: characterName,
          fullName: '',
          background: '',
          team: '',
          status: 'Active',
          portrait: 'data/origins/' + defaultOrigin.portraits[0],
          xp: 80,
          events: [],
          xpLog: [],
          vitality: 1,
          stamina: 1,
          arcane: 1,
          agility: 1,
          spirit: 1,
          originId: 'human',
          racialBonusAttributes: ['vitality'],
          skills: [],
          spellSlots: [],
          professions: [],
          crafting: {
            skills: [],
            lore: [],
            talents: []
          },
          archiveDate: '',
          isLocked: false
        };

        const newCharacter = new Character(
          newCharacterData,
          this._skillsService,
          this._spellSlotService,
          this._spellsService,
          this._professionsService,
          this._originsService
        );
        this._db
          .object('characters/' + this._uid + '/' + newCharacterData.id)
          .set(newCharacter.data);

        this._router.navigate(['characters', newCharacterData.id, this._uid]);

        if (this.pageService.isMobile) {
          this.close.emit();
        }
      }
    });
  }

  public onClickChangeViewStatus(status: string): void {
    this.viewStatus = status;
    this.characters = this._allCharacters.filter(
      c => c.data.status === this.viewStatus
    );
  }

  public onClickFindPlayerCharacter(): void {
    const dialogRef = this._dialog.open(FindCharacterDialogComponent, {
      width: '400px',
      height: '500px',
      data: null,
      panelClass: 'crux-dialog'
    });

    this._dialogCloseSub = dialogRef.afterClosed().subscribe(data => {
      // Yes, we have confirmed a delete request
      if (data) {
        this._router.navigate(['characters', data.characterId, data.uid]);
      }
    });
  }
}
