import { IOutlineItem } from '../../interfaces/outline';

import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as marked from 'marked';
import { MarkdownService } from '../../services/markdown.service';
import { ActivatedRoute } from '@angular/router';
import { PageService } from '../../services/page.service';
import { JsonService } from '../../services/json.service';
import { ILogisticsItem, ILogisticsGroup } from '../../interfaces/logistics';
import { OutlineHelper } from '../../helpers/outline.helper';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-logistics',
  templateUrl: './logistics.component.html',
  styleUrls: ['../tiles.scss', '../toolbar.scss', '../page.scss', './logistics.component.scss']
})
export class LogisticsComponent implements OnInit, OnDestroy {
  public groups: ILogisticsGroup[] = [];
  public outlineItems: IOutlineItem[] = [];

  public showGoToTop = false;
  public scrollDuration = 350;

  private _urlSub: Subscription;

  constructor(
    private _jsonService: JsonService,
    private _markdownService: MarkdownService,
    private _activatedRoute: ActivatedRoute,
    public pageService: PageService,
    private _scrollToService: ScrollToService
  ) {
    this._urlSub = this._activatedRoute.url
      .pipe(map(segments => segments.join('')))
      .subscribe(url => {
        this.pageService.setTarget(url);
      });
  }

  ngOnInit() {
    this.initScroll();

    this._jsonService.get('logistics').then((logisticsItems: ILogisticsItem[]) => {
      logisticsItems.forEach(item => {
        this._markdownService.getFromLogistics(item.fileName).then(results => {
          const newGroup: ILogisticsGroup = {
            title: item.title,
            renderedMarkdown: 'NOTHING RENDERED'
          };

          const renderer = new marked.Renderer();
          renderer.heading = (text: string, level: number, raw: string) => OutlineHelper.createHeaders(text, level, raw, this.outlineItems);

          marked(results, { renderer: renderer, gfm: true }, (err, markdown) => {
            if (err) {
              console.error(err);
            }

            newGroup.renderedMarkdown = markdown;
            this.groups.push(newGroup);
          });
        });
      });
    });
  }

  public ngOnDestroy(): void {
    this.destroyScroll();
    this._urlSub.unsubscribe();
  }

  public initScroll(): void {
    if (this.pageService.isMobile) {
      window.addEventListener('scroll', this.scroll, true);
    }
  }

  private destroyScroll(): void {
    if (this.pageService.isMobile) {
      window.removeEventListener('scroll', this.scroll, true);
    }
  }

  public toggleOutline(outline: IOutlineItem): void {
    outline.open = !outline.open;
  }

  scroll = (e: any): void => {
    if (!this.showGoToTop) {
      this.showGoToTop = true;
    } else if (e.target.scrollTop === 0) {
      this.showGoToTop = false;
    }
  }

  public scrollToTop(): void {
    this._scrollToService.scrollTo({ target: '#outline', duration: this.scrollDuration }).subscribe({
      complete: () => {
        this.showGoToTop = false;
      }
    });
  }
}
