import { IChatChannel, IChannelMessages, IChatMessage, IPlayerInvite, IInvitePlayerData, IAccountChatTimestampData } from './../../../interfaces/chat';
import { AccountService } from './../../../services/account.service';
import { IAccountInfo } from './../../../interfaces/accountInfo';
import { AuthService } from './../../../services/auth.service';
import { PageService } from './../../../services/page.service';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireObject, AngularFireDatabase, SnapshotAction } from '@angular/fire/compat/database';
import { IChat } from '../../../interfaces/chat';
import { Subscription, Observable } from 'rxjs';
import { ChatService } from '../../../services/chat.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../../delete-dialog/delete-dialog.component';
import { NewChannelComponent } from './new-channel/new-channel.component';
import { InvitePlayerComponent } from './invite-player/invite-player.component';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-drop-menu-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['../../section.scss', '../../tiles.scss', '../../button.scss', './chat.component.scss']
})
export class DropMenuChatComponent implements OnInit, OnDestroy {
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() preventClose: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('newChatText', { static: false }) newChatText: ElementRef;
  @ViewChild(PerfectScrollbarComponent, { static: false }) componentScroll: PerfectScrollbarComponent;

  public messages: IChatMessage[] = [];
  public haltAutoScroll = false;
  public hasNewMessages = false;
  public editing = false;
  public showMobileChannels = false;

  private _spamTimeDuration = 1000; // in milliseconds
  private _lastMessageTime = 0;
  private _lastMessageCount = 0;
  private _scrollSpeed = 150;
  private _timestampIndex = -1;

  public accountInfo: IAccountInfo;
  public invites: string[] = [];
  private _uid: string;

  private _channelSub: Subscription;
  private _dialogCloseSub: Subscription;
  private _newChannelSub: Subscription;
  private _accountSub: Subscription;
  private _authSub: Subscription;

  public get canPostMessage(): boolean {
    return new Date().getTime() - this._lastMessageTime >= this._spamTimeDuration;
  }

  constructor(
    public pageService: PageService,
    public chatService: ChatService,
    private _dialog: MatDialog,
    private _cdr: ChangeDetectorRef,
    private _accountService: AccountService,
    private _authService: AuthService
  ) {
    this._lastMessageTime = new Date().getTime();
    this.chatService.isChatOpen = true;
  }

  ngOnInit() {
    this._accountSub = this._accountService.accountInfo.subscribe(info => {
      this.accountInfo = info;

      this.invites = [];
      if (this.accountInfo.chatData.invites) {
        for (const key in this.accountInfo.chatData.invites) {
          if (this.accountInfo.chatData.invites.hasOwnProperty(key)) {
            const expirationDate = this.accountInfo.chatData.invites[key].expirationDate as number;
            if (moment.utc().isSameOrBefore(expirationDate)) {
              const channel = this.chatService.channels.find(c => {
                return this.chatService.getChannelName(c.name) === key;
              });

              this.invites.push(channel.name);
            } else {
              // Remove the invite as it has expired
            }
          }
        }
      }
    });

    this._authSub = this._authService.user.subscribe(user => {
      if (user) {
        this._uid = user.uid;
      }
    });
    this.initMessages();
    this.updateFromSpam();
  }

  public ngOnDestroy(): void {
    if (this._channelSub) this._channelSub.unsubscribe();
    if (this._dialogCloseSub) this._dialogCloseSub.unsubscribe();
    if (this._newChannelSub) this._newChannelSub.unsubscribe();
    if (this._accountSub) this._accountSub.unsubscribe();
    if (this._authSub) this._authSub.unsubscribe();
    this.chatService.isChatOpen = false;
  }

  private initMessages(): void {
    this._lastMessageCount = 0;
    const snaps = this.chatService.entriesSnaps[this.chatService.activeChannelName] as Observable<SnapshotAction<IChatMessage>[]>;
    this._channelSub = snaps
      .pipe(map(changes => {
        return changes.map(c => ({ key: c.payload.key, ...c.payload.val() }));
      }))
      .subscribe((messages: IChatMessage[]) => {
        const scrollSpeed = this._lastMessageCount === 0 ? 0 : this._scrollSpeed;
        this.messages = messages;
        this.componentScroll.directiveRef.update();

        this._timestampIndex = -1;

        if (this._lastMessageCount !== messages.length) {
          this._lastMessageCount = messages.length;
          if (this.haltAutoScroll) {
            this.hasNewMessages = true;
          }
        }

        setTimeout(() => {
          if (!this.haltAutoScroll) {
            this.componentScroll.directiveRef.scrollToBottom(0, scrollSpeed);
            this.chatService.markChannelRead(this.chatService.activeChannelName);
          }
        }, 50);
      });
  }

  public onClickChannel(channel: IChatChannel): void {
    if (this._channelSub) this._channelSub.unsubscribe();

    this.showMobileChannels = false;
    this.editing = false;
    this.chatService.changeChannel(channel);
    this.initMessages();
  }

  public onEnter(event: any, text: string): void {
    if (!this.canPostMessage) return;
    if (event) {
      event.preventDefault();
    }

    this._lastMessageTime = new Date().getTime();
    const chatText = text.lastIndexOf('\r') === text.length ? text.substring(0, text.length - 1) : text; // Remove any extra line feed characters
    this.chatService.sendMessage(chatText).then(() => {
      this.newChatText.nativeElement.value = '';
      this.componentScroll.directiveRef.scrollToBottom(0, this._scrollSpeed);
      this.onClickNewMessages();
    });

    this.updateFromSpam();
  }

  public onEditMessages(value: boolean): void {
    this.editing = value;
  }

  public onClickDeleteMessage(message: IChatMessage): void {
    this.preventClose.emit(true);
    const dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '400px',
      height: '200px',
      data: {
        title: 'Delete Message?',
        text: 'Are you sure you wish to delete this message? You cannot undo this.'
      },
      panelClass: 'crux-dialog'
    });

    this._dialogCloseSub = dialogRef.afterClosed().subscribe(result => {
      // Yes, we have confirmed a delete request
      if (result) {
        this.chatService.removeMessage(message).then(() => {
          this.preventClose.emit(false);
        });
      }
    });
  }

  public isNewGroup(a: IChatMessage, b: IChatMessage): boolean {
    if (a.userId !== b.userId) {
      return true;
    }

    if (b.timestamp - a.timestamp > 300000) {
      return true;
    }

    return false;
  }

  public onBottomReached(e: any): void {
    this.haltAutoScroll = false;
    this.chatService.markChannelRead(this.chatService.activeChannelName);
    this.hasNewMessages = false;
  }

  public onScroll(e: any): void {
    this.haltAutoScroll = true;
  }

  public scrollToNewMessages(): void {
    this.componentScroll.directiveRef.scrollToBottom(0, this._scrollSpeed);
    this.hasNewMessages = false;
  }

  private updateFromSpam(): void {
    setTimeout(() => {
      this._cdr.markForCheck();
    }, this._spamTimeDuration + 10);
  }

  public toggleShowMobileChannels(value: boolean): void {
    this.showMobileChannels = value;
  }

  public onClickAddChannel(): void {
    this.preventClose.emit(true);
    const dialogRef = this._dialog.open(NewChannelComponent, {
      width: '300px',
      height: '300px',
      data: { name: 'New Channel', owner: this._uid, moderators: [this._uid], users: [this._uid], private: true },
      panelClass: 'crux-dialog'
    });

    this._newChannelSub = dialogRef.afterClosed().subscribe((result: IChatChannel) => {
      if (result) {
        this.chatService.addChannel(result).then(channel => {
          this.onClickChannel(channel);
        });
      }

      this.preventClose.emit(false);
    });
  }

  public onDeleteChannel(channel: IChatChannel): void {
    this.preventClose.emit(true);
    const dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '400px',
      height: '200px',
      data: {
        title: 'Delete Channel?',
        text: 'Are you sure you wish to delete this channel? You cannot undo this.'
      },
      panelClass: 'crux-dialog'
    });

    this._dialogCloseSub = dialogRef.afterClosed().subscribe(result => {
      // Yes, we have confirmed a delete request
      if (result) {
        this.chatService.removeChannel(channel).then(() => {
          this.onClickChannel(this.chatService.channels[0]);
          this.preventClose.emit(false);
        });
      }
    });
  }

  public onInvitePlayer(channel: IChatChannel): void {
    this.preventClose.emit(true);
    const dialogRef = this._dialog.open(InvitePlayerComponent, {
      width: '300px',
      height: '500px',
      data: channel,
      panelClass: 'crux-dialog'
    });

    this._newChannelSub = dialogRef.afterClosed().subscribe((result: string[]) => {
      if (result && result.length > 0) {
      }

      this.preventClose.emit(false);
    });
  }

  public onClickAcceptInvite(channelName: string): void {
    this.chatService.acceptInvite(this.chatService.getChannelName(channelName), this._uid).then(channel => {
      if (this._channelSub) this._channelSub.unsubscribe();

      this.showMobileChannels = false;
      this.editing = false;
      this.chatService.changeChannel(channel);
      this.initMessages();
    });
  }

  public onClickDeclineInvite(channelName: string): void {
    this.chatService.declineInvite(this.chatService.getChannelName(channelName), this._uid);
  }

  public onLeaveChannel(channelName: string): void {
    this.chatService.removePlayerFromChannel(this.chatService.activeChannel, this._uid).then(() => {
      this.initMessages();
    });
  }

  public isMessageNew(message: IChatMessage, index: number): boolean {
    const name = this.chatService.activeChannelName;
    const timestamp =
      this.chatService.timestamps && this.chatService.timestamps[name] ? this.chatService.timestamps[name].lastViewedDate : 0;

    if (message.timestamp >= timestamp && this._timestampIndex === -1) {
      this._timestampIndex = index;
    }

    return message.timestamp >= timestamp && this._timestampIndex === index;
  }

  public onClickNewMessages(): void {
    this.chatService.markNewMessagesRead(this.chatService.activeChannelName);
    this._timestampIndex = this.messages.length - 1;
  }
}
