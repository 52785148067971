import { SpellsService } from './../services/spells.service';
import { ICraftingSkill, CraftingType } from './crafting';
import { OriginsService } from './../services/origins.service';
import { IOrigin } from './origin';
import { SpellSlotsService } from './../services/spell-slots.service';
import { AttributeHelper } from '../helpers/attribute.helper';
import { ISkill } from './skill';
import { SkillsService } from '../services/skills.service';
import { ISpell, ISpellSlot } from './spell';
import { IProfession } from './profession';
import { ProfessionsService } from '../services/professions.service';
import { SkillHelper } from '../helpers/skill.helper';
import { SpellSlotHelper } from '../helpers/spell-slot.helper';
import { ProfessionHelper } from '../helpers/profession.helper';

export interface ICharacter {
  id: string;
  type: string;
  name: string;
  fullName?: string;
  backStory?: string;
  background: string;
  team: string;
  xp: number;
  xpLog: IXpLogEntry[];
  events: ICharacterEvent[];
  portrait: string;
  status: string;
  citizenship?: ICRUXCitizenshipDetails;
  isLocked?: boolean;

  // Attributes
  vitality: number;
  stamina: number;
  arcane: number;
  agility: number;
  spirit: number;

  // Origin
  originId: string;
  racialBonusAttributes: string[];

  // Skills
  skills: ICharacterSkill[];

  // Spells
  spellSlots: ICharacterSpellSlot[];

  // Professions
  professions: ICharacterProfession[];

  // Crafting
  crafting: ICharacterCrafting;

  // Dates
  archiveDate: string;
}

export class Character {
  public data: ICharacter;
  public level = 0;
  public cpLeft = 0;
  public apLeft = 0;
  public isDirty = false;

  private _skillsService: SkillsService;
  private _spellSlotsService: SpellSlotsService;
  private _spellsService: SpellsService;
  private _professionsService: ProfessionsService;
  private _originsService: OriginsService;

  private _origin: IOrigin;
  private _vitalityBonus = 0;
  private _vitalityBoost = 0;

  private _craftingLevel = 0;
  private _craftingLevelLeft = 0;
  private _craftingTalents = 0;
  private _craftingTalentsLeft = 0;

  private _purchaseList: string[] = [];

  public static calculateCharacterPoints(xp: number): number {
    return Math.floor(xp / 4);
  }

  public static calculateLevel(cp: number): number {
    return Math.floor(cp / 5);
  }

  public static calculateAttributePoints(cp: number): number {
    return 40 + Math.floor((cp - 10) / 5);
  }

  public get cp(): number {
    return Character.calculateCharacterPoints(this.data.xp);
  }

  public get ap(): number {
    return Character.calculateAttributePoints(this.cp);
  }

  public get spellSlotTotal(): number {
    let count = 0;

    this.data.spellSlots.forEach(slot => {
      count += slot.slots;
    });

    return count;
  }

  public get spellSlotMana(): number {
    return this.spellSlotTotal * 2;
  }

  public get spellSlotFocus(): number {
    return Math.trunc(this.spellSlotTotal / 2);
  }

  public get maxProfessionTier(): number {
    const currentCp = this.cp;
    for (let i = ProfessionHelper.tierCPCosts.length - 1; i >= 0; --i) {
      if (currentCp >= ProfessionHelper.tierCPCosts[i]) {
        return i + 1;
      }
    }

    return 0;
  }

  public get vitality(): number {
    return this.data.vitality + this.getAttributeBonuses('vitality');
  }

  public get vitalityBoost(): number {
    return this._vitalityBoost;
  }

  public get stamina(): number {
    return this.data.stamina + this.getAttributeBonuses('stamina');
  }

  public get arcane(): number {
    return this.data.arcane + this.getAttributeBonuses('arcane');
  }

  public get agility(): number {
    return this.data.agility + this.getAttributeBonuses('agility');
  }

  public get spirit(): number {
    return this.data.spirit + this.getAttributeBonuses('spirit');
  }

  public get origin(): IOrigin {
    return this._origin;
  }

  public get craftingLevel(): number {
    return this._craftingLevel;
  }

  public get craftingLevelLeft(): number {
    return this._craftingLevelLeft;
  }

  public get craftingTalents(): number {
    return this._craftingTalents;
  }

  public get craftingTalentsLeft(): number {
    return this._craftingTalentsLeft;
  }

  constructor(
    data: ICharacter,
    skillsService: SkillsService,
    spellSlotsService: SpellSlotsService,
    spellsService: SpellsService,
    professionsService: ProfessionsService,
    originsService: OriginsService
  ) {
    this.data = data;
    this._skillsService = skillsService;
    this._spellSlotsService = spellSlotsService;
    this._spellsService = spellsService;
    this._professionsService = professionsService;
    this._originsService = originsService;

    // Migrate the RaceId over to OriginId
    this.migrateRace();

    this._originsService
      .get(this.data.originId)
      .then(origin => (this._origin = origin));

    this.initSpellSlots();
    this.initProfessions();
    this.initXpLog();
    this.initEvents();
    this.initStatus();
    this.initSkills();
    this.initCRUXCitizenshipDetails();
    this.calculateRemainingCp();
    this.calculateRemainingAp();
    this.calculateVitalityModifiers();
    this.calculateCrafting();
  }

  private migrateRace(): void {
    const raceId = this.data['raceId'];
    if (raceId && !this.data.originId) {
      this.data.originId = raceId;
    }

    delete this.data['raceId'];

    this.data.portrait = this.data.portrait.replace('races/', 'origins/');
  }

  public setOrigin(origin: IOrigin): void {
    this._origin = origin;
  }

  public calculateEverything(): void {
    this.calculateRemainingCp();
    this.calculateRemainingAp();
    this.calculateVitalityModifiers();
    this.calculateCrafting();
  }

  public calculateRemainingCp(): void {
    const cp = Character.calculateCharacterPoints(this.data.xp);
    this.cpLeft = cp;
    this.level = Character.calculateLevel(cp);

    if (this.data.skills) {
      this.data.skills.forEach(characterSkill => {
        this._skillsService.get(characterSkill.skillId).then(skill => {
          if (skill) {
            this.cpLeft -= SkillHelper.getTotalSkillCost(
              skill,
              characterSkill.count
            );
          }
        });
      });
    }

    if (this.data.professions) {
      this.data.professions.forEach(charProf => {
        const prof = this._professionsService.allProfessions.find(
          p => p.id === charProf.professionId
        );
        if (prof) {
          this.cpLeft -= prof.cost;
        }
      });
    }

    if (this.data.spellSlots) {
      this.data.spellSlots.forEach(charSpellSlot => {
        const spellSlot = this._spellSlotsService.allSpellSlots.find(
          ss => ss.id === charSpellSlot.spellSlotId
        );
        this._skillsService.get(spellSlot.skillId).then(skill => {
          this.cpLeft -= SkillHelper.getTotalSkillCost(
            skill,
            charSpellSlot.slots
          );
        });
      });
    }
  }

  public calculateRemainingAp(): void {
    const cp = Character.calculateCharacterPoints(this.data.xp);
    this.apLeft = Character.calculateAttributePoints(cp);
    const attributes = ['vitality', 'stamina', 'arcane', 'agility', 'spirit'];
    attributes.forEach(attribute => {
      const cost = AttributeHelper.calculateCost(this.data[attribute]);
      this.apLeft -= cost;
    });
  }

  public setXp(xp: number): void {
    this.data.xp = xp;
    this.calculateRemainingCp();
  }

  public adjustXp(entry: IXpLogEntry): void {
    this.data.xpLog.push(entry);
    this.data.xp += entry.xpValue;
    this.calculateRemainingCp();
  }

  public addPurchased(id: string): void {
    this._purchaseList.push(id);
  }

  public removePurchased(id: string): void {
    const index = this._purchaseList.indexOf(id);
    if (index > -1) {
      this._purchaseList.splice(index, 1);
    }
  }

  public canSell(id: string): boolean {
    if (!this.data.isLocked) return true;

    return this._purchaseList.some(pl => pl === id);
  }

  public hasSkill(skill: ISkill): boolean {
    if (!this.data.skills) {
      this.data.skills = [];
    }

    const index = this.data.skills.findIndex(s => s.skillId === skill.id);
    return index >= 0;
  }

  public hasAllSkills(skillIds: string[]): boolean {
    if (!this.data.skills) {
      this.data.skills = [];
    }

    for (let i = 0; i < skillIds.length; ++i) {
      const index = this.data.skills.findIndex(s => s.skillId === skillIds[i]);
      if (index < 0) {
        return false;
      }
    }

    return true;
  }

  public skillRequirementsMet(skill: ISkill): boolean {
    if (!skill) return false;

    const results: boolean[] = [];

    if (skill.professionRequirement) {
      let hasProf = false;
      for (let i = 0; i < skill.professionRequirement.length; ++i) {
        if (this.hasProfessionId(skill.professionRequirement[i])) {
          hasProf = true;
          break;
        }
      }

      results.push(hasProf);
    }

    if (skill.requirements && skill.requirements.length > 0) {
      results.push(this.hasAllSkills(skill.requirements));
    }

    if (skill.spellSlotRequirement) {
      const hasSpellSlot =
        this.data.spellSlots[skill.spellSlotRequirement.level - 1].slots >=
        skill.spellSlotRequirement.minSlots;
      results.push(hasSpellSlot);
    }

    if (skill.attributeRequirement) {
      const attributeLevel =
        this.data[skill.attributeRequirement.attributeId] +
        this.getAttributeBonuses(skill.attributeRequirement.attributeId);
      results.push(attributeLevel >= skill.attributeRequirement.minValue);
    }

    // if (skill.maxCountBySkill) {
    //   const charSkill = this.data.skills.find(s => s.skillId === skill.id);
    //   const reqCharSkill = this.data.skills.find(s => s.skillId === skill.maxCountBySkill.skillId);
    //   if (reqCharSkill) {
    //     const value = Math.floor(reqCharSkill.count * skill.maxCountBySkill.multiplier) > (charSkill ? charSkill.count : 0);
    //     results.push(value);
    //   } else {
    //     results.push(false);
    //   }
    // }

    let result = true;
    results.forEach(r => {
      if (!r) {
        result = false;
      }
    });

    return result;
  }

  public requiredByOtherSkill(skill: ISkill): boolean {
    if (!this.data.skills) this.data.skills = [];
    if (this.data.skills.length === 0) return false;

    const charSkill = this.data.skills.find(s => s.skillId === skill.id);

    // Combine the skills across the character to ensure our requirements are met
    const allCharacterSkills: ICharacterSkill[] = [];
    allCharacterSkills.push(...this.data.skills);
    this.data.spellSlots.forEach(ss => {
      const spellSlotSkill = this._spellSlotsService.allSpellSlots.find(
        s => s.id === ss.spellSlotId
      );
      allCharacterSkills.push({
        skillId: spellSlotSkill.skillId,
        count: ss.slots
      });
    });

    for (let i = 0; i < allCharacterSkills.length; ++i) {
      const dataCharSkill = allCharacterSkills[i];
      this._skillsService.get(dataCharSkill.skillId).then(dataSkill => {
        if (dataSkill) {
          if (
            dataSkill.maxCountBySkill &&
            dataSkill.maxCountBySkill.skillId === skill.id
          ) {
            const value =
              (charSkill ? charSkill.count : 0) <=
              Math.ceil(
                dataCharSkill.count / dataSkill.maxCountBySkill.multiplier
              );
            return value;
          }

          if (
            dataCharSkill.count > 0 &&
            dataSkill.requirements &&
            dataSkill.requirements.includes(skill.id)
          ) {
            return true;
          }
        }
      });
    }

    return false;
  }

  public purchaseSkill(skill: ISkill): void {
    if (!this.data.skills) {
      this.data.skills = [];
    }

    if (!this.skillRequirementsMet(skill)) return; // If we don't meet the requirements then bail out.

    const index = this.data.skills.findIndex(s => s.skillId === skill.id);

    const nextSkillCount = index >= 0 ? this.data.skills[index].count + 1 : 1;
    const skillCost = SkillHelper.getSkillCost(skill, nextSkillCount);
    const totalSkillCost = SkillHelper.getTotalSkillCost(
      skill,
      nextSkillCount - 1
    );
    if (this.cpLeft < skillCost) return; // If we don't have enough CP then bail out.

    if (
      index >= 0 &&
      (skill.maxCount ? skill.maxCount : 9999) > this.data.skills[index].count
    ) {
      this.data.skills[index].count++;
      this.cpLeft -= skillCost;
      this.addPurchased(skill.id);
      this.calculateVitalityModifiers();
      this.markDirty();
    } else if (index < 0) {
      this.data.skills.push({
        skillId: skill.id,
        count: 1
      });

      this.cpLeft -= skillCost;
      this.addPurchased(skill.id);
      this.calculateVitalityModifiers();
      this.markDirty();
    }

    this.calculateCrafting();
  }

  public sellSkill(skill: ISkill, all: boolean = false): void {
    if (!this.canSell(skill.id)) return;

    if (this.requiredByOtherSkill(skill)) {
      return; // If we are required by another skill we cannot be sold.
    }

    const index = this.data.skills.findIndex(s => s.skillId === skill.id);
    if (index >= 0) {
      if (all) {
        const totalSkillCost = SkillHelper.getTotalSkillCost(
          skill,
          this.data.skills[index].count
        );
        this.cpLeft += totalSkillCost;
        this.data.skills.splice(index, 1);
      } else {
        const skillCost = SkillHelper.getSkillCost(
          skill,
          this.data.skills[index].count
        );
        this.cpLeft += skillCost;

        this.data.skills[index].count--;
        if (this.data.skills[index].count === 0) {
          this.data.skills.splice(index, 1);
        }
      }

      this.removePurchased(skill.id);
      this.calculateVitalityModifiers();
      this.markDirty();
      this.calculateCrafting();
    }
  }

  private initSpellSlots(): void {
    if (!this.data.spellSlots) {
      this.data.spellSlots = [];
    }

    for (let i = 0; i < this.data.spellSlots.length; ++i) {
      if (this.data.spellSlots[i].spells === undefined) {
        this.data.spellSlots[i].spells = [];
      }
    }

    for (
      let i = this.data.spellSlots.length;
      i < this._spellSlotsService.allSpellSlots.length;
      ++i
    ) {
      this.data.spellSlots.push({
        spellSlotId: this._spellSlotsService.allSpellSlots[i].id,
        slots: 0,
        spells: []
      });
    }

    // Remove any spells that might be in our spell list that have been deleted or moved to another level.
    // We should free up the slot to let them pick something new.
    for (let i = 0; i < this.data.spellSlots.length; ++i) {
      for (let k = this.data.spellSlots[i].spells.length - 1; k > -1; --k) {
        const spellId = this.data.spellSlots[i].spells[k];
        const index = this._spellsService.allSpellGroups[i].spells.findIndex(s => s.id === spellId);
        if (index === -1) {
          this.data.spellSlots[i].spells.splice(k, 1);
        }
      }
    }
  }

  private initProfessions(): void {
    if (!this.data.professions) {
      this.data.professions = [];
    }
  }

  public purchaseSpellSlot(spellSlot: ISpellSlot): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const slotIndex = this.data.spellSlots.findIndex(
        ss => ss.spellSlotId === spellSlot.id
      );

      // Bail out if we would be more than the previous level
      if (
        slotIndex > 0 &&
        this.data.spellSlots[slotIndex - 1].slots <=
        this.data.spellSlots[slotIndex].slots
      ) {
        return;
      }

      const arcaneMax = this.data.arcane + this.getAttributeBonuses('arcane');
      if (this.data.spellSlots[slotIndex].slots >= arcaneMax) return;

      // Only care about the pyramid max if we are beyond first level
      if (spellSlot.level > 1) {
        // Get the slot max and bail out if we are already maxed out base on the pyramid
        const pyramidMax = SpellSlotHelper.getPyramidMax(
          spellSlot.level,
          this.data.spellSlots[0].slots
        );
        if (this.data.spellSlots[slotIndex].slots >= pyramidMax) return;
      }

      this._skillsService.get(spellSlot.skillId).then(slotSkill => {
        // Make sure we can afford the skill
        const skillCost = SkillHelper.getSkillCost(
          slotSkill,
          this.data.spellSlots[slotIndex].slots + 1
        );
        if (this.cpLeft < skillCost) return;

        // We've encountered no issues up to this point; purchase the spell slot.
        this.data.spellSlots[slotIndex].slots++;
        this.cpLeft -= slotSkill.cost;
        this.addPurchased(spellSlot.skillId);
        this.markDirty();
        resolve();
      });
    });
  }

  public sellSpellSlot(spellSlot: ISpellSlot): Promise<void> {
    if (!this.canSell(spellSlot.skillId)) return;

    return new Promise<void>((resolve, reject) => {
      const slotIndex = this.data.spellSlots.findIndex(
        ss => ss.spellSlotId === spellSlot.id
      );

      if (
        slotIndex < this._spellSlotsService.allSpellSlots.length - 1 &&
        this.data.spellSlots[slotIndex + 1].slots ===
        this.data.spellSlots[slotIndex].slots
      ) {
        return;
      }

      if (this.data.spellSlots[slotIndex].slots > 0) {
        this._skillsService.get(spellSlot.skillId).then(slotSkill => {
          this.data.spellSlots[slotIndex].slots--;
          this.cpLeft += slotSkill.cost;
          this.markDirty();
          this.removePurchased(spellSlot.skillId);
          resolve();
        });
      }
    });
  }

  public hasSpell(spellSlotId: string, spell: ISpell): boolean {
    const slotIndex = this.data.spellSlots.findIndex(
      ss => ss.spellSlotId === spellSlotId
    );
    if (
      slotIndex >= 0 &&
      this.data.spellSlots[slotIndex].spells.includes(spell.id)
    ) {
      return true;
    }

    // for (let i = 0; i < this.data.spellSlots.length; ++i) {}

    return false;
  }

  public addSpell(spellSlotId: string, spell: ISpell): void {

    const slotIndex = this.data.spellSlots.findIndex(
      ss => ss.spellSlotId === spellSlotId
    );
    if (slotIndex >= 0 && !this.hasSpell(spellSlotId, spell)) {
      this.data.spellSlots[slotIndex].spells.push(spell.id);
      this.addPurchased(spell.id);
      this.markDirty();
    }
  }

  public removeSpell(spellSlotId: string, spell: ISpell): void {
    if (!this.canSell(spell.id)) return;

    const slotIndex = this.data.spellSlots.findIndex(
      ss => ss.spellSlotId === spellSlotId
    );
    if (slotIndex >= 0 && this.hasSpell(spellSlotId, spell)) {
      const spellIndex = this.data.spellSlots[slotIndex].spells.findIndex(
        s => s === spell.id
      );
      this.data.spellSlots[slotIndex].spells.splice(spellIndex, 1);
      this.removePurchased(spell.id);
      this.markDirty();
    }
  }

  public getAttributeBonuses(attributeId: string): number {
    let bonus = 0;
    if (this.data.racialBonusAttributes.includes(attributeId)) {
      const advantage = this._origin.attributeModifiers.advantages.find(a =>
        a.type.includes(attributeId)
      );
      if (advantage) {
        bonus += advantage.value;
      }
    }

    if (attributeId === 'vitality') {
      bonus += this._vitalityBonus;
    }

    return bonus;
  }

  private clearOldProfessions(
    profession: IProfession,
    allSkills: ISkill[]
  ): void {
    // Check if we already have a profession at this tier and remove it if we do
    const oldTiers = this.data.professions
      .filter(p => p.tier >= profession.tier)
      .sort((a, b) => b.tier - a.tier);
    oldTiers.forEach(oldTier => {
      const tierIndex = this.data.professions.findIndex(
        p => p.tier === oldTier.tier
      );
      if (tierIndex >= 0) {
        const oldProf = this._professionsService.allProfessions.find(
          p => p.id === oldTier.professionId
        );
        if (oldProf) {
          this.cpLeft += oldProf.cost;

          const oldProfSkills = allSkills.filter(s =>
            oldProf.skills.includes(s.id)
          );
          oldProfSkills.forEach(s => {
            this.sellSkill(s, true);
          });
        }

        this.data.professions.splice(tierIndex, 1);
      }
    });
  }

  public addProfession(profession: IProfession): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const profIndex = this.data.professions.findIndex(
        p => p.professionId === profession.id
      );
      if (profIndex >= 0) resolve(); // We already have it so don't add it again

      this._skillsService.getAll().then(allSkills => {
        this.clearOldProfessions(profession, allSkills);

        // Add the profession first so our skills below have proper requirements met
        this.data.professions.push({
          tier: profession.tier,
          professionId: profession.id
        });

        // Grab all the zero cost skills from the profession and immediately add them
        const profSkills = allSkills.filter(
          s => profession.skills.includes(s.id) && s.cost === 0
        );
        profSkills.forEach(s => {
          this.purchaseSkill(s);
        });

        this.cpLeft -= profession.cost;
        this.addPurchased(profession.id);
        this.calculateVitalityModifiers();
        this.markDirty();
        resolve();
      });
    });
  }

  public removeProfession(profession: IProfession): Promise<void> {
    if (!this.canSell(profession.id)) return Promise.resolve();

    return new Promise<void>((resolve, reject) => {
      const profIndex = this.data.professions.findIndex(
        p => p.professionId === profession.id
      );
      if (profIndex === -1) resolve(); // We don't have it so we bail

      this._skillsService.getAll().then(allSkills => {
        this.clearOldProfessions(profession, allSkills);
        this.removePurchased(profession.id);
        this.calculateVitalityModifiers();
        this.markDirty();
        resolve();
      });
    });
  }

  public hasProfessionId(profId: string): boolean {
    return this.data.professions.findIndex(p => p.professionId === profId) >= 0;
  }

  public hasProfession(profession: IProfession): boolean {
    return (
      this.data.professions.findIndex(p => p.professionId === profession.id) >=
      0
    );
  }

  public hasAnyProfession(tier: number): boolean {
    return this.data.professions.findIndex(p => p.tier === tier) >= 0;
  }

  private calculateVitalityModifiers(): void {
    // We need to find any skills that have modifiers for vitality to allow for adjustment
    this._vitalityBonus = 0;
    this._vitalityBoost = 0;

    this.data.skills.forEach(charSkill => {
      this._skillsService.get(charSkill.skillId).then(skill => {
        if (
          skill &&
          skill.attributeModifier &&
          skill.attributeModifier.attribute === 'vitality'
        ) {
          if (skill.attributeModifier.permanent) {
            this._vitalityBonus += skill.attributeModifier.value;
          } else {
            this._vitalityBoost += skill.attributeModifier.value;
          }
        }
      });
    });
  }

  public getCraftingArray(type: CraftingType): ICharacterSkill[] {
    if (!this.data.crafting) return [];

    switch (type) {
      case CraftingType.Lore:
        return this.data.crafting.lore;
      case CraftingType.Talent:
        return this.data.crafting.talents;
      default:
        return this.data.crafting.skills;
    }
  }

  public setCraftingArray(array: ICharacterSkill[], type: CraftingType): void {
    switch (type) {
      case CraftingType.Lore:
        this.data.crafting.lore = array;
        break;
      case CraftingType.Talent:
        this.data.crafting.talents = array;
        break;
      default:
        this.data.crafting.skills = array;
        break;
    }
  }

  public purchaseCraftingSkill(skill: ICraftingSkill, type: CraftingType): void {
    if (!this.data.crafting.skills) this.data.crafting.skills = [];

    // if (!this.skillRequirementsMet(skill)) return; // If we don't meet the requirements then bail out.

    const array = this.getCraftingArray(type);
    const index = array.findIndex(s => s.skillId === skill.id);
    const skillCost = 1;

    if (type === CraftingType.Talent && this._craftingTalentsLeft < skillCost) return;
    if (type !== CraftingType.Talent && this.craftingLevelLeft < skillCost) return; // If we don't have enough CP then bail out.

    if (
      index >= 0 &&
      (skill.maxCount ? skill.maxCount : 9999) > array[index].count
    ) {
      array[index].count++;

      if (type === CraftingType.Talent) this._craftingTalentsLeft -= skillCost;
      else this._craftingLevelLeft -= skillCost;

      this.addPurchased(skill.id);
      this.markDirty();
    } else if (index < 0) {
      array.push({
        skillId: skill.id,
        count: 1
      });

      if (type === CraftingType.Talent) this._craftingTalentsLeft -= skillCost;
      else this._craftingLevelLeft -= skillCost;

      this.addPurchased(skill.id);
      this.markDirty();
    }

    this.setCraftingArray(array, type);
    this.calculateCrafting();
  }

  public sellCraftingSkill(skill: ICraftingSkill, type: CraftingType, all: boolean = false): void {
    // if (this.requiredByOtherSkill(skill)) {
    //   return; // If we are required by another skill we cannot be sold.
    // }

    if (!this.canSell(skill.id)) return;

    const array = this.getCraftingArray(type);
    const index = array.findIndex(s => s.skillId === skill.id);
    if (index >= 0) {
      if (all) {
        const totalSkillCost = array[index].count;

        if (type === CraftingType.Talent) this._craftingTalentsLeft += totalSkillCost;
        else this._craftingLevelLeft += totalSkillCost;

        array.splice(index, 1);
        this.removePurchased(skill.id);
      } else {
        const skillCost = 1;

        if (type === CraftingType.Talent) this._craftingTalentsLeft += skillCost;
        else this._craftingLevelLeft += skillCost;

        array[index].count--;
        if (array[index].count === 0) {
          array.splice(index, 1);
        }

        this.removePurchased(skill.id);
      }

      this.calculateCrafting();
      this.markDirty();
    }
  }

  private calculateCrafting(): void {
    const craftSkill = this.data.skills.find(s => s.skillId === 'craft');
    if (craftSkill) {
      this._craftingLevel = craftSkill.count;
      this._craftingTalents = Math.floor(this._craftingLevel / 3);

      if (!this.data.crafting) {
        this.data.crafting = {
          skills: [],
          lore: [],
          talents: []
        };
      }

      if (!this.data.crafting.skills) this.data.crafting.skills = [];
      if (!this.data.crafting.lore) this.data.crafting.lore = [];
      if (!this.data.crafting.talents) this.data.crafting.talents = [];

      const craftingSkillCount = this.data.crafting.skills.reduce((a, b) => a + b.count, 0);
      const craftingLoreCount = this.data.crafting.lore.reduce((a, b) => a + b.count, 0);
      this._craftingLevelLeft = this._craftingLevel - craftingSkillCount - craftingLoreCount;

      const craftingTalentsCount = this.data.crafting.talents.reduce((a, b) => a + b.count, 0);
      this._craftingTalentsLeft = this._craftingTalents - craftingTalentsCount;
    }
  }

  public initXpLog(): void {
    if (!this.data.xpLog) this.data.xpLog = [];
  }

  public initEvents(): void {
    if (!this.data.events) this.data.events = [];
  }

  public initStatus(): void {
    if (!this.data.status) this.data.status = 'Active';
  }

  public initSkills(): void {
    if (!this.data.skills) this.data.skills = [];
  }

  public initCRUXCitizenshipDetails(): void {
    if (!this.data.citizenship) {
      this.data.citizenship = {
        location: '',
        yearsServed: 0,
        title: '',
        typeOfCitizen: '',
        homeland: ''
      };
    } else {
      if (!this.data.citizenship.location) this.data.citizenship.location = '';
      if (!this.data.citizenship.yearsServed) {
        this.data.citizenship.yearsServed = 0;
      }
      if (!this.data.citizenship.title) this.data.citizenship.title = '';
      if (!this.data.citizenship.typeOfCitizen) {
        this.data.citizenship.typeOfCitizen = '';
      }
      if (!this.data.citizenship.homeland) this.data.citizenship.homeland = '';
    }
  }

  public setType(type: string): void {
    this.data.type = type;
  }

  public markDirty(): void {
    this.isDirty = true;
  }

  public markClean(): void {
    this.isDirty = false;
  }
}

export interface IXpLogEntry {
  date: string;
  xpValue: number;
  eventId?: string;
  reason: string;
}

export interface ICharacterEvent {
  eventId: string;
  pel?: PostEventLetter;
}

export interface PostEventLetter {
  text: string;
  submitDate: string;
  updatedDate: string;
}

export interface ICharacterSkill {
  skillId: string;
  count: number;
}

export interface ICharacterSkillView {
  skill: ISkill;
  count: number;
}

export interface ICharacterSpellSlot {
  spellSlotId: string;
  slots: number;
  spells: string[];
}

export interface ICharacterProfession {
  tier: number;
  professionId: string;
}

export interface ICRUXCitizenshipDetails {
  location: string;
  yearsServed: number;
  title: string;
  typeOfCitizen: string;
  homeland: string;
}


export interface ICharacterCrafting {
  skills: ICharacterSkill[];
  lore: ICharacterSkill[];
  talents: ICharacterSkill[];
}
