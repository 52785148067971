import { IOutlineItem } from '../interfaces/outline';

export class OutlineHelper {
  public static createHeaders(text: string, level: number, raw: string, items: IOutlineItem[]): string {
    const cleanText = text.replace(/[^a-zA-Z ]/g, '');
    const dashText = cleanText.replace(/\s+/g, '-').toLowerCase();

    const newOutlineItem: IOutlineItem = { open: false, text: text, link: dashText, subItems: [] };
    const prevIndex = items.length - 1;
    if (level === 3) {
      const prevSubIndex = items[prevIndex].subItems.length - 1;
      items[prevIndex].subItems[prevSubIndex].subItems.push(newOutlineItem);
    } else if (level === 2) {
      items[prevIndex].subItems.push(newOutlineItem);
    } else if (level === 1) {
      items.push(newOutlineItem);
    }

    return `<h${level} id="${dashText}">${text}</h${level}>`;
  }
}
