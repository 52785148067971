import { Pipe, PipeTransform } from '@angular/core';
import { ChapterService } from '../services/chapter.service';

@Pipe({
  name: 'chapterName'
})
export class ChapterNamePipe implements PipeTransform {
  constructor(private _chapterService: ChapterService) { }

  transform(id: string): any {
    const chapter = this._chapterService.chapters.find(a => a.id === id);
    return chapter ? chapter.name : 'Chapter not found';
  }

}
