import { Component, OnInit, Input } from '@angular/core';
import { HomeTileType } from '../../../enums/homeTileType';

@Component({
  selector: 'app-home-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss', '../home.component.scss', '../../tiles.scss']
})
export class HomeTileComponent implements OnInit {
  @Input() tileType: HomeTileType;
  @Input() background = 1;

  public HomeTileType = HomeTileType;
  public backgroundClass = 'background-1';

  constructor() { }

  ngOnInit() {
    if (this.tileType === HomeTileType.Questline) {
      this.backgroundClass = 'background-questline';
    } else {
      this.backgroundClass = 'background-' + this.background;
    }

  }

}
