import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from './../delete-dialog/delete-dialog.component';
import { PageService } from './../../services/page.service';
import { AuthService } from './../../services/auth.service';
import { Observable, Subscription } from 'rxjs';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { IComment, ICommentEntry } from '../../interfaces/comment';
import { AngularFireObject, AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { serverTimestamp } from 'firebase/database';
import * as moment from 'moment';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import * as marked from 'marked';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['../button.scss', './comments.component.scss']
})
export class CommentsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() id: string;
  @Output() commentsCount: EventEmitter<any> = new EventEmitter();
  @ViewChild('newEntryText', { static: false }) newEntryElementRef: ElementRef;
  @ViewChild(PerfectScrollbarComponent, { static: false }) componentScroll: PerfectScrollbarComponent;

  public commentsPsConfig: PerfectScrollbarConfigInterface = {
    wheelPropagation: true
  };

  public previewConfig: PerfectScrollbarConfigInterface = {
  };

  public comment: IComment;
  public entries: ICommentEntry[];
  public canPost = false;
  public showPreview = false;
  public preview = '';

  private _uid: string;
  private _commentRef: AngularFireObject<IComment>;
  private _entriesRef: AngularFireList<ICommentEntry>;

  private _commentSub: Subscription;
  private _entriesSub: Subscription;
  private _dialogCloseSub: Subscription;

  constructor(
    private _db: AngularFireDatabase,
    private _authService: AuthService,
    public pageService: PageService,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    this._authService.user.subscribe(user => {
      if (user) {
        this.canPost = true;
        this._uid = user.uid;
      }
    });
  }

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges['id'] && simpleChanges['id'].currentValue) {
      this._commentRef = this._db.object<IComment>('comments/' + simpleChanges['id'].currentValue + '?shallow=true');
      this._commentSub = this._commentRef.valueChanges().subscribe(comment => {
        this.comment = comment;
      });

      this._entriesRef = this._db.list<ICommentEntry>('comments/' + simpleChanges['id'].currentValue + '/entries', ref =>
        ref.orderByChild('postTime')
      );
      this._entriesSub = this._entriesRef.snapshotChanges().subscribe(snapshot => {
        this.entries = snapshot.map(s => ({ key: s.key, ...s.payload.val() }));
        this.commentsCount.emit(this.entries.length);

        // setTimeout(() => {
        //   this.componentScroll.directiveRef.scrollToBottom();
        // }, 100);
      });
    }
  }

  public ngOnDestroy(): void {
    if (this._commentSub) this._commentSub.unsubscribe();
    if (this._entriesSub) this._entriesSub.unsubscribe();
    if (this._dialogCloseSub) this._dialogCloseSub.unsubscribe();
  }

  public ngAfterViewInit(): void {
    // this.componentScroll.directiveRef.scrollToBottom();
  }

  public onClickPost(text: string): void {
    if (text.trim().length === 0) return;

    const newEntry: ICommentEntry = {
      text: text,
      accountId: this._uid,
      postTime: Number.MAX_SAFE_INTEGER - new Date().getTime(), // Use this to sort by descending order
      postDate: serverTimestamp(),
      reactions: []
    };

    this._entriesRef.push(newEntry).then(() => {
      this.newEntryElementRef.nativeElement.value = '';
      this.preview = '';
      this.onClickHidePreview();
      // this.componentScroll.directiveRef.scrollToBottom();
    });
  }

  public handleDelete(entry: ICommentEntry): void {
    this._entriesRef.remove(entry.key);
  }

  public onClickDeleteAll(): void {
    const dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '300px',
      height: '200px',
      data: {
        title: 'Delete all comments?',
        text: 'Are you sure you wish to delete ALL of the comments? You cannot undo this.'
      },
      panelClass: 'crux-dialog'
    });

    this._dialogCloseSub = dialogRef.afterClosed().subscribe(result => {
      // Yes, we have confirmed a delete request
      if (result) {
        this._entriesRef.remove();
      }
    });
  }

  public onClickHidePreview(): void {
    this.showPreview = false;
  }

  public onClickShowPreview(): void {
    this.showPreview = true;
    this.preview = marked(this.newEntryElementRef.nativeElement.value);
  }

  public updatePreview(): void {
    this.preview = marked(this.newEntryElementRef.nativeElement.value);
  }
}
