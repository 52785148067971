import { IAccountInfo } from './../interfaces/accountInfo';
import { Subscription } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Injectable, OnDestroy } from '@angular/core';
import { IProfile } from '../interfaces/accountInfo';
import { IChatPlayerInfo } from '../interfaces/chat';

@Injectable()
export class PlayersService implements OnDestroy {
  private _accounts = {};
  private _accountSub: Subscription;

  constructor(private _db: AngularFireDatabase) {
    this.getAll();
  }

  private getAll(): void {
    this._db.object<any>('accounts').valueChanges().subscribe(accounts => {
      this._accounts = accounts;
    });
  }

  public getAllProfilesForChat(): Promise<IChatPlayerInfo[]> {
    return new Promise<IChatPlayerInfo[]>((resolve, reject) => {
      const data: IChatPlayerInfo[] = [];

      for (const key in this._accounts) {
        if (this._accounts.hasOwnProperty(key)) {
          data.push({
            uid: key,
            displayName: this._accounts[key].profile.displayName,
            avatarUrl: this._accounts[key].profile.avatarUrl,
            channels: this._accounts[key].chatData ? this._accounts[key].chatData.channels : [],
            invites: this._accounts[key].chatData ? this._accounts[key].chatData.invites : [],
          });
        }
      }

      resolve(data);
    });
  }

  public getProfile(uid: string): Promise<IProfile> {
    return new Promise((resolve, reject) => {
      const account = this._accounts[uid] as IAccountInfo;
      if (!account) {
        setTimeout(() => {
          resolve(this.getProfile(uid));
        }, 100);
      } else {
        resolve(account.profile);
      }
    });
  }

  public ngOnDestroy(): void {
    if (this._accountSub) this._accountSub.unsubscribe();
  }
}
