import { Subscription } from 'rxjs';
import { AccountService } from './../../../services/account.service';
import { IEvent, IEventPlayer } from './../../../interfaces/event';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-event-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class EventSelectorComponent implements OnInit, OnChanges {
  @Input() event: IEvent;
  @Input() isLast: boolean;
  @Output() eventClicked: EventEmitter<IEvent> = new EventEmitter();

  public playerCount = 0;
  public pcCost = 0;
  public npcCost = 0;

  private _paypalSub: Subscription;

  constructor(private _accountService: AccountService) { }

  ngOnInit() {
    if (this.event) {
      this.pcCost = this.event.cost * this.event.length;
      this.npcCost = this.event.npcCost * this.event.length;

      this._paypalSub = this._accountService.paypalSubscription.subscribe(paypalSub => {
        if (paypalSub) {
          const discount = (paypalSub.status !== 'ACTIVE' ? 0 : this.event.memberDiscount) * this.event.length;
          this.pcCost -= discount;
          this.npcCost -= discount;
        }
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['event'] && changes['event'].currentValue) {
      this.playerCount = 0;
      for (const key in this.event.players) {
        if (!this.event.players.hasOwnProperty(key)) continue;

        const eventPlayer = this.event.players[key] as IEventPlayer;
        if (eventPlayer.status === 'Cancelled') continue;

        ++this.playerCount;
      }
    }
  }

  public onClickEvent(): void {
    this.eventClicked.emit(this.event);
  }
}
