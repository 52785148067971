import { IOrigin } from '../interfaces/origin';
import { OriginsService } from '../services/origins.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'origin'
})
export class OriginPipe implements PipeTransform {

  constructor(private _originsService: OriginsService) { }

  transform(id: string): Promise<IOrigin> {
    return new Promise<IOrigin>((resolve, reject) => {
      this._originsService.get(id).then(origin => {
        resolve(origin);
      }, err => reject(err));
    });
  }

}
