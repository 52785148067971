import { OriginsService } from './../../../services/origins.service';
import { Character } from './../../../interfaces/character';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IProfile } from '../../../interfaces/accountInfo';

@Component({
  selector: 'app-character-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['../../character.scss', './selector.component.scss']
})
export class CharacterSelectorComponent implements OnInit, OnChanges {
  @Input() character: Character;
  @Input() profile: IProfile;
  @Output() selected: EventEmitter<Character> = new EventEmitter();

  public originName: string;

  constructor(private _originsService: OriginsService) {}

  ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['character'] && changes['character'].currentValue) {
      this._originsService.get(this.character.data.originId).then(origin => {
        this.originName = origin.name;
      });
    }
  }

  public onClickCharacter(): void {
    this.selected.emit(this.character);
  }
}
