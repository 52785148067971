import { Component, OnInit, Input } from '@angular/core';
import { IAttribute } from '../../../interfaces/attribute';

@Component({
  selector: 'app-tooltip-attribute',
  templateUrl: './attribute.component.html',
  styleUrls: ['./attribute.component.scss']
})
export class TooltipAttributeComponent implements OnInit {
  @Input() attribute: IAttribute;
  @Input() data: any;

  constructor() { }

  ngOnInit() {
  }

}
