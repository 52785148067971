import { ISkill } from './../interfaces/skill';
import { JsonService } from './json.service';
import { Injectable } from '@angular/core';

@Injectable()
export class SkillsService {
  private _allSkills: ISkill[] = [];
  private _loading = false;

  constructor(private _jsonService: JsonService) {
  }

  public getAll(): Promise<ISkill[]> {
    return new Promise((resolve, reject) => {
      if (this._loading) {
        setTimeout(() => {
          resolve(this.getAll());
        }, 100);
      } else {
        if (this._allSkills.length === 0) {
          this._loading = true;
          this._jsonService.get('skills').then(skills => {
            this._allSkills = skills;
            this._loading = false;
            resolve(skills);
          });
        } else {
          resolve(this._allSkills);
        }
      }
    });
  }

  public get(id: string): Promise<ISkill> {
    return new Promise((resolve, reject) => {
      if (this._loading) {
        setTimeout(() => {
          resolve(this.get(id));
        }, 100);
      } else {
        if (this._allSkills.length === 0) {
          this._jsonService.get('skills').then(skills => {
            this._allSkills = skills;
            resolve(this.findSkill(id));
          });
        } else {
          resolve(this.findSkill(id));
        }
      }
    });
  }

  private findSkill(id: string): ISkill {
    return this._allSkills.find(r => r.id === id);
  }
}
