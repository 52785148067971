export class SpellSlotHelper {
  //                              0                 6     8       11          15             20
  public static MAX_TREE_SLOTS = [0, 0, 0, 0, 0, 0, 1, 2, 2, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 5];

  public static getPyramidMax(level: number, baseValue: number): number {
    if (level < 0) return 0;
    if (baseValue > 5) return baseValue;

    const slotIndex = SpellSlotHelper.getSlotIndex(baseValue);
    return this.MAX_TREE_SLOTS[slotIndex - level + 1];
  }

  public static getSlotIndex(count: number): number {
    let index = 6;
    for (let i = 1; i < count; ++i) {
      index += (i + 1);
    }

    return index;
  }
}
