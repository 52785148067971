import { ISpellSlot } from './../../../../../interfaces/spell';
import { SpellSlotsService } from './../../../../../services/spell-slots.service';
import { ISkill } from './../../../../../interfaces/skill';
import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ICharacterSpellSlot } from '../../../../../interfaces/character';
import { SkillsService } from '../../../../../services/skills.service';

@Component({
  selector: 'app-character-card-print-spell-slots',
  templateUrl: './spell-slots.component.html',
  styleUrls: ['../../../../../../print.css', './spell-slots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardPrintSpellSlotsComponent implements OnInit, OnChanges {

  @Input() characterSpellSlots: ICharacterSpellSlot[] = [];

  public spellSlots: IPrintSpellSlot[] = [];

  constructor(private _cdr: ChangeDetectorRef, private _skillsService: SkillsService, private _spellSlotsService: SpellSlotsService) { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['characterSpellSlots'] && changes['characterSpellSlots'].currentValue) {
      this.spellSlots = [];
      this.characterSpellSlots.forEach(charSpellSlot => {
        const spellSlot = this._spellSlotsService.allSpellSlots.find(s => s.id === charSpellSlot.spellSlotId);
        this.spellSlots.push({slot: spellSlot, count: charSpellSlot.slots});
      });
    }
  }

  public markForCheck(): void {
    this._cdr.markForCheck();
  }
}

export interface IPrintSpellSlot {
  slot: ISpellSlot;
  count: number;
}
