import { PlayersService } from './../services/players.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'player'
})
export class PlayerPipe implements PipeTransform {

  constructor(private _playerService: PlayersService) {
  }

  transform(uid: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this._playerService.getProfile(uid).then(profile => {
        resolve(profile.displayName);
      }, err => reject(err));
    });
  }

}
