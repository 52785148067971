import { SpellsService } from './../../services/spells.service';
import { AccountService } from './../../services/account.service';
import { SpellSlotsService } from './../../services/spell-slots.service';
import { ProfessionsService } from './../../services/professions.service';
import { SkillsService } from './../../services/skills.service';
import { ICharacter, Character } from './../../interfaces/character';
import { AngularFireDatabase, AngularFireAction } from '@angular/fire/compat/database';
import { Subject, Subscription, Observable } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DataSnapshot } from '@firebase/database-types';
import { OriginsService } from '../../services/origins.service';
import { PlayersService } from '../../services/players.service';
import { IProfile } from '../../interfaces/accountInfo';
import { debounceTime, take } from 'rxjs/operators';

@Component({
  selector: 'app-find-character-dialog',
  templateUrl: './find-character-dialog.component.html',
  styleUrls: ['../tiles.scss', '../section.scss', '../button.scss', './find-character-dialog.component.scss']
})
export class FindCharacterDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('filter', { static: false }) filterElementRef: ElementRef;

  private _filterUpdate: Subject<string> = new Subject<string>();
  private _filterObs: Observable<string> = this._filterUpdate.asObservable();

  private _charsSub: Subscription;
  private _allCharacters: IPlayerCharacterData[] = [];

  public playerCharacters: IPlayerCharacter[] = [];

  constructor(
    public dialogRef: MatDialogRef<FindCharacterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _db: AngularFireDatabase,
    private _spellSlotsService: SpellSlotsService,
    private _spellsService: SpellsService,
    private _skillsService: SkillsService,
    private _professionsService: ProfessionsService,
    private _originsService: OriginsService,
    private _playersService: PlayersService,
    private _accountsService: AccountService
  ) { }

  public ngOnInit(): void {
    this._charsSub = this._db
      .list('characters')
      .snapshotChanges()
      .pipe(take(1))
      .subscribe((snaps: AngularFireAction<DataSnapshot>[]) => {
        this._allCharacters = [];
        snaps.forEach(snap => {
          const val = snap.payload.val();
          const promises = [];
          for (const key in val) {
            if (val.hasOwnProperty(key)) {
              promises.push(
                this._playersService.getProfile(snap.key).then(p => {
                  this._allCharacters.push({ playerId: snap.key, character: val[key] as ICharacter, profile: p });
                })
              );
            }
          }

          Promise.all(promises).then(() => {
            this.playerCharacters = this.mapAllCharacters();
          });
        });
      });

    this._filterObs.pipe(debounceTime(300)).subscribe(value => {
      const filter = value.trim().toLowerCase();
      if (filter.length > 1) {
        const characters = this._allCharacters.filter(c => c.character.name.toLowerCase().startsWith(filter));
        const list: IPlayerCharacter[] = [];
        characters.forEach(c => {
          list.push({
            playerId: c.playerId,
            character: new Character(c.character, this._skillsService, this._spellSlotsService, this._spellsService, this._professionsService, this._originsService),
            profile: c.profile
          });
        });

        this.playerCharacters = list.sort(this.sortByName);
      } else {
        this.playerCharacters = this.mapAllCharacters();
      }
    });
  }

  public ngAfterViewInit(): void {
    this.filterElementRef.nativeElement.focus();
  }

  public ngOnDestroy(): void {
    if (this._charsSub) this._charsSub.unsubscribe();
  }

  public onClickClose(): void {
    this.dialogRef.close();
  }

  public onClickCharacter(playerCharacter: IPlayerCharacter): void {
    this.dialogRef.close({ characterId: playerCharacter.character.data.id, uid: playerCharacter.playerId });
  }

  public onKeyupFilter(value: string): void {
    this._filterUpdate.next(value);
  }

  private mapAllCharacters(): IPlayerCharacter[] {
    return this._allCharacters
      .map(c => {
        return {
          playerId: c.playerId,
          character: new Character(c.character, this._skillsService, this._spellSlotsService, this._spellsService, this._professionsService, this._originsService),
          profile: c.profile
        };
      })
      .sort(this.sortByName);
  }

  private sortByName(a: IPlayerCharacter, b: IPlayerCharacter): number {
    if (a.character.data.name < b.character.data.name) return -1;
    if (a.character.data.name > b.character.data.name) return 1;
    return 0;
  }
}

export interface IPlayerCharacterData {
  playerId: string;
  character: ICharacter;
  profile: IProfile;
}

export interface IPlayerCharacter {
  playerId: string;
  character: Character;
  profile: IProfile;
}
