import { SkillsService } from './../../../services/skills.service';
import { ISkill } from './../../../interfaces/skill';
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { SkillHelper } from '../../../helpers/skill.helper';

@Component({
  selector: 'app-tooltip-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss']
})
export class TooltipSkillComponent implements OnInit, OnChanges {
  @Input() skill: ISkill;
  @Input() data: any;

  public requirementsText = '';
  public verbalText = '';
  public cost = 0;

  constructor(private _skillsService: SkillsService) { }

  ngOnInit() { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['skill']) {
      if (this.skill.requirements) {
        this.requirementsText = '';
        for (let i = 0; i < this.skill.requirements.length; ++i) {
          const skillId = this.skill.requirements[i];
          this._skillsService.get(skillId).then(requiredSkill => {
            if (i > 0 && i < this.skill.requirements.length) {
              this.requirementsText += ', ';
            }

            if (requiredSkill) {
              this.requirementsText += requiredSkill.name;
            }
          });
        }

        this.requirementsText = this.requirementsText.substring(0, this.requirementsText.length - 6);
      }

      this.verbalText = SkillHelper.getVerbalText(this.skill, 1);

      this.calculateSkillCost();
    }
  }

  private calculateSkillCost(): void {
    this.cost = SkillHelper.getSkillCost(this.skill, this.data);
  }
}
