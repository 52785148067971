import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChildren, QueryList, Input } from '@angular/core';
import { SkillsService } from '../../../../../services/skills.service';
import { ISkill } from '../../../../../interfaces/skill';
import { Character } from '../../../../../interfaces/character';
import { CharacterCardSkillSlotComponent } from '../slot/slot.component';

@Component({
  selector: 'app-character-card-skills-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardSkillsGroupComponent implements OnInit {
  @Input() type: string;
  public character: Character;
  public skills: ISkill[] = [];

  @ViewChildren(CharacterCardSkillSlotComponent) skillSlots: QueryList<CharacterCardSkillSlotComponent>;

  private _parent: any;

  constructor(private _skillsService: SkillsService, private _cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this._skillsService.getAll().then(skills => {
      this.skills = skills.filter(s => s.type === this.type);
      this._cdr.markForCheck();
    });
  }

  public updateCharacter(character: Character, parent: any): void {
    this.character = character;
    this._parent = parent;
    this.updateSkills();

    this.skillSlots.forEach(slot => {
      slot.updateCharacter(character, this);
    });
    this._cdr.markForCheck();
  }

  public markForCheck(): void {
    this._cdr.markForCheck();

    this.skillSlots.forEach(slot => {
      slot.markForCheck();
    });
  }

  private updateSkills(): void { }

  public checkDetails(): void {
    this._parent.checkDetails();
  }

  public checkPrint(): void {
    this._parent.checkPrint();
  }
}
