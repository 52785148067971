import { IProfession } from './../interfaces/profession';
import { ProfessionsService } from './../services/professions.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'profession'
})
export class ProfessionPipe implements PipeTransform {

  constructor(private _professionsService: ProfessionsService) { }

  transform(id: string): Promise<IProfession> {
    return new Promise<IProfession>((resolve, reject) => {
      this._professionsService.get(id).then(prof => {
        return prof;
      }, err => reject(err));
    });
  }

}
