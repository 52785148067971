import { OriginsService } from '../../services/origins.service';
import { JsonService } from '../../services/json.service';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { IOrigin } from '../../interfaces/origin';
import { Router, ActivatedRoute } from '@angular/router';
import { UuidHelper } from '../../helpers/uuid.helper';
import { ISkill } from '../../interfaces/skill';
import { Subscription, Observable } from 'rxjs';
import { PageService } from '../../services/page.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-origins',
  templateUrl: './origins.component.html',
  styleUrls: ['../toolbar.scss', '../page.scss', '../tiles.scss', './origins.component.scss']
})
export class OriginsComponent implements OnInit, AfterViewInit, OnDestroy {
  public allOrigins: IOrigin[] = [];
  public gridColumns = 1;

  private _urlSub: Subscription;
  private _minTileWidth = 300; // in pixels

  @ViewChild('page', { static: false }) page: ElementRef;

  constructor(
    public _router: Router,
    private _originsService: OriginsService,
    private _activatedRoute: ActivatedRoute,
    public pageService: PageService
  ) {
    this._urlSub = this._activatedRoute.url.pipe(map(segments => segments.join(''))).subscribe(url => {
      this.pageService.setTarget(url);
    });
  }

  ngOnInit() {
    this._originsService.getAll().then(origins => {
      this.allOrigins = origins;
    });
  }

  ngAfterViewInit() {
    this.calculateGridColumns();
  }

  public ngOnDestroy(): void {
    this._urlSub.unsubscribe();
  }

  public calculateGridColumns(): void {
    const width = parseInt(this.page.nativeElement['offsetWidth'], 10);
    setTimeout(() => {
      this.gridColumns = Math.trunc(width / this._minTileWidth);
    }, 1);

  }
}
