import { MobileNavService } from './services/mobile-nav.service';
import { environment } from './../environments/environment';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AuthDialogComponent } from './components/auth-dialog/auth-dialog.component';
import { DropMenuComponent } from './components/drop-menu/drop-menu.component';
import { Router } from '@angular/router';
import { INavTarget } from './interfaces/navTarget';
import { IAccountInfo } from './interfaces/accountInfo';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { AccountService } from './services/account.service';
import { PageService } from './services/page.service';
import { SpellsService } from './services/spells.service';
import { ProfessionsService } from './services/professions.service';
import { AttributesService } from './services/attributes.service';
import { AuthService } from './services/auth.service';
import { Component, ChangeDetectorRef, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SkillsService } from './services/skills.service';
import { OriginsService } from './services/origins.service';
import { SpellSlotsService } from './services/spell-slots.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { DropMenuType } from './enums/dropMenuType';
import { ChatService } from './services/chat.service';
import FirebaseAuth from 'firebase/auth';
import { DropMenuService } from './services/drop-menu.service';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: []
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'app';
  private _mobileQueryListener: () => void;
  public pageTitle = 'Home';
  public user: FirebaseAuth.User;
  public accountInfo: IAccountInfo;
  public dropMenuType = DropMenuType.None;
  public DropMenuType = DropMenuType;
  public newChat = false;
  public chatOpened = false;

  public psConfig: PerfectScrollbarConfigInterface = {
    suppressScrollY: true
  };

  private _pageTitleSub: Subscription;
  private _accountRef: AngularFireObject<IAccountInfo>;

  @ViewChild(MatSidenav, { static: false }) matSidenavComponent: MatSidenav;
  @ViewChild(DropMenuComponent, { static: false }) dropMenuComponent: DropMenuComponent;
  @ViewChild(SideNavComponent, { static: false }) sidenavComponent: SideNavComponent;

  constructor(
    public pageService: PageService,
    private _authService: AuthService,
    private _accountService: AccountService,
    private _skillsService: SkillsService,
    private _attributesService: AttributesService,
    private _professionsService: ProfessionsService,
    private _originsService: OriginsService,
    private _spellsService: SpellsService,
    private _spellSlots: SpellSlotsService,
    private _cdr: ChangeDetectorRef,
    private _media: MediaMatcher,
    private _db: AngularFireDatabase,
    private _router: Router,
    private _dialog: MatDialog,
    private _dropMenuService: DropMenuService,
    private _mobileNavService: MobileNavService,
    public chatService: ChatService,
    private _fns: AngularFireFunctions,
    private _gas: GoogleAnalyticsService
  ) {
    this._mobileQueryListener = () => _cdr.detectChanges();
    this.pageService.mobileQuery = _media.matchMedia('(max-width: 1023px)');
    this.pageService.mobileQuery.addListener(this._mobileQueryListener);

    this.pageService.normalQuery = _media.matchMedia('(max-width: 1919px)');
    this.pageService.normalQuery.addListener(this._mobileQueryListener);

    this.pageService.hdQuery = _media.matchMedia('(max-width: 9999px)');
    this.pageService.hdQuery.addListener(this._mobileQueryListener);

    this._originsService.getAll(); // Preload all the origins
    this._skillsService.getAll(); // Preload all the skills
    this._professionsService.getAll(); // Preload all the professions
  }

  public ngOnInit(): void {
    this._pageTitleSub = this.pageService.pageTitle.subscribe(title => {
      this.pageTitle = title;
    });

    this._authService.user.subscribe(user => {
      if (user) {
        this.user = user;
        const isProduction = environment.production; // Override this if you want to test something locally

        this._accountRef = this._db.object('accounts/' + user.uid);
        this._accountRef.valueChanges().subscribe(info => {
          if (!info) {
            const newInfo: IAccountInfo = {
              profile: {
                displayName: user.displayName,
                email: user.email,
                avatarUrl: '/assets/images/default_avatar.png',
                maxCharacters: isProduction ? 3 : 100,
              },
              roles: [],
              chatData: {
                channels: ['public', 'out-of-game', 'in-game'],
                status: 'Active',
                doNotDisturb: false
              },
              membership: {
                subscriptionId: '',
                isActive: false,
                lastPaidDate: ''
              }
            };

            this._accountRef.set(newInfo).then(() => {
              this._accountService.accountInfo.next(newInfo);
              this._gas.event('account', 'new_account', `${newInfo.profile.displayName} (${user.email}) [${user.uid}]`);
            });
          } else {
            let updateInfo = false;
            if (!info.profile.maxCharacters) {
              info.profile.maxCharacters = isProduction ? 3 : 100;
              updateInfo = true;
            } else if (info.profile.maxCharacters <= 2 && isProduction) {
              info.profile.maxCharacters = 3;
              updateInfo = true;
            } else if (info.profile.maxCharacters <= 3 && info.membership.isActive && isProduction) { // Update all active accounts to have a max of 4 characters
              info.profile.maxCharacters = 4;
              updateInfo = true;
            }

            if (!info.profile.email) {
              info.profile.email = user.email;
              updateInfo = true;
            }

            if (!info.chatData) {
              info.chatData = {
                channels: ['public', 'out-of-game', 'in-game'],
                status: 'Active',
                doNotDisturb: false
              };
              updateInfo = true;
            }

            if (!info.membership) {
              info.membership = {
                subscriptionId: '',
                isActive: false,
                lastPaidDate: ''
              };
              updateInfo = true;
            }

            if (updateInfo) {
              this._accountRef.update(info).then(() => {
                updateInfo = false;
              });
            }

            this._accountService.accountInfo.next(info);
          }
        });
      }
    });

    this._accountService.accountInfo.subscribe(info => {
      this.accountInfo = info;
    });

    this._dropMenuService.dropMenuType.subscribe(type => {
      if (!this.pageService.isMobile && type) {
        this.onClickDropMenu(type);
      }
    });
  }

  public ngAfterViewInit(): void {
    this._mobileNavService.chatToggle.subscribe(value => {
      this.chatOpened = value;
    });

    this._mobileNavService.charToggle.subscribe(value => {
      if (value) {
        const navType = this.pageService.navTargets.find(n => n.target === 'characters');
        this.sidenavComponent.onClickNav(navType);
        this.openSideNav();
      }
    });

    this._accountService.accountInfo.subscribe(info => {
      if (info && info.membership && info.membership.subscriptionId !== '') {
        // Connect to Paypal API and verify account status
        const getSubscription = this._fns.httpsCallable('getSubscription');
        getSubscription({ subscriptionId: info.membership.subscriptionId }).subscribe(results => {
          this._accountService.paypalSubscription.next(results);

          // If the membership has bene cancelled or expired clear the subscription to force a new one to be made
          if (results.status === 'CANCELLED' || results.status === 'EXPIRED') {
            // const updatedMembership = { membership: { ...info.membership, subscriptionId: '' } };
            // this._accountRef.update(updatedMembership).then(() => { });
          }
        });
      }
    });
  }

  public ngOnDestroy(): void {
    this._pageTitleSub.unsubscribe();
    this.pageService.mobileQuery.removeListener(this._mobileQueryListener);
  }

  public onClickDropMenu(type: DropMenuType): void {
    if (type === DropMenuType.Avatar && !this.user) return;

    const isTall = true;
    const isWide = type !== DropMenuType.Avatar;
    if (this.dropMenuType !== type) {
      this.dropMenuComponent.changeType(type, isTall, isWide);
    } else if (this.dropMenuType === type) {
      this.dropMenuComponent.changeType(DropMenuType.None, isTall, isWide);
    }
  }

  public onClickNav(navTarget: INavTarget): void {
    if (navTarget.useList) {
      this.pageService.setTarget(navTarget.target);
      return;
    }

    this._router.navigate(['/' + navTarget.target]);
    this.dropMenuComponent.changeType(DropMenuType.None);
  }

  public onClickLogin(): void {
    const dialogRef = this._dialog.open(AuthDialogComponent, {
      width: '300px',
      height: '400px',
      data: null,
      panelClass: 'crux-dialog'
    });
  }

  public openSideNav(): void {
    this.matSidenavComponent.open();
  }

  public closeSideNav(): void {
    this.matSidenavComponent.close();
  }
}
