import { PaypalHelper } from './../../helpers/paypal.helper';
import { loadScript } from '@paypal/paypal-js';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AccountService } from './../../services/account.service';
import { AuthService } from './../../services/auth.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { AngularFireObject, AngularFireDatabase } from '@angular/fire/compat/database';
import { IAccountInfo, IMembership } from './../../interfaces/accountInfo';
import FirebaseAuth from 'firebase/auth';
import { Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { MembershipTypes } from '../../enums/membershipTypes';
import { environment } from '../../../environments/environment';
import { MembershipsService } from '../../services/memberships.service';
import { IMembershipDetails } from '../../interfaces/memberships';
import { PageService } from '../../services/page.service';

@Component({
  selector: 'app-memberships',
  templateUrl: './memberships.component.html',
  styleUrls: ['../tiles.scss', './memberships.component.scss', '../button.scss']
})
export class MembershipsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('paypalButton', { static: false }) paypalButtonElement: ElementRef;
  public MembershipTypes = MembershipTypes;

  public loading = true;
  public selectedMembershipType: MembershipTypes | null = null;
  public isActive = false;
  public paypalSubscription = null;
  public membershipTypeText = 'None';
  public activeMembershipType: MembershipTypes | null = null;
  public updating = false;

  public memberships: IMembershipDetails[];

  private _user: FirebaseAuth.User;
  private _accountInfo: IAccountInfo;
  private _accountRef: AngularFireObject<IAccountInfo>;

  private _paypalButton: any;
  private _buttonRendered = false;
  private _initializingButton = false;
  private _paypal = null;

  constructor(
    private _authService: AuthService,
    private _db: AngularFireDatabase,
    private _accountService: AccountService,
    private _fns: AngularFireFunctions,
    private _cdr: ChangeDetectorRef,
    private _membershipsService: MembershipsService,
    public pageService: PageService
  ) { }

  private clearSub(sub: Subscription): void {
    if (sub) {
      sub.unsubscribe();
      sub = null;
    }
  }

  public async ngOnInit(): Promise<void> {
    this._membershipsService.getAll().then(memberships => this.memberships = memberships);

    const clientId = environment.paypal.env === 'production' ? environment.paypal.client.production : environment.paypal.client.sandbox;

    try {
      const paypal = await loadScript({ 'client-id': clientId, intent: 'subscription', vault: true, 'disable-funding': 'credit,card' });

      this._paypal = paypal;
      const user = await firstValueFrom(this._authService.user);
      this._user = user;

      if (user) {
        this._accountRef = this._db.object('accounts/' + user.uid);

        const accountInfoSub = this._accountService.accountInfo.subscribe(info => {
          if (info) {
            this._accountInfo = info;
            this.isActive = info.membership ? info.membership.isActive : false;

            if (info.membership.subscriptionId) {
              const paypalObsSub = this._accountService.paypalSubscription.subscribe(paypalSub => {
                if (paypalSub) {
                  this.paypalSubscription = paypalSub;

                  const isActive = paypalSub && paypalSub.status === 'ACTIVE';
                  if (isActive) {
                    this.membershipTypeText = PaypalHelper.getMembershipNameByPlanId(paypalSub.plan_id);
                    this.activeMembershipType = PaypalHelper.getMembershipTypeByPlanId(paypalSub.plan_id);

                    if (!info.membership.planId) {
                      this._accountRef.update({ membership: { ...info.membership, planId: paypalSub.plan_id } }).then(() => {
                        console.log('Updated planId');
                      });
                    }
                  } else {
                    this.membershipTypeText = 'None';
                  }

                  this._cdr.detectChanges();

                  if (paypalSub && isActive) {
                    this.closePaypalButton();
                  } else {
                    this.initPaypalButton();
                  }

                  this.loading = false;
                  setTimeout(() => {
                    this.clearSub(paypalObsSub);
                  }, 100);
                }
              });
            } else {
              this.initPaypalButton();
              this.loading = false;
            }

            setTimeout(() => {
              this.clearSub(accountInfoSub);
            }, 100);
          }
        });
      }
    } catch (err) {
      console.error('Failed to load the PayPal JS SDK script library.', err);
    }
    // PaypalHelper.loadScript('subscription');

  }

  public ngAfterViewInit(): void {

  }

  public ngOnDestroy(): void {
    this.closePaypalButton();
  }

  private closePaypalButton(): void {
    if (this._paypalButton && this._buttonRendered) {
      this._paypalButton.close();
      this._buttonRendered = false;
    }
  }

  private initPaypalButton(): void {
    if (!this._initializingButton && this.paypalButtonElement && this.paypalButtonElement.nativeElement) {
      this._initializingButton = true;
      this.closePaypalButton();

      this._paypalButton = this._paypal.Buttons({
        createSubscription: (data, actions) => {
          this.updating = true;
          return actions.subscription.create({
            plan_id: environment.paypal.plans[this.selectedMembershipType]
          });
        },
        onApprove: (data, actions) => {
          const getSubscription = this._fns.httpsCallable('getSubscription');
          firstValueFrom(getSubscription({ subscriptionId: data.subscriptionID })).then(results => {
            this._accountService.paypalSubscription.next(results);
            this.paypalSubscription = results;

            const membership: IMembership = {
              subscriptionId: results.id,
              planId: results.plan_id,
              isActive: true,
              lastPaidDate: ''
            };

            this._accountInfo.membership = membership;
            this._accountRef.update({ membership }).then(() => {
              this.selectedMembershipType = null;
              this.activeMembershipType = PaypalHelper.getMembershipTypeByPlanId(results.plan_id);
              this.isActive = true;
              this.updating = false;
              this.closePaypalButton();
            });
          });
        }
      });

      this._paypalButton.render(this.paypalButtonElement.nativeElement).then(() => {
        this._buttonRendered = true;
        this._initializingButton = false;
      });
    }
  }

  public onClickType(type: MembershipTypes): void {
    this.selectedMembershipType = type;
  }

  public onClickSuspend(): void {
    this.updating = true;
    const suspendSubscription = this._fns.httpsCallable('suspendSubscription');
    suspendSubscription({ subscriptionId: this._accountInfo.membership.subscriptionId }).subscribe(results => {
      this._accountService.paypalSubscription.next(results);
      this.paypalSubscription = results;

      this._accountInfo.membership.isActive = false;
      this._accountRef.update({ membership: { ...this._accountInfo.membership } }).then(() => {
        this.updating = false;
      });
    });
  }

  public onClickReactivate(): void {
    this.updating = true;
    const reactivateSubscription = this._fns.httpsCallable('reactivateSubscription');
    reactivateSubscription({ subscriptionId: this._accountInfo.membership.subscriptionId }).subscribe(results => {
      this._accountService.paypalSubscription.next(results);
      this.paypalSubscription = results;

      this._accountInfo.membership.isActive = true;
      this._accountRef.update({ membership: { ...this._accountInfo.membership } }).then(() => {
        this.updating = false;
      });
    });
  }

  public onClickCancel(): void {
    this.updating = true;
    const cancelSubscription = this._fns.httpsCallable('cancelSubscription');
    cancelSubscription({ subscriptionId: this._accountInfo.membership.subscriptionId }).subscribe(results => {
      this._accountService.paypalSubscription.next(results);
      this.paypalSubscription = results;
      this.isActive = false;

      this._accountInfo.membership.subscriptionId = '';
      this._accountInfo.membership.isActive = false;
      this._accountInfo.membership.lastPaidDate = '';
      this._accountInfo.membership.planId = null;
      this._accountRef.update({ membership: { ...this._accountInfo.membership } }).then(() => {
        this.updating = false;
        this.ngOnInit();
      });
    });
  }
}
