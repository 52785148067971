import { PageService } from './../../../../../services/page.service';
import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ISpell, ISpellSlot } from '../../../../../interfaces/spell';
import { Character } from '../../../../../interfaces/character';
import { ISkill } from '../../../../../interfaces/skill';
import { MobileTooltipActionType } from '../../../../../enums/mobileTooltipActionType';

@Component({
  selector: 'app-character-card-spell-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardSpellsSpellComponent implements OnInit {
  @Input() spell: ISpell;
  @Input() spellSlot: ISpellSlot;
  @Input() parentSkill: ISkill;
  public character: Character;
  public show = false;
  public MobileTooltipActionType = MobileTooltipActionType;
  private _parent: any;

  constructor(private _cdr: ChangeDetectorRef, public pageService: PageService) { }

  ngOnInit() { }

  public updateCharacter(character: Character, parent: any): void {
    this.character = character;
    this._parent = parent;
    this.show = true;
    this._cdr.markForCheck();
  }

  public hasSpell(): boolean {
    if (!this.character) return false;
    return this.character.hasSpell(this.spellSlot.id, this.spell);
  }

  public onClickSpell(): void {
    if (this.pageService.isMobile) return;
    this.addSpell();
  }

  public addSpell(): void {
    if (!this.requirementsMet()) return;

    this.character.addSpell(this.spellSlot.id, this.spell);
    this._parent.updateSpellGroups();
    this._parent.checkPrint();
    this._parent.markForCheck();
  }

  public onRightClickSpell(e: any): void {
    e.preventDefault();

    if (this.pageService.isMobile) return;
    this.removeSpell();
  }

  public removeSpell(): void {
    if (!this.requirementsMet()) return;

    this.character.removeSpell(this.spellSlot.id, this.spell);
    this._parent.updateSpellGroups();
    this._parent.checkPrint();
    this._parent.markForCheck();
  }

  public canRemoveSpell(): boolean {
    return true;
  }

  public requirementsMet(): boolean {
    if (this.hasSpell()) return true;

    const characterSpellSlot = this.character.data.spellSlots.find(ss => ss.spellSlotId === this.spellSlot.id);
    if (characterSpellSlot.slots <= characterSpellSlot.spells.length) {
      return false;
    }

    if (this.spell.requiredProfession && this.spell.requiredProfession.length > 0) {
      let hasAnyProf = false;
      this.spell.requiredProfession.forEach(p => {
        if (this.character.hasProfessionId(p)) {
          hasAnyProf = true;
        }
      });

      if (!hasAnyProf) return false;
    }

    return true;
  }

  public markForCheck(): void {
    this._cdr.markForCheck();
  }
}
