// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  googleAnalyticsTrackingId: 'UA-120613430-2',
  useEmulators: false,
  firebase: {
    apiKey: 'AIzaSyCeg9hCFOmn6KocemadQ4xh2NqML20sYho',
    authDomain: 'sll-crux-spa-dev.firebaseapp.com',
    databaseURL: 'https://sll-crux-spa-dev.firebaseio.com',
    projectId: 'sll-crux-spa-dev',
    storageBucket: 'sll-crux-spa-dev.appspot.com',
    messagingSenderId: '621906583828',
  },
  paypal: {
    env: 'sandbox',
    client: {
      sandbox: 'ATfbPgw6B5t88vxhYuM_ZFxuEV4HG0_LGjCtDZg3yjjQe4wNOs1tIJGGvlBVZBEaoIDvc9D827ABoiV5',
      production: 'AeYWYktbiF-OtgrthHZa6Si8ahhanVmEnBmol9gKVyYku7blJcGohkS9D4Ow748y65mJLRJWmS5yNEh6'
    },
    plans: {
      silver: 'P-28R545222G355960KLZX5HWQ',
      silverPlus: 'P-67T33932TK104922HLZX5SQI',
      gold: 'P-08F56642M9849384YMOFALBQ',
      goldPlus: 'P-4PT62345X3913950TMOFALTQ',
      platinum: 'P-2D620455370216744MOFAL7A',
      platinumPlus: 'P-06D09641EY4862150MOFAMMY',
      palladium: 'P-7BK575552F081283MMOFAM7A'
    }
  }
};
