import { SpellSlotsService } from '../services/spell-slots.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spellSlotName'
})
export class SpellSlotsNamePipe implements PipeTransform {
  constructor(private _spellSlotsService: SpellSlotsService) {
  }

  transform(level: number): string {
    const spellSlot = this._spellSlotsService.allSpellSlots.find(slot => slot.level === level);
    return spellSlot.name;
  }

}
