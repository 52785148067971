import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { IProfession } from '../../../interfaces/profession';
import { ISkill } from '../../../interfaces/skill';
import { JsonService } from '../../../services/json.service';
import { SkillsService } from '../../../services/skills.service';

@Component({
  selector: 'app-profession-card',
  templateUrl: './card.component.html',
  styleUrls: ['../../slots.scss', './card.component.scss']
})
export class ProfessionCardComponent implements OnInit {
  @Input() public profession: IProfession;

  constructor(private _router: Router) {}

  ngOnInit() {}

  public onClickProfession(profession: IProfession): void {
    this._router.navigate(['professions', profession.id]);
  }
}
