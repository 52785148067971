import { ProfessionsService } from './../services/professions.service';
import { Pipe, PipeTransform } from '@angular/core';
import { IProfession } from '../interfaces/profession';

@Pipe({
  name: 'professions'
})
export class ProfessionsPipe implements PipeTransform {
  constructor(private _professionsService: ProfessionsService) {
  }

  transform(professions: string[]): Promise<IProfession[]> {
    return new Promise<IProfession[]>((resolve, reject) => {
      this._professionsService.getAll().then(profs => {
        resolve(profs.filter(profession => professions.includes(profession.id)));
      }, err => reject(err));
    });
  }

}
