import { Pipe, PipeTransform } from '@angular/core';
import { ISkill } from '../interfaces/skill';
import { ICharacterSkill, ICharacterSkillView } from '../interfaces/character';

@Pipe({
  name: 'skillView',
  pure: false
})
export class SkillViewPipe implements PipeTransform {
  transform(skills: ISkill[], characterSkills: ICharacterSkill[]): any {
    const skillViews: ICharacterSkillView[] = [];
    skills.forEach(skill => {
      const characterSkill = characterSkills.find(cs => cs.skillId === skill.id);
      let count = 0;

      if (characterSkill) {
        count = characterSkill.count;
      }

      skillViews.push({ skill: skill, count: count });
    });

    return skillViews;
  }
}
