import { JsonService } from './json.service';
import { Injectable } from '@angular/core';
import { IAttribute } from '../interfaces/attribute';

@Injectable()
export class AttributesService {
  private _allAttributes: IAttribute[] = [];

  public get allAttributes(): IAttribute[] {
    return this._allAttributes;
  }

  constructor(private _jsonService: JsonService) {
    if (this._allAttributes.length === 0) {
      this._jsonService.get('attributes').then(attributes => {
        this._allAttributes = attributes;
      });
    }
  }
}
