import { Character } from './../../interfaces/character';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CharacterService } from '../../services/character.service';

@Component({
  selector: 'app-new-character-dialog',
  templateUrl: './new-character-dialog.component.html',
  styleUrls: ['../section.scss', '../tiles.scss', './new-character-dialog.component.scss']
})
export class NewCharacterDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('name', { static: false }) nameElementRef: ElementRef;

  public characterName: string;
  public isCreateDisabled = true;
  public isNameEmpty = false;
  public isNameUsed = false;

  private _allCharacters: Character[];

  constructor(public dialogRef: MatDialogRef<NewCharacterDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this._allCharacters = data.characters;
  }

  public ngOnInit(): void { }

  public ngAfterViewInit(): void {
    this.nameElementRef.nativeElement.focus();
  }

  public onKeyupName(): void {
    this.isNameEmpty = this.characterName.trim().length === 0;

    this.isNameUsed = false;
    for (let i = 0; i < this._allCharacters.length; ++i) {
      const character = this._allCharacters[i];
      if (character.data.name.toLowerCase() === this.characterName.toLowerCase()) {
        this.isNameUsed = true;
        break;
      }
    }

    if (this.isNameUsed) {
      this.dialogRef.updateSize('350px', '184px');
    } else {
      this.dialogRef.updateSize('350px', '156px');
    }

    this.isCreateDisabled = this.isNameEmpty || this.isNameUsed;
  }

  public onClickCancel(): void {
    this.dialogRef.close();
  }

  public onClickCreate(): void {
    if (this.isNameEmpty || this.isNameUsed) return;

    this.dialogRef.close(this.characterName);
  }
}
