import { AccountService } from './account.service';
import { PageService } from './page.service';
import { Injectable, OnInit } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth';

import { Observable } from 'rxjs';
import { IAccountInfo } from '../interfaces/accountInfo';
import FirebaseAuth from 'firebase/auth';

@Injectable()
export class AuthService implements OnInit {
  user: Observable<FirebaseAuth.User>;

  constructor(private firebaseAuth: AngularFireAuth, private pageService: PageService, private _accountService: AccountService) {
    this.user = firebaseAuth.authState;
  }

  public ngOnInit(): void { }

  public isAuthenticated(): boolean {
    if (!this.user) return false;

    return true;
  }

  // public signup(email: string, password: string): void {
  //   this.firebaseAuth.auth
  //     .createUserWithEmailAndPassword(email, password)
  //     .then(value => {
  //       console.log('Success!', value);
  //     })
  //     .catch(err => {
  //       console.log('Something went wrong:', err.message);
  //     });
  // }

  // public login(): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this.firebaseAuth.auth
  //       .setPersistence(firebase.auth.Auth.Persistence.SESSION)
  //       .then(() => {
  //         if (this.pageService.isMobile || this.isIE()) {
  //           return this.firebaseAuth.auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider());
  //         } else {
  //           return this.firebaseAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(results => {
  //             resolve(results);
  //           });
  //         }
  //       })
  //       .catch(error => {
  //         // Handle Errors here.
  //         console.error(error);
  //       });
  //   });
  // }

  public logout(): void {
    this.firebaseAuth.signOut().then(() => {
      this._accountService.accountInfo.next(null);
      this.pageService.allowAdminEdit = false;
    });
  }

  public isIE() {
    const ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // tslint:disable-next-line:max-line-length
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
  }
}
