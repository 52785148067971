import { AngularFireDatabase } from '@angular/fire/compat/database';
import { IXpLogEntry } from './../../interfaces/character';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';

@Component({
  selector: 'app-xp-award-dialog',
  templateUrl: './xp-award-dialog.component.html',
  styleUrls: ['../section.scss', '../tiles.scss', './xp-award-dialog.component.scss']
})
export class XpAwardDialogComponent implements OnInit {
  @ViewChild('xp', { static: false }) xpElementRef: ElementRef;

  public entry: IXpLogEntry;
  public xpMask = {
    mask: [/[0-9]/, /\d/, /\d/, /\d/, /\d/],
    guide: false,
    keepCharPositions: true
  };

  constructor(public dialogRef: MatDialogRef<XpAwardDialogComponent>, private _db: AngularFireDatabase) {
    this.entry = {
      xpValue: null,
      date: moment.utc().toISOString(),
      eventId: null,
      reason: ''
    };
  }

  public ngOnInit(): void {
  }

  public onClickCancel(): void {
    this.dialogRef.close();
  }

  public onClickConfirm(): void {
    this.dialogRef.close(this.entry);
  }
}
