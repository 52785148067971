import { environment } from '../../environments/environment';
import { MembershipTypes } from '../enums/membershipTypes';

declare let paypal: any;

export class PaypalHelper {
  public static loadScript(intent: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      if (!environment.paypal.client) resolve();

      // Don't load this more than once.
      const paypalScript = document.getElementById('paypal-script');
      if (paypalScript) {
        resolve();
      } else {
        const clientId = environment.paypal.env === 'production' ? environment.paypal.client.production : environment.paypal.client.sandbox;
        const scriptElement = document.createElement('script');
        scriptElement.id = 'paypal-script';
        scriptElement.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&intent=${intent}&vault=true&disable-funding=credit,card`;
        scriptElement.onload = () => {
          resolve();
        };
        document.body.appendChild(scriptElement);
      }
    });
  }

  public static getMembershipNameByPlanId(planId: string): string {
    let text = 'None';

    if (planId === environment.paypal.plans.silver) {
      text = 'Silver';
    } else if (planId === environment.paypal.plans.silverPlus) {
      text = 'Silver+';
    } else if (planId === environment.paypal.plans.gold) {
      text = 'Gold';
    } else if (planId === environment.paypal.plans.goldPlus) {
      text = 'Gold+';
    } else if (planId === environment.paypal.plans.platinum) {
      text = 'Platinum';
    } else if (planId === environment.paypal.plans.platinumPlus) {
      text = 'Platinum+';
    } else if (planId === environment.paypal.plans.palladium) {
      text = 'Palladium';
    }

    return text;
  }

  public static getMembershipTypeByPlanId(planId: string): MembershipTypes | null {
    let type = null;

    if (planId === environment.paypal.plans.silver) {
      type = MembershipTypes.SILVER;
    } else if (planId === environment.paypal.plans.silverPlus) {
      type = MembershipTypes.SILVER_PLUS;
    } else if (planId === environment.paypal.plans.gold) {
      type = MembershipTypes.GOLD;
    } else if (planId === environment.paypal.plans.goldPlus) {
      type = MembershipTypes.GOLD_PLUS;
    } else if (planId === environment.paypal.plans.platinum) {
      type = MembershipTypes.PLATINUM;
    } else if (planId === environment.paypal.plans.platinumPlus) {
      type = MembershipTypes.PLATINUM_PLUS;
    } else if (planId === environment.paypal.plans.palladium) {
      type = MembershipTypes.PALLADIUM;
    }

    return type;
  }
}
