export class IChat {
  channels: IChatChannel[];
  messages: IChannelMessages[];
}

export class IChatChannel {
  name: string;
  owner: string;
  moderators: string[];
  users: string[];
  private: boolean;
}

export class IChannelMessages {
  entries: IChatMessage[];
}

export class IChatMessage {
  key: string;
  userId: string;
  text: string;
  timestamp: number;
}

export interface IChatPlayerInfo {
  uid: string;
  displayName: string;
  avatarUrl: string;
  channels: string[];
  invites: any[];
}

export interface IInvitePlayerData {
  uid: string;
  displayName: string;
  avatarUrl: string;
  channels: string[];
  invited: boolean;
  isOwner: boolean;
  isModerator: boolean;
  isMember: boolean;
}

export interface IPlayerInvite {
  expirationDate: number;
}

export interface IAccountChatTimestampData {
  lastViewedDate: number;
}
