import { PageService } from './../../../services/page.service';
import { Subscription } from 'rxjs';
import { DeleteDialogComponent } from './../../delete-dialog/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PlayersService } from './../../../services/players.service';
import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ICommentEntry } from '../../../interfaces/comment';
import { IProfile } from '../../../interfaces/accountInfo';
import * as marked from 'marked';

@Component({
  selector: 'app-comment-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class CommentEntryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() entry: ICommentEntry;
  @Output() delete: EventEmitter<ICommentEntry> = new EventEmitter();

  public profile: IProfile;
  public markdown: string;

  private _dialogCloseSub: Subscription;

  constructor(private _playersService: PlayersService, private _dialog: MatDialog, public pageService: PageService) { }

  ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['entry'] && changes['entry'].currentValue) {
      this._playersService.getProfile(this.entry.accountId).then(profile => {
        this.profile = profile;
      });

      this.markdown = marked(this.entry.text);
    }
  }

  public ngOnDestroy(): void {
    if (this._dialogCloseSub) this._dialogCloseSub.unsubscribe();
  }

  public onClickDelete(): void {
    const dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '300px',
      height: '200px',
      data: {
        title: 'Delete comment?',
        text: 'Are you sure you wish to delete this comment? You cannot undo this.'
      },
      panelClass: 'crux-dialog'
    });

    this._dialogCloseSub = dialogRef.afterClosed().subscribe(result => {
      // Yes, we have confirmed a delete request
      if (result) {
        this.delete.emit(this.entry);
      }
    });
  }
}
