import { JsonService } from '../../../../services/json.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  SimpleChanges,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewChildren,
  QueryList,
  AfterViewInit
} from '@angular/core';
import { IOrigin } from '../../../../interfaces/origin';
import { ISkill } from '../../../../interfaces/skill';
import { IAttribute } from '../../../../interfaces/attribute';
import { Character } from '../../../../interfaces/character';
import { CharacterCardSkillSlotComponent } from '../skills/slot/slot.component';
import { OriginsService } from '../../../../services/origins.service';
import { SkillsService } from '../../../../services/skills.service';
import { AttributesService } from '../../../../services/attributes.service';
import { PageService } from '../../../../services/page.service';
import { CharacterCardComponent } from '../card.component';

@Component({
  selector: 'app-character-card-origin',
  templateUrl: './origin.component.html',
  styleUrls: ['../../../section.scss', './origin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardOriginComponent implements OnInit, AfterViewInit {
  public character: Character;
  public origin: IOrigin;
  public allOrigins: IOrigin[] = [];
  public allAttributes: IAttribute[] = [];
  public racialSkills: ISkill[] = [];
  private _parent: CharacterCardComponent;

  @ViewChildren(CharacterCardSkillSlotComponent) skillSlots: QueryList<CharacterCardSkillSlotComponent>;

  constructor(
    private _originsService: OriginsService,
    private _skillService: SkillsService,
    private _attributesService: AttributesService,
    private _cdr: ChangeDetectorRef,
    public pageService: PageService
  ) { }

  public ngOnInit(): void {
    this._originsService.getAll().then(origins => (this.allOrigins = origins));
    this.allAttributes = this._attributesService.allAttributes;
  }

  public ngAfterViewInit(): void {
  }

  public onClickPreviousOrigin(): void {
    let index = this.allOrigins.findIndex(origin => origin.id === this.character.data.originId);
    if (--index < 0) {
      index = this.allOrigins.length - 1;
    }

    this.character.data.originId = this.allOrigins[index].id;
    this.updateOrigin();
    this._parent.checkDetails();
    this._parent.checkPrint();
    this.character.markDirty();
  }

  public onClickNextOrigin(): void {
    let index = this.allOrigins.findIndex(origin => origin.id === this.character.data.originId);
    if (++index === this.allOrigins.length) {
      index = 0;
    }

    this.character.data.originId = this.allOrigins[index].id;
    this.updateOrigin();
    this._parent.checkDetails();
    this._parent.checkPrint();
    this.character.markDirty();
  }

  public onClickTogglePortrait(): void {
    const pathList = this.character.data.portrait.split('/');
    const portraitName = pathList[pathList.length - 1];
    let newIndex = this.origin.portraits.findIndex(p => p === portraitName) + 1;
    if (newIndex >= this.origin.portraits.length) {
      newIndex = 0;
    }

    this.character.data.portrait = 'data/origins/' + this.origin.portraits[newIndex];
    this.character.markDirty();
  }

  public onClickAttributeBonus(attribute: IAttribute): void {
    this.character.data.racialBonusAttributes = [attribute.id];
    this._parent.checkDetails();
    this.character.markDirty();
  }

  public isAttributeSelected(type: string): boolean {
    return this.character.data.racialBonusAttributes.includes(type);
  }

  public hasSkill(skill: ISkill): boolean {
    return this.character.hasSkill(skill);
  }

  public updateCharacter(character: Character, parent: CharacterCardComponent): void {
    this.character = character;
    this._parent = parent;
    this.updateOrigin();
  }

  public updateOrigin(onlySetOrigin: boolean = false): void {
    // Remove the current Origin skills from the character
    if (!onlySetOrigin && this.origin) {
      this.origin.skills.forEach(skillId => {
        const skill = this.racialSkills.find(s => s.id === skillId);
        if (skill) {
          this.character.sellSkill(skill);
        }
      });
    }

    // Set the new origin
    this.origin = this.allOrigins.find(r => r.id === this.character.data.originId);
    this.character.setOrigin(this.origin);

    // Updated the default bonus attribute when selecting a new origin
    if (!onlySetOrigin && this.origin.attributeModifiers.advantages.length > 0) {
      this.character.data.racialBonusAttributes = [this.origin.attributeModifiers.advantages[0].type[0]];
    }

    this._skillService.getAll().then(allSkills => {
      this.racialSkills = [];
      // Add any zero cost skills to the character
      this.origin.skills.forEach(skillId => {
        const skill = allSkills.find(s => s.id === skillId);
        this.racialSkills.push(skill);
        if (skill && skill.cost === 0) {
          this.character.purchaseSkill(skill);
        }
      });

      // Set the default portrait
      if (!onlySetOrigin) {
        this.character.data.portrait = 'data/origins/' + this.origin.portraits[0];
      }

      this._cdr.detectChanges();

      // Now that we have skills we should update the skills section.
      const slotsArray = this.skillSlots.toArray();
      for (let i = 0; i < slotsArray.length; ++i) {
        slotsArray[i].updateCharacter(this.character, this);
      }
    });
  }

  public markForCheck(): void {
    this._cdr.markForCheck();

    this.skillSlots.forEach(slot => {
      slot.markForCheck();
    });
  }

  public handleSkillClicked(): void {
    this.checkDetails();
  }

  public handleSkillRightClicked(): void {
    this.checkDetails();
  }

  public checkDetails(): void {
    this._parent.checkDetails();
  }

  public checkProfessions(): void {
    this._parent.checkProfessions();
  }

  public checkPrint(): void {
    this._parent.checkPrint();
  }
}
