import { Component, OnInit, AfterViewInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { SpellsService } from '../../../../services/spells.service';
import { ISpell } from '../../../../interfaces/spell';
import { Character } from '../../../../interfaces/character';
import { CharacterCardSpellsGroupComponent } from '../spells/group/group.component';
import { PageService } from '../../../../services/page.service';
import { CharacterCardComponent } from '../card.component';

@Component({
  selector: 'app-character-card-spells',
  templateUrl: './spells.component.html',
  styleUrls: ['../../../section.scss', './spells.component.scss']
})
export class CharacterCardSpellsComponent implements OnInit, AfterViewInit {
  public character: Character;
  public activeSpellGroup = 1;

  @ViewChildren(CharacterCardSpellsGroupComponent) spellGroups: QueryList<CharacterCardSpellsGroupComponent>;

  private _parent: CharacterCardComponent;

  public tabs = [
    { level: 1, tooltip: 'Class One Spells', imageUrl: '../../../../../assets/images/book.png' },
    { level: 2, tooltip: 'Class Two Spells', imageUrl: '../../../../../assets/images/book.png' },
    { level: 3, tooltip: 'Class Three Spells', imageUrl: '../../../../../assets/images/book.png' },
    { level: 4, tooltip: 'Class Four Spells', imageUrl: '../../../../../assets/images/book.png' },
    { level: 5, tooltip: 'Class Five Spells', imageUrl: '../../../../../assets/images/book.png' },
    { level: 6, tooltip: 'Class Six Spells', imageUrl: '../../../../../assets/images/book.png' }
  ];

  constructor(
    private _spellsService: SpellsService,
    public pageService: PageService) { }

  ngOnInit() { }

  public ngAfterViewInit(): void {

  }

  public updateCharacter(character: Character, parent: CharacterCardComponent): void {
    this.character = character;
    this._parent = parent;

    this.spellGroups.forEach(group => {
      group.updateCharacter(this.character, this);
    });
  }

  public onClickSpellGroupHeader(level: number): void {
    this.activeSpellGroup = level;
    this.markForCheck();
  }

  public markForCheck(): void {
    this.spellGroups.forEach(group => {
      group.markForCheck();
    });
  }

  public checkSpellSlots(): void {
    this._parent.checkDetails();
  }

  public checkProfessions(): void {
    this._parent.checkProfessions();
  }

  public checkPrint(): void {
    this._parent.checkPrint();
  }

  public getLevelText(level: number): string {
    switch (level) {
      case 1: return 'I';
      case 2: return 'II';
      case 3: return 'III';
      case 4: return 'IV';
      case 5: return 'V';
      case 6: return 'VI';
    }

    return '';
  }
}
