import { FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';
import { AccountService } from './../../services/account.service';
import { IAccountInfo } from './../../interfaces/accountInfo';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['../tiles.scss', './auth-dialog.component.scss']
})
export class AuthDialogComponent {
  private _accountRef: AngularFireObject<IAccountInfo>;

  constructor(
    public dialogRef: MatDialogRef<AuthDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _gas: GoogleAnalyticsService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public successCallback(data: FirebaseUISignInSuccessWithAuthResult): void {
    this._gas.event('Account', 'Login', `${data.authResult.user.email} [${data.authResult.user.uid}]`);

    this.dialogRef.close();
  }
}
