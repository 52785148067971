import { IOrigin } from './../../../../interfaces/origin';
import { MobileTooltipActionType } from './../../../../enums/mobileTooltipActionType';
import { IAttribute } from './../../../../interfaces/attribute';
import { Component, OnInit } from '@angular/core';
import { Character } from '../../../../interfaces/character';
import { AttributesService } from '../../../../services/attributes.service';
import { AttributeHelper } from '../../../../helpers/attribute.helper';
import { OriginsService } from '../../../../services/origins.service';
import { PageService } from '../../../../services/page.service';
import { CharacterCardComponent } from '../card.component';

@Component({
  selector: 'app-character-card-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['../../../section.scss', './attributes.component.scss']
})
export class CharacterCardAttributesComponent implements OnInit {
  public character: Character;
  public allAttributes: IAttribute[] = [];
  public pips: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  public pipCost: number[] = [];

  private _parent: CharacterCardComponent;
  private currentOrigin: IOrigin;

  public MobileTooltipActionType = MobileTooltipActionType;

  constructor(private _attributesService: AttributesService, private _originsService: OriginsService, public pageService: PageService) {
    for (let i = 0; i < this.pips.length; ++i) {
      this.pipCost.push(AttributeHelper.calculateCost(i));
    }
  }

  ngOnInit() {
    this.allAttributes = this._attributesService.allAttributes;
  }

  public updateCharacter(character: Character, parent: CharacterCardComponent): void {
    this.character = character;
    this._parent = parent;

    this._originsService.get(this.character.data.originId).then(origin => (this.currentOrigin = origin));

    this.updateAttributes();
  }

  private updateAttributes(): void { }

  public onClickAttribute(attribute: IAttribute): void {
    if (this.pageService.isMobile) return;
    this.addAttribute(attribute);
  }

  public addAttribute(attribute: IAttribute): void {
    const nextValue = this.character.data[attribute.id] + 1;
    const bonus = this.character.getAttributeBonuses(attribute.id);
    const nextValueWithBonus = nextValue + bonus;

    if (this.canAffordPip(nextValueWithBonus, attribute) || nextValue < this.character.data[attribute.id]) {
      this.character.data[attribute.id] = nextValue;
      this.character.calculateRemainingAp();
      this.character.markDirty();
      this._parent.checkDetails();
      this._parent.checkPrint();
    }
  }

  public onRightClickAttribute(attribute: IAttribute): void {
    if (this.pageService.isMobile) return;
    this.removeAttribute(attribute);
  }

  public removeAttribute(attribute: IAttribute): void {
    const prevValue = this.character.data[attribute.id] - 1;
    if (prevValue < 1) {
      return;
    }

    this.character.data[attribute.id] = prevValue;
    this.character.calculateRemainingAp();
    this.character.markDirty();
    this._parent.checkDetails();
    this._parent.checkPrint();
  }

  public onClickPip(index: number, attribute: IAttribute): void {
    const bonus = this.character.getAttributeBonuses(attribute.id);
    if (index <= bonus) return;
    index -= bonus;

    if (this.canAffordPip(index, attribute) || index < this.character.data[attribute.id]) {
      this.character.data[attribute.id] = index;
      this.character.calculateRemainingAp();
      this.character.markDirty();
      this._parent.checkDetails();
      this._parent.checkPrint();
    }
  }

  public isPipActive(index: number, attribute: IAttribute): boolean {
    if (!this.character) {
      return false;
    }

    index -= this.character.getAttributeBonuses(attribute.id);
    return index <= this.character.data[attribute.id];
  }

  public canAffordPip(index: number, attribute: IAttribute): boolean {
    if (!this.character) {
      return false;
    }

    const bonus = this.character.getAttributeBonuses(attribute.id);
    index -= bonus;

    // Check for any origin disadvantages to make sure we can't go above a certain value
    if (
      this.currentOrigin &&
      this.currentOrigin.attributeModifiers &&
      this.currentOrigin.attributeModifiers.disadvantages &&
      this.currentOrigin.attributeModifiers.disadvantages.length > 0
    ) {
      const attributeDisadvantage = this.currentOrigin.attributeModifiers.disadvantages.find(d => d.type.includes(attribute.id));
      if (attributeDisadvantage && attributeDisadvantage.value < index) {
        return false;
      }
    }

    return this.pipCost[index] - this.pipCost[this.character.data[attribute.id]] <= this.character.apLeft;
  }

  public isBonusPip(index: number, attribute: IAttribute): boolean {
    if (!this.character) {
      return false;
    }

    const bonus = this.character.getAttributeBonuses(attribute.id);

    return index <= bonus;
  }

  public isLastActivePip(index: number, attribute: IAttribute): boolean {
    if (!this.character) {
      return false;
    }

    index -= this.character.getAttributeBonuses(attribute.id);

    return index === this.character.data[attribute.id];
  }

  public checkAttributes(): void {
    if (this.currentOrigin.id !== this.character.data.originId) {
      this._originsService.get(this.character.data.originId).then(origin => (this.currentOrigin = origin));
    }
  }
}
