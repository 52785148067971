import { ChapterService, MAIN_CHAPTER_GUID } from './../../services/chapter.service';
import { AccountService } from './../../services/account.service';
import { MobileNavService } from './../../services/mobile-nav.service';
import { DropMenuType } from './../../enums/dropMenuType';
import { ICharacter } from './../../interfaces/character';
import { PayDialogComponent } from './../pay-dialog/pay-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  EventRegisterDialogComponent,
  IEventRegisterData,
  IEventRegisterDataResult
} from './../events/register/register.component';
import { AuthService } from './../../services/auth.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { IEvent, IEventPlayer } from './../../interfaces/event';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as marked from 'marked';
import * as moment from 'moment';
import { MarkdownService } from '../../services/markdown.service';
import { PageService } from '../../services/page.service';
import { EventUnregisterDialogComponent } from '../events/unregister/unregister.component';
import { AuthDialogComponent } from '../auth-dialog/auth-dialog.component';
import { DropMenuService } from '../../services/drop-menu.service';
import { HomeTileType } from '../../enums/homeTileType';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: [
    '../toolbar.scss',
    '../page.scss',
    '../tiles.scss',
    '../button.scss',
    '../sheet.scss',
    './home.component.scss'
  ]
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  public introText = '';
  public gettingStartedText = '';
  public showRegister = false;
  public showUnregister = false;
  public showSignin = true;

  public nextEvent: IEvent;
  public nextEventPlayer: IEventPlayer;
  public nextEventPlayerCount = 0;
  public nextEventCommentsCount = 0;
  public nextEventAllowRegistration = false;
  public nextEventTimeBeforeStart = '';
  public nextEventCost = 0;
  public nextEventCharacter: ICharacter;
  public nextEventDescription: string;

  public prevEvent: IEvent;
  public prevEventPlayer: IEventPlayer;
  public prevEventPlayerCount = 0;
  public prevEventCommentsCount = 0;
  public prevEventTimeBeforeStart = '';
  public prevEventDescription: string;

  public HomeTileType = HomeTileType;
  public MAIN_CHAPTER_GUID = MAIN_CHAPTER_GUID;

  private _uid: string;
  private _urlSub: Subscription;
  private _nextEventSub: Subscription;
  private _prevEventSub: Subscription;
  private _accountSub: Subscription;
  private _dialogSub: Subscription;
  private _charactersSub: Subscription;
  private _accountInfoSub: Subscription;
  private _nextEventPlayerSub: Subscription;
  private _prevEventPlayerSub: Subscription;
  private _characters: ICharacter[];

  constructor(
    private _markdownService: MarkdownService,
    private _activatedRoute: ActivatedRoute,
    private _db: AngularFireDatabase,
    public pageService: PageService,
    private _authService: AuthService,
    private _dialog: MatDialog,
    private _router: Router,
    private _dropMenuService: DropMenuService,
    private _mobileNavService: MobileNavService,
    private _accountService: AccountService,
    private _chaptersService: ChapterService
  ) {
    this._urlSub = this._activatedRoute.url
      .pipe(map(segments => segments.join('')))
      .subscribe(url => {
        this.pageService.setTarget(url);
      });
  }

  ngOnInit() {
    this._markdownService.getFromAssets('home').then(results => {
      this.introText = marked(results);
    });

    this._accountSub = this._authService.user.subscribe(user => {
      if (user) {
        this.showSignin = false;
        this._uid = user.uid;

        this._charactersSub = this._db
          .list<ICharacter>('characters/' + user.uid)
          .valueChanges()
          .pipe(take(1))
          .subscribe(chars => {
            this._characters = chars;
            this.showRegister = chars.length > 0;

            this.checkNextEventData();
            this.checkPrevEventData();
          });
      } else {
        this.showRegister = false;
      }
    });
  }

  public ngAfterViewInit(): void {
    this._nextEventSub = this._db
      .list<IEvent>('events', ref =>
        ref.orderByChild('endDate').startAt(moment.utc().toISOString())
      )
      .valueChanges()
      .pipe(take(1))
      .subscribe(results => {
        this.nextEvent = results[0];
        if (this.nextEvent) {

          if (this.nextEvent.players) {
            const activeEventPlayers = (Object.values(
              this.nextEvent.players
            ) as IEventPlayer[]).filter(
              (p: IEventPlayer) => p.status === 'Active'
            );
            this.nextEventPlayerCount = activeEventPlayers.length;
          }

          this.checkNextEventData();
          this.checkNextEventComments();

          const startDate = moment(this.nextEvent.startDate);
          const prefix = startDate.isAfter(moment()) ? 'Starts ' : 'Started ';
          this.nextEventTimeBeforeStart = prefix + startDate.fromNow();
        }
      });

    this._prevEventSub = this._db
      .list<IEvent>('events', ref =>
        ref.orderByChild('endDate').endAt(moment.utc().toISOString())
      )
      .valueChanges()
      .pipe(take(1))
      .subscribe(results => {
        this.prevEvent = results[results.length - 1];
        const activeEventPlayers = (Object.values(
          this.prevEvent.players
        ) as IEventPlayer[]).filter((p: IEventPlayer) => p.status === 'Active');
        this.prevEventPlayerCount = activeEventPlayers.length;
        this.checkPrevEventData();
        this.checkPrevEventComments();

        const endDate = moment(this.prevEvent.endDate);
        this.prevEventTimeBeforeStart = 'Ended ' + endDate.fromNow();
      });
  }

  private checkNextEventData(): void {
    if (!this.nextEvent) return;

    this.nextEventDescription = this.nextEvent.description;

    if (!this._uid) return;
    if (!this.nextEvent.players) this.nextEvent.players = [];

    const now = moment.utc();
    const registrationStartMoment = this.nextEvent.registrationStartDate
      ? moment.utc(this.nextEvent.registrationStartDate)
      : moment.utc(this.nextEvent.startDate).subtract(2, 'week');
    this.nextEventAllowRegistration = now.isSameOrAfter(
      registrationStartMoment
    );

    this._nextEventPlayerSub = this._db
      .object('events/' + this.nextEvent.id + '/players/' + this._uid)
      .valueChanges()
      .pipe(take(1))
      .subscribe((value: IEventPlayer) => {
        if (value) {
          this.nextEventPlayer = value;
          this.showUnregister = true;

          if (value.status !== 'Cancelled' && this._characters) {
            const character = this._characters.find(c => c.id === value.characterId);
            if (character) {
              this.nextEventCharacter = character;

              const pcCost = this.nextEvent.cost * this.nextEvent.length;
              const npcCost = this.nextEvent.npcCost * this.nextEvent.length;

              this.nextEventCost = !character.type || character.type === 'pc' ? pcCost : npcCost;

              this._accountInfoSub = this._accountService.paypalSubscription.subscribe(paypalSub => {
                if (paypalSub) {
                  const discount = (paypalSub.state !== 'Active' ? 0 : this.nextEvent.memberDiscount) * this.nextEvent.length;
                  this.nextEventCost -= discount;
                }
              });
            }
          }
        }
      });
  }

  private checkNextEventComments(): void {
    if (!this.nextEvent) return;

    this._db
      .list('comments/' + this.nextEvent.id + '/entries')
      .valueChanges()
      .pipe(take(1))
      .subscribe(entries => {
        this.nextEventCommentsCount = entries.length;
      });
  }

  private checkPrevEventData(): void {
    if (!this.prevEvent) return;

    this.prevEventDescription = this.prevEvent.description;

    if (!this._uid) return;
    if (!this.prevEvent.players) this.prevEvent.players = [];

    this._prevEventPlayerSub = this._db
      .object('events/' + this.prevEvent.id + '/players/' + this._uid)
      .valueChanges()
      .pipe(take(1))
      .subscribe((value: IEventPlayer) => {
        this.prevEventPlayer = value;
      });
  }

  private checkPrevEventComments(): void {
    if (!this.prevEvent) return;

    this._db
      .list('comments/' + this.prevEvent.id + '/entries')
      .valueChanges()
      .pipe(take(1))
      .subscribe(entries => {
        this.prevEventCommentsCount = entries.length;
      });
  }

  public ngOnDestroy(): void {
    if (this._urlSub) this._urlSub.unsubscribe();
    if (this._nextEventSub) this._nextEventSub.unsubscribe();
    if (this._prevEventSub) this._prevEventSub.unsubscribe();
    if (this._accountSub) this._accountSub.unsubscribe();
    if (this._dialogSub) this._dialogSub.unsubscribe();
    if (this._charactersSub) this._charactersSub.unsubscribe();
    if (this._accountInfoSub) this._accountInfoSub.unsubscribe();
    if (this._nextEventPlayerSub) this._nextEventPlayerSub.unsubscribe();
    if (this._prevEventPlayerSub) this._prevEventPlayerSub.unsubscribe();
  }

  public onClickRegister(e: any): void {
    const data: IEventRegisterData = {
      uid: this._uid,
      event: this.nextEvent,
      eventPlayer: this.nextEventPlayer
    };

    const dialogRef = this._dialog.open(EventRegisterDialogComponent, {
      width: '300px',
      height: '500px',
      data: data,
      panelClass: 'crux-dialog'
    });

    this._dialogSub = dialogRef
      .afterClosed()
      .subscribe((result: IEventRegisterDataResult) => {
        if (result) {
          this.nextEvent = result.event;
          this.nextEventPlayer = result.eventPlayer;

          const character = this._characters.find(c => c.id === result.eventPlayer.characterId);
          if (character) {
            this.nextEventCharacter = character;
            this.nextEventCost = (!character.type || character.type === 'pc' ? this.nextEvent.cost : this.nextEvent.npcCost) * this.nextEvent.length;
          }
        }
      });
  }

  public onClickUnregister(e: any): void {
    const data: IEventRegisterData = {
      uid: this._uid,
      event: this.nextEvent,
      eventPlayer: this.nextEventPlayer
    };

    const dialogRef = this._dialog.open(EventUnregisterDialogComponent, {
      width: '300px',
      height: '200px',
      data: data,
      panelClass: 'crux-dialog'
    });

    this._dialogSub = dialogRef
      .afterClosed()
      .subscribe((result: IEventRegisterDataResult) => {
        if (result) {
          this.nextEvent = result.event;
          this.nextEventPlayer = result.eventPlayer;

          if (result.eventPlayer.status === 'Cancelled') {
            this.nextEventCharacter = null;
            this.nextEventCost = 0;
          }
        }
      });
  }

  public onClickPay(e: any): void {
    const dialogRef = this._dialog.open(PayDialogComponent, {
      width: '300px',
      height: '200px',
      data: { event: this.nextEvent, eventPlayer: this.nextEventPlayer },
      panelClass: 'crux-dialog'
    });

    this._dialogSub = dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.nextEventPlayer = result;
      }
    });
  }

  public onClickNextEvent(): void {
    this._router.navigate(['events', this.nextEvent.id]);
  }

  public onClickPrevEvent(): void {
    this._router.navigate(['events', this.prevEvent.id]);
  }

  public onClickOrigins(): void {
    this._router.navigate(['origins']);
  }

  public onClickHandbook(): void {
    this._router.navigate(['handbook']);
  }

  public onClickSignIn(): void {
    const dialogRef = this._dialog.open(AuthDialogComponent, {
      width: '300px',
      height: '400px',
      data: null,
      panelClass: 'crux-dialog'
    });
  }

  public onClickChatSystem(): void {
    if (!this.pageService.isMobile) {
      this._dropMenuService.changeType(DropMenuType.Chat);
    } else {
      this._mobileNavService.toggleChat(true);
    }
  }

  public onClickCharacter(): void {
    if (!this.pageService.isMobile) {
      this._dropMenuService.changeType(DropMenuType.Characters);
    } else {
      this._mobileNavService.toggleChar(true);
    }
  }
}
