import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['../tiles.scss', './delete-dialog.component.scss']
})
export class DeleteDialogComponent {
  public model: IDeleteDialogModel;

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.model = data;
  }

  public onClickNo(): void {
    this.dialogRef.close();
  }

  public onClickYes(): void {
    this.dialogRef.close(true);
  }
}

export interface IDeleteDialogModel {
  title: string;
  text: string;
}
