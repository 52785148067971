import { PageService } from './../../../services/page.service';
import { MarkdownService } from './../../../services/markdown.service';
import { JsonService } from './../../../services/json.service';
import { Component, OnInit, AfterContentInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IProfession } from '../../../interfaces/profession';
import * as marked from 'marked';
import { ProfessionsService } from '../../../services/professions.service';
import { IOutlineItem } from '../../../interfaces/outline';
import { OutlineHelper } from '../../../helpers/outline.helper';

@Component({
  selector: 'app-profession-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['../../tiles.scss', '../../toolbar.scss', '../../page.scss', './detail.component.scss']
})
export class ProfessionDetailComponent implements OnInit, AfterContentInit {
  public renderedMarkdown: string;
  public profession: IProfession;
  public outlineItems: IOutlineItem[] = [];

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _jsonService: JsonService,
    private _markdownService: MarkdownService,
    private _professionsService: ProfessionsService,
    public pageService: PageService
  ) { }

  ngOnInit() { }

  public ngAfterContentInit(): void {
    this._activatedRoute.params.subscribe(params => {
      const id = params['id'] as string;
      this._professionsService.get(id).then(profession => (this.profession = profession));

      this._markdownService.getFromData('professions/' + id).then(results => {
        const renderer = new marked.Renderer();
        renderer.heading = (text: string, level: number, raw: string) => OutlineHelper.createHeaders(text, level, raw, this.outlineItems);

        marked(results, { renderer: renderer, gfm: true }, (err, markdown) => {
          if (err) { console.error(err); }
          this.renderedMarkdown = markdown;
        });
      });
    });
  }

  public toggleOutline(outline: IOutlineItem): void {
    outline.open = !outline.open;
  }
}
