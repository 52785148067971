import { Pipe, PipeTransform } from '@angular/core';
import { ChatService } from '../services/chat.service';
import { IChatChannel } from '../interfaces/chat';

@Pipe({
  name: 'chatChannel',
  pure: false
})
export class ChatChannelPipe implements PipeTransform {

  constructor(private _chatService: ChatService) {}

  transform(channelNames: string[]): IChatChannel[] {
    return this._chatService.channels.filter(c => channelNames.includes(this._chatService.getChannelName(c.name)));
  }

}
