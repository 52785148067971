import { AccountService } from './../../services/account.service';
import { ICharacter } from './../../interfaces/character';
import FirebaseAuth from 'firebase/auth';
import { IEventPlayer } from './../../interfaces/event';
import { AuthService } from './../../services/auth.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { IEvent } from '../../interfaces/event';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { loadScript } from '@paypal/paypal-js';

@Component({
  selector: 'app-pay-dialog',
  templateUrl: './pay-dialog.component.html',
  styleUrls: ['../tiles.scss', './pay-dialog.component.scss']
})
export class PayDialogComponent implements OnInit, OnDestroy {
  @ViewChild('paypalButton', { static: false }) paypalButton: ElementRef;

  public event: IEvent;
  public eventPlayer: IEventPlayer;
  public loading = true;
  public eventCost = 0;
  public paymentAmount = 0;

  private _user: FirebaseAuth.User;
  private _character: ICharacter;
  private _paypalSubscriptionSub: Subscription;
  private _characterSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _authService: AuthService,
    private _db: AngularFireDatabase,
    private _accountService: AccountService
  ) {
    this.event = data.event;
    this.eventPlayer = data.eventPlayer;
  }

  public ngOnInit(): void {
    // PaypalHelper.loadScript();
    this._authService.user.subscribe(user => {
      this._user = user;
      const characterRef = this._db.object('characters/' + user.uid + '/' + this.eventPlayer.characterId);
      this._characterSub = characterRef
        .valueChanges()
        .pipe(take(1))
        .subscribe((character: ICharacter) => {
          const baseCost = character.type === 'npc' ? this.event.npcCost : this.event.cost;
          this.eventCost = baseCost * this.event.length;

          this._paypalSubscriptionSub = this._accountService.paypalSubscription.subscribe(paypalSub => {
            if (paypalSub) {
              const discount = (paypalSub.status === 'ACTIVE' ? this.event.memberDiscount : 0) * this.event.length;
              this.eventCost -= discount;
            }
          });

          const clientId = environment.paypal.env === 'production' ? environment.paypal.client.production : environment.paypal.client.sandbox;
          loadScript({ 'client-id': clientId, 'disable-funding': 'credit,card' })
            .then((paypal) => {
              paypal.Buttons({
                createOrder: (data, actions) => {
                  // This function sets up the details of the transaction, including the amount and line item details.
                  return actions.order.create({
                    purchase_units: [{
                      description: 'CRUX Event',
                      amount: {
                        currency_code: 'USD',
                        value: this.eventCost.toString()
                      }
                    }]
                  });
                },
                onApprove: (data, actions) => {
                  // This function captures the funds from the transaction.
                  return actions.order.capture().then(details => {
                    const eventPlayerRef = this._db.object('events/' + this.event.id + '/players/' + this._user.uid);
                    this.eventPlayer.paidValue = this.eventCost;
                    this.eventPlayer.datePaid = moment.utc().toISOString();
                    eventPlayerRef.update(this.eventPlayer).then(ep => {
                      this.dialogRef.close(this.eventPlayer);
                    });
                  });
                }
              }).render(this.paypalButton.nativeElement);
              this.loading = false;
            })
            .catch((err) => {
              console.error('Failed to load the PayPal JS SDK script library.', err);
            });
        });
    });
  }

  public ngOnDestroy(): void {
    if (this._characterSub) this._characterSub.unsubscribe();
    if (this._paypalSubscriptionSub) this._paypalSubscriptionSub.unsubscribe();
  }

  public onClickClose(): void {
    this.dialogRef.close();
  }
}
