export interface ICraftingSkill {
  id: string;
  name: string;
  description: string;
  maxCount: number;
  imageUrl?: string;
  requirements?: string[];
  type: CraftingType;
}

export enum CraftingType {
  Skill = 'Skill',
  Lore = 'Lore',
  Talent = 'Talent'
}
