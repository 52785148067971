import { TooltipCraftingComponent } from './components/tooltip/crafting/crafting.component';
import { CraftingService } from './services/crafting.service';
import { TooltipDescriptionComponent } from './components/tooltip/description/description.component';
import { HomeTileProfessionsComponent } from './components/home/tiles/professions/professions.component';
import { ProfessionsPipe } from './pipes/professions.pipe';
import { AuthDialogComponent } from './components/auth-dialog/auth-dialog.component';
import { SideNavCharactersComponent } from './components/side-nav/characters/characters.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TextMaskModule } from 'angular2-text-mask';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { OverlayModule } from '@angular/cdk/overlay';
import { QuillModule } from 'ngx-quill';

import { AppComponent } from './app.component';
import { AngularMaterialsModule } from './angular-materials.module';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireFunctionsModule, USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';

import { AuthGuard } from './services/auth.guard';

import { AuthService } from './services/auth.service';
import { AccountService } from './services/account.service';
import { MarkdownService } from './services/markdown.service';
import { CharacterService } from './services/character.service';

import { SideNavComponent } from './components/side-nav/side-nav.component';
import { HandbookComponent } from './components/handbook/handbook.component';
import { LogisticsComponent } from './components/logistics/logistics.component';
import { CharacterCardComponent } from './components/characters/card/card.component';
import { CharacterCardOriginComponent } from './components/characters/card/origin/origin.component';
import { HomeComponent } from './components/home/home.component';
import { SettingsComponent } from './components/settings/settings.component';
import { PageBorderComponent } from './components/page-border/page-border.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { EventsComponent } from './components/events/events.component';
import { EventDetailComponent } from './components/events/detail/detail.component';
import { OriginsComponent } from './components/origins/origins.component';
import { OriginCardComponent } from './components/origins/card/card.component';
import { OriginDetailComponent } from './components/origins/detail/detail.component';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

import { EllipsisModule } from 'ngx-ellipsis';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { JsonService } from './services/json.service';
import { SkillsPipe } from './pipes/skills.pipe';
import { AppTooltipDirective } from './directives/skill-tooltip.directive';
import { AppTooltipComponent } from './components/tooltip/app-tooltip.component';
import { TooltipSkillComponent } from './components/tooltip/skill/skill.component';
import { TooltipOriginDescriptionComponent } from './components/tooltip/origin-description/origin-description.component';
import { AttributePipe } from './pipes/attribute.pipe';
import { TooltipAttributeComponent } from './components/tooltip/attribute/attribute.component';
import { TooltipTextComponent } from './components/tooltip/text/text.component';
import { CharacterCardDetailsComponent } from './components/characters/card/details/details.component';
import { CharacterCardAttributesComponent } from './components/characters/card/attributes/attributes.component';
import { SkillsService } from './services/skills.service';
import { AttributesService } from './services/attributes.service';
import { CharacterCardProfessionComponent } from './components/characters/card/profession/profession.component';
import { CharacterCardSkillsComponent } from './components/characters/card/skills/skills.component';
import { ProfessionsService } from './services/professions.service';
import { SkillViewPipe } from './pipes/skill-view.pipe';
import { CharacterCardSkillSlotComponent } from './components/characters/card/skills/slot/slot.component';
import { OriginsService } from './services/origins.service';
import { CharacterCardSkillsGroupComponent } from './components/characters/card/skills/group/group.component';
import { AttributeNamePipe } from './pipes/attribute-name.pipe';
import { CharacterCardSpellsGroupComponent } from './components/characters/card/spells/group/group.component';
import { CharacterCardSpellsSpellComponent } from './components/characters/card/spells/slot/slot.component';
import { SpellsService } from './services/spells.service';
import { TooltipSpellComponent } from './components/tooltip/spell/spell.component';
import { CharacterCardSpellSlotsComponent } from './components/characters/card/spells/slots/slots.component';
import { SpellSlotsService } from './services/spell-slots.service';
import { PageService } from './services/page.service';
import { CharacterCardSpellsComponent } from './components/characters/card/spells/spells.component';
import { OriginPipe } from './pipes/origin.pipe';
import { CharacterCardProfessionSlotComponent } from './components/characters/card/profession/slot/slot.component';
import { TooltipProfessionComponent } from './components/tooltip/profession/profession.component';
// tslint:disable-next-line:max-line-length
import { CharacterCardProfessionSlotDropdownComponent } from './components/characters/card/profession/slot-dropdown/slot-dropdown.component';
import { SideNavEventsComponent } from './components/side-nav/events/events.component';
import { CharacterCardXpLogComponent } from './components/characters/card/xp-log/xp-log.component';
import { EventRegisterDialogComponent } from './components/events/register/register.component';
import { EventUnregisterDialogComponent } from './components/events/unregister/unregister.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { CharacterSelectorComponent } from './components/characters/selector/selector.component';
import { AccountSelectorComponent } from './components/accounts/selector/selector.component';
import { CalendarComponent } from './components/calendar/calendar.component';

import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { FirebaseUIModule, firebaseui } from 'firebaseui-angular';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { PayDialogComponent } from './components/pay-dialog/pay-dialog.component';
import { MembershipDialogComponent } from './components/membership-dialog/membership-dialog.component';
import { ClickOutsideModule } from 'ng4-click-outside';
import { EventSelectorComponent } from './components/events/selector/selector.component';
import { DropMenuComponent } from './components/drop-menu/drop-menu.component';
import { DropMenuEventsComponent } from './components/drop-menu/events/events.component';
import { DropMenuAvatarComponent } from './components/drop-menu/avatar/avatar.component';
import { DropMenuCharactersComponent } from './components/drop-menu/characters/characters.component';
import { DropMenuAccountsComponent } from './components/drop-menu/accounts/accounts.component';
import { CommentsComponent } from './components/comments/comments.component';
import { CommentEntryComponent } from './components/comments/comment/entry.component';
import { PlayersService } from './services/players.service';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { TierComponent } from './components/characters/card/profession/tier/tier.component';
import { FindCharacterDialogComponent } from './components/find-character-dialog/find-character-dialog.component';
import { XpAwardDialogComponent } from './components/xp-award-dialog/xp-award-dialog.component';
import { AllowContextMenuDirective } from './directives/allow-context-menu.directive';
import { PrintService } from './services/print.service';
import { CharacterCardPrintComponent } from './components/characters/card/print/print.component';
import { CharacterCardPrintSkillComponent } from './components/characters/card/print/skill/skill.component';
import { CharacterCardPrintSkillGroupComponent } from './components/characters/card/print/skill-group/skill-group.component';
import { CharacterCardPrintSkillShieldComponent } from './components/characters/card/print/skill-shield/skill-shield.component';
import { CharacterCardPrintProfessionGroupComponent } from './components/characters/card/print/profession-group/profession-group.component';
import { CharacterCardPrintSpellSlotsComponent } from './components/characters/card/print/spell-slots/spell-slots.component';
import { ProfessionsComponent } from './components/professions/professions.component';
import { ProfessionCardComponent } from './components/professions/card/card.component';
import { ProfessionPipe } from './pipes/profession.pipe';
import { ProfessionDetailComponent } from './components/professions/detail/detail.component';
import { SideNavProfileComponent } from './components/side-nav/profile/profile.component';
import { DropMenuChatComponent } from './components/drop-menu/chat/chat.component';
import { ChatService } from './services/chat.service';
import { PlayerPipe } from './pipes/player.pipe';
import { NewChannelComponent } from './components/drop-menu/chat/new-channel/new-channel.component';
import { ChatChannelPipe } from './pipes/chat-channel.pipe';
import { InvitePlayerComponent } from './components/drop-menu/chat/invite-player/invite-player.component';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { DropMenuService } from './services/drop-menu.service';
import { HomeTileComponent } from './components/home/tiles/tile.component';
import { HomeTileOriginsComponent } from './components/home/tiles/origins/origins.component';
import { HomeTileHandbookComponent } from './components/home/tiles/handbook/handbook.component';
import { HomeTileQuestlineComponent } from './components/home/tiles/questline/questline.component';
import { MobileNavService } from './services/mobile-nav.service';
import { NewCharacterDialogComponent } from './components/new-character-dialog/new-character-dialog.component';
import { SpellSlotsNamePipe } from './pipes/spell-slots-name.pipe';
import { MembershipsComponent } from './components/memberships/memberships.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ChapterNamePipe } from './pipes/chapter-name.pipe';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

// const facebookCustomConfig: AuthProviderWithCustomConfig = {
//   provider: AuthProvider.Facebook,
//   customConfig: {
//     scopes: [
//       'public_profile',
//       'email',
//       'user_likes',
//       'user_friends'
//     ],
//     customParameters: {
//       // Forces password re-entry.
//       auth_type: 'reauthenticate'
//     }
//   }
// };

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID,
    {
      requireDisplayName: false,
      provider: EmailAuthProvider.PROVIDER_ID
    }
  ],
  signInFlow: 'popup',
  credentialHelper: firebaseui.auth.CredentialHelper.NONE
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    HandbookComponent,
    LogisticsComponent,
    HomeComponent,
    SettingsComponent,
    PageBorderComponent,
    DeleteDialogComponent,
    EventsComponent,
    EventDetailComponent,
    OriginsComponent,
    OriginDetailComponent,
    SkillsPipe,
    SpellSlotsNamePipe,
    OriginCardComponent,
    AppTooltipDirective,
    AppTooltipComponent,
    CharacterCardComponent,
    CharacterCardOriginComponent,
    CharacterCardDetailsComponent,
    CharacterCardAttributesComponent,
    CharacterCardProfessionComponent,
    CharacterCardSkillsComponent,
    CharacterCardSkillSlotComponent,
    CharacterCardSkillsGroupComponent,
    CharacterCardSpellSlotsComponent,
    CharacterCardSpellsComponent,
    CharacterCardSpellsGroupComponent,
    CharacterCardSpellsSpellComponent,
    CharacterCardProfessionSlotComponent,
    CharacterCardProfessionSlotDropdownComponent,
    CharacterCardXpLogComponent,
    TooltipSkillComponent,
    TooltipOriginDescriptionComponent,
    AttributePipe,
    TooltipAttributeComponent,
    TooltipTextComponent,
    TooltipDescriptionComponent,
    SkillViewPipe,
    AttributeNamePipe,
    TooltipSpellComponent,
    SideNavCharactersComponent,
    OriginPipe,
    ProfessionPipe,
    ProfessionsPipe,
    TooltipProfessionComponent,
    TooltipCraftingComponent,
    SideNavEventsComponent,
    EventRegisterDialogComponent,
    EventUnregisterDialogComponent,
    CharacterSelectorComponent,
    CalendarComponent,
    AuthDialogComponent,
    ClickStopPropagationDirective,
    PayDialogComponent,
    MembershipDialogComponent,
    EventSelectorComponent,
    DropMenuComponent,
    DropMenuEventsComponent,
    DropMenuAvatarComponent,
    DropMenuCharactersComponent,
    DropMenuAccountsComponent,
    CommentsComponent,
    CommentEntryComponent,
    SafeHtmlPipe,
    TierComponent,
    FindCharacterDialogComponent,
    XpAwardDialogComponent,
    NewCharacterDialogComponent,
    AllowContextMenuDirective,
    CharacterCardPrintComponent,
    CharacterCardPrintSkillComponent,
    CharacterCardPrintSkillGroupComponent,
    CharacterCardPrintSkillShieldComponent,
    CharacterCardPrintProfessionGroupComponent,
    CharacterCardPrintSpellSlotsComponent,
    ProfessionsComponent,
    ProfessionCardComponent,
    ProfessionDetailComponent,
    SideNavProfileComponent,
    DropMenuChatComponent,
    PlayerPipe,
    NewChannelComponent,
    ChatChannelPipe,
    InvitePlayerComponent,
    ModalDialogComponent,
    AccountSelectorComponent,
    HomeTileComponent,
    HomeTileProfessionsComponent,
    HomeTileOriginsComponent,
    HomeTileHandbookComponent,
    HomeTileQuestlineComponent,
    MembershipsComponent,
    DateFormatPipe,
    ChapterNamePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularMaterialsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    PerfectScrollbarModule,
    EllipsisModule,
    TextMaskModule,
    ClickOutsideModule,
    ScrollToModule.forRoot(),
    ClipboardModule,
    OverlayModule,
    QuillModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTrackingId),
    NgxGoogleAnalyticsRouterModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
    PageService,
    AuthService,
    AuthGuard,
    AccountService,
    MarkdownService,
    CharacterService,
    JsonService,
    SkillsService,
    CraftingService,
    AttributesService,
    ProfessionsService,
    OriginsService,
    SpellsService,
    SpellSlotsService,
    PlayersService,
    PrintService,
    ChatService,
    DropMenuService,
    MobileNavService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
