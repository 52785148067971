import FirebaseAuth from 'firebase/auth';
import { IAccountInfo } from './../../interfaces/accountInfo';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { AccountService } from './../../services/account.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { PageService } from './../../services/page.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['../toolbar.scss', '../page.scss', '../tiles.scss', '../section.scss', './settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public isDirty = false;
  public accountInfo: IAccountInfo;

  private _user: FirebaseAuth.User;
  private _accountRef: AngularFireObject<IAccountInfo>;

  constructor(
    public pageService: PageService,
    private _authService: AuthService,
    private _accountService: AccountService,
    private firebaseAuth: AngularFireAuth,
    private _db: AngularFireDatabase,
  ) { }

  ngOnInit() {
    this._accountService.accountInfo.subscribe(info => {
      this.accountInfo = info;
    });

    this._authService.user.subscribe(user => {
      this._user = user;
    });
  }

  public onClickSave(): void {
    this._accountRef = this._db.object('accounts/' + this._user.uid);
    this._accountRef.update(this.accountInfo);
  }

}
