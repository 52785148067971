import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class JsonService {
  private _url = '';

  constructor(@Inject('BASE_URL') baseUrl: string, private _http: HttpClient) {
    this._url = baseUrl;
  }

  public get(type: string, folder?: string): Promise<any> {
    let url = this._url + 'data/' + type + '.json';
    if (folder) {
      url = this._url + 'data/' + folder + '/' + type + '.json';
    }

    return new Promise((resolve, reject) => {
      this._http.get(url).subscribe(data => {
        resolve(data);
      });
    });
  }

  public getCss(file: string): Promise<any> {
    const url = this._url + file + '.css';

    return new Promise((resolve, reject) => {
      this._http.get(url, { responseType: 'text' }).subscribe(data => {
        resolve(data);
      });
    });
  }

  public getById<T>(id: string, type: string, folder?: string): Promise<T> {
    return new Promise((resolve, reject) => {
      this.get(type, folder).then((results) => {
        const filtered = results.filter(r => r.id === id);
        if (filtered.length === 1) {
          resolve(filtered[0]);
        } else {
          reject('Cannot find ' + type);
        }
      });
    });
  }

  public getByIds<T>(ids: string[], type: string, folder?: string): Promise<T[]> {
    return new Promise((resolve, reject) => {
      this.get(type, folder).then((results) => {
        const filtered = results.filter(r => ids.includes(r.id));
        if (filtered.length > 0) {
          resolve(filtered);
        } else {
          reject('Cannot find ' + type);
        }
      });
    });
  }
}
