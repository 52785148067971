import { Component, OnInit, Input } from '@angular/core';
import { IOrigin } from '../../../interfaces/origin';

@Component({
  selector: 'app-tooltip-origin-description',
  templateUrl: './origin-description.component.html',
  styleUrls: ['./origin-description.component.scss']
})
export class TooltipOriginDescriptionComponent implements OnInit {
  @Input() public origin: IOrigin;
  @Input() data: any;

  constructor() { }

  ngOnInit() {
  }

}
