import { ISpell, ISpellGroup } from './../interfaces/spell';
import { JsonService } from './json.service';
import { Injectable } from '@angular/core';

@Injectable()
export class SpellsService {
  private _allSpellGroups: ISpellGroup[] = [];

  public get allSpellGroups(): ISpellGroup[] {
    return this._allSpellGroups;
  }

  constructor(private _jsonService: JsonService) {
    if (this._allSpellGroups.length === 0) {
      this._jsonService.get('spells').then(spellGroups => this._allSpellGroups = spellGroups);
    }
  }
}
