import { PageService } from './../../../../../services/page.service';
import { MobileTooltipActionType } from './../../../../../enums/mobileTooltipActionType';
import { ProfessionsService } from './../../../../../services/professions.service';
import { Character } from './../../../../../interfaces/character';
import { IProfession } from './../../../../../interfaces/profession';
import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-character-card-profession-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['../../../../slots.scss', './slot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardProfessionSlotComponent implements OnInit {
  @Input() profession: IProfession;
  public character: Character;
  public show = false;
  public MobileTooltipActionType = MobileTooltipActionType;

  private _parent: any;

  constructor(private _professionsService: ProfessionsService, private _cdr: ChangeDetectorRef, private _pageService: PageService) { }

  ngOnInit() { }

  public updateCharacter(character: Character, parent: any): void {
    this.character = character;
    this._parent = parent;

    this.show = true;
    this.markForCheck();
  }

  public onClickProfession(): void {
    if (this._pageService.isMobile) return;
    this.addProfession();
  }

  public addProfession(): void {
    if (!this.requirementsMet() || !this.canAffordProfession()) return;

    this.character.addProfession(this.profession).then(() => {
      this._parent.updateProfessions();
    });
  }

  public onRightClickProfession(): void {
    if (this._pageService.isMobile) return;
    this.removeProfession();
  }

  public removeProfession(): void {
    this.character.removeProfession(this.profession).then(() => {
      this._parent.updateProfessions();
    });
  }

  public hasProfession(): boolean {
    return this.character.hasProfession(this.profession);
  }

  public requirementsMet(): boolean {
    if (!this.profession) return false;
    if (this.profession.tier > this.character.maxProfessionTier) return false;
    if (this.character.hasAnyProfession(this.profession.tier)) return true;

    return true;
  }

  public requiredByOtherProf(): boolean {
    return false;
  }

  public markForCheck(): void {
    this._cdr.markForCheck();
  }

  public canAffordProfession(): boolean {
    return this.character.cpLeft >= this.profession.cost;
  }
}
