import { CraftingService } from './../../../services/crafting.service';
import { ICraftingSkill } from './../../../interfaces/crafting';
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-tooltip-crafting',
  templateUrl: './crafting.component.html',
  styleUrls: ['./crafting.component.scss']
})
export class TooltipCraftingComponent implements OnInit, OnChanges {
  @Input() skill: ICraftingSkill;
  @Input() data: any;

  public requirementsText = '';

  constructor(private _craftingsService: CraftingService) { }

  ngOnInit() { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['skill']) {
      if (this.skill.requirements) {
        this.requirementsText = '';
        for (let i = 0; i < this.skill.requirements.length; ++i) {
          const craftingId = this.skill.requirements[i];
          this._craftingsService.get(craftingId).then(requiredCrafting => {
            if (i > 0 && i < this.skill.requirements.length) {
              this.requirementsText += ', ';
            }

            if (requiredCrafting) {
              this.requirementsText += requiredCrafting.name;
            }
          });
        }

        this.requirementsText = this.requirementsText.substring(0, this.requirementsText.length - 6);
      }
    }
  }
}
