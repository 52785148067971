import { PageService } from './../../../../services/page.service';
import { AuthService } from './../../../../services/auth.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { IChatChannel } from '../../../../interfaces/chat';
import { Subscription } from 'rxjs';
import { ChatService } from '../../../../services/chat.service';

@Component({
  selector: 'app-new-channel',
  templateUrl: './new-channel.component.html',
  styleUrls: ['../../../section.scss', '../../../tiles.scss', '../../../character.scss', '../../../button.scss', './new-channel.component.scss']
})
export class NewChannelComponent implements OnInit, OnDestroy {
  public channel: IChatChannel;
  public errorText = '';

  private _uid: string;

  private _authSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<NewChannelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IChatChannel,
    private _db: AngularFireDatabase,
    private _authService: AuthService,
    public pageService: PageService,
    private _chatService: ChatService
  ) {
    this.channel = data;
  }

  ngOnInit() {
    this._authSub = this._authService.user.subscribe(user => {
      if (user) {
        this._uid = user.uid;
      }
    });
  }

  public ngOnDestroy(): void {
    if (this._authSub) this._authSub.unsubscribe();
  }

  public onClickCreate(): void {
    const channelName = this._chatService.getChannelName(this.channel.name);
    const index = this._chatService.channels.findIndex(c => this._chatService.getChannelName(c.name) === channelName) || channelName === 'new-channel';
    if (index < 0) {
      this.dialogRef.close(this.channel);
    } else {
      this.errorText = 'The channel already exists. Please choose a different name.';
    }
  }

  public onClickCancel(): void {
    this.dialogRef.close();
  }

  public onPrivateChanged(e: any): void {
    const checked = e.target.checked;
    if (checked) {
      this.channel.users = [this._uid];
    } else {
      this.channel.users = [];
    }
  }
}
