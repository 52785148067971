import { JsonService } from './json.service';
import { Injectable, ElementRef } from '@angular/core';

@Injectable()
export class PrintService {
  constructor(private _jsonService: JsonService) { }

  public print(characterName: string, printElement: ElementRef): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const printWindow = window.open('', 'crux-print', 'status=1');
      this._jsonService.getCss('print').then(contents => {
        printWindow.document.write(
          `<html><head><title>${characterName}</title>` +
          `<link href="https://fonts.googleapis.com/css?family=Berkshire+Swash" rel="stylesheet">` +
          `<style>${contents}</style></head>`
        );
        printWindow.document.write('<body class="A4 landscape" onafterprint="self.close()">');
        // printWindow.document.write('<body class="A4 landscape">');
        printWindow.document.write(printElement.nativeElement.innerHTML);
        printWindow.document.write('</body></html>');

        setTimeout(() => {
          printWindow.print();
          resolve();
        }, 250);
      });
    });
  }
}
