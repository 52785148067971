import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { IOrigin } from '../interfaces/origin';
import { JsonService } from './json.service';

@Injectable()
export class OriginsService {
  private _allOrigins: IOrigin[] = [];
  private _loading = false;

  constructor(private _jsonService: JsonService) { }

  public getAll(): Promise<IOrigin[]> {
    return new Promise((resolve, reject) => {
      if (this._loading) {
        setTimeout(() => {
          resolve(this.getAll());
        }, 100);
      } else {
        if (this._allOrigins.length === 0) {
          this._loading = true;
          this._jsonService.get('origins').then(origins => {
            this._allOrigins = origins;
            this._loading = false;
            resolve(origins);
          });
        } else {
          resolve(this._allOrigins);
        }
      }
    });
  }

  public get(id: string): Promise<IOrigin> {
    return new Promise((resolve, reject) => {
      if (this._loading) {
        setTimeout(() => {
          resolve(this.get(id));
        }, 100);
      } else {
        if (this._allOrigins.length === 0) {
          this._jsonService.get('origins').then(origins => {
            this._allOrigins = origins;
            resolve(this.findOrigin(id));
          });
        } else {
          resolve(this.findOrigin(id));
        }
      }
    });
  }

  private findOrigin(id: string): IOrigin {
    return this._allOrigins.find(r => r.id === id);
  }
}
