import { PageService } from './../../../../services/page.service';
import { CharacterCardComponent } from './../card.component';
import { Character, IXpLogEntry } from './../../../../interfaces/character';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-character-card-xp-log',
  templateUrl: './xp-log.component.html',
  styleUrls: ['../../../section.scss', './xp-log.component.scss']
})
export class CharacterCardXpLogComponent implements OnInit {
  public show = false;
  public character: Character;
  public logEntries: IXpLogEntry[] = [];

  private _parent: CharacterCardComponent;

  constructor(public pageService: PageService) { }

  ngOnInit() { }

  public updateCharacter(character: Character, parent: CharacterCardComponent): void {
    this.character = character;
    this._parent = parent;

    this.logEntries = this.character.data.xpLog.sort((a, b) => {
      const aDate = moment.utc(a.date);
      const bDate = moment.utc(b.date);

      if (aDate > bDate) return -1;
      if (aDate < bDate) return 1;
      return 0;
    });

    this.show = true;
  }
}
