import { Component, OnInit, Input, OnChanges, SimpleChanges, HostListener, ElementRef } from '@angular/core';
import { DropMenuType } from '../../enums/dropMenuType';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-drop-menu',
  templateUrl: './drop-menu.component.html',
  styleUrls: ['./drop-menu.component.scss'],
  animations: [
    trigger('menuState', [
      state(
        'inactive',
        style({
          height: '0',
        })
      ),
      state(
        'active',
        style({
          height: '*'
        })
      ),
      transition('inactive => active', animate('200ms ease-in')),
      transition('active => inactive', animate('200ms ease-out'))
    ])
  ]
})
export class DropMenuComponent implements OnInit {
  public dropMenuType = DropMenuType.None;
  public state = 'inactive';
  public DropMenuType = DropMenuType;
  public isTall = false;
  public isWide = false;

  private _animating = false;
  private _preventClose = false;

  constructor(private _elementRef: ElementRef) { }

  ngOnInit() { }

  public animationStarted(e: any): void {
    this._animating = true;
  }

  public animationDone(e: any): void {
    this._animating = false;
  }

  public changeType(type: DropMenuType, isTall = false, isWide = false): void {
    this.dropMenuType = type;
    this.isTall = isTall;
    this.isWide = isWide;

    this.state = this.dropMenuType !== DropMenuType.None ? 'active' : 'inactive';
  }

  public close(e: any): void {
    if (this.dropMenuType !== DropMenuType.None) {
      this.dropMenuType = DropMenuType.None;
      this.state = 'inactive';
    }
  }

  public preventClose(value: boolean): void {
    this._preventClose = value;
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClickOutside(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) return;
    if (this._animating) return;
    if (this._preventClose) return;

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.close(event);
    }
  }
}
