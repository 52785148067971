import { MobileTooltipActionType } from './../../../../../enums/mobileTooltipActionType';
import { PageService } from './../../../../../services/page.service';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';
import { ISkill } from '../../../../../interfaces/skill';
import { Character } from '../../../../../interfaces/character';
import { SkillHelper } from '../../../../../helpers/skill.helper';

@Component({
  selector: 'app-character-card-skill-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardSkillSlotComponent implements OnInit {
  @Input() skill: ISkill;
  @Output() skillClicked: EventEmitter<any> = new EventEmitter();
  @Output() skillRightClicked: EventEmitter<any> = new EventEmitter();

  public character: Character;
  public show = false;

  public count = 0;
  public maxCount: number;
  public nextSkillCost = 0;

  public MobileTooltipActionType = MobileTooltipActionType;

  private _parent: any;

  constructor(private _cdr: ChangeDetectorRef, private _pageService: PageService) { }

  public ngOnInit(): void { }

  public updateCharacter(character: Character, parent: any): void {
    this.character = character;
    this._parent = parent;
    this.calculateSkillData();

    this.show = true;
    this._cdr.markForCheck();
  }

  public hasSkill(): boolean {
    if (!this.character) {
      return false;
    }
    return this.character.hasSkill(this.skill);
  }

  public onClickSkill(): void {
    if (this._pageService.isMobile) return;
    this.addSkill();
  }

  public addSkill(): void {
    if (this.maxCount <= this.count) return;

    this.character.purchaseSkill(this.skill);

    this._cdr.markForCheck();
    this._parent.markForCheck();
    this._parent.checkDetails();
    this._parent.checkPrint();
    this.skillClicked.emit(null);
  }

  public onRightClickSkill(e: any): void {
    e.preventDefault();

    if (this._pageService.isMobile) return;
    this.removeSkill();
  }

  public removeSkill(): void {
    if (this.skill.cost === 0) return; // Don't sell auto skills.
    this.character.sellSkill(this.skill);

    this._cdr.markForCheck();
    this._parent.markForCheck();
    this._parent.checkDetails();
    this._parent.checkPrint();
    this.skillRightClicked.emit(null);
  }

  public calculateSkillData(): void {
    this.calculateSkillCount();
    this.calculateMaxSkillCount();
    this.calculateNextSkillCost();
  }

  public calculateSkillCount(): void {
    const characterSkill = this.character.data.skills.find(s => s.skillId === this.skill.id);
    if (characterSkill) {
      this.count = characterSkill.count;
    } else {
      this.count = 0;
    }
  }

  public calculateNextSkillCost(): void {
    const characterSkill = this.character.data.skills.find(s => s.skillId === this.skill.id);
    this.nextSkillCost = SkillHelper.getSkillCost(this.skill, characterSkill ? characterSkill.count + 1 : 1);
  }

  public calculateMaxSkillCount(): void {
    if (this.skill.maxCount) {
      this.maxCount = this.skill.maxCount;
    }

    if (this.skill.maxCountByAttribute) {
      this.maxCount = this.character.data[this.skill.maxCountByAttribute] + this.character.getAttributeBonuses(this.skill.maxCountByAttribute);
    }

    if (this.skill.maxCountBySkill) {
      this.maxCount = 0;
      const charSkill = this.character.data.skills.find(s => s.skillId === this.skill.id);
      const reqCharSkill = this.character.data.skills.find(s => s.skillId === this.skill.maxCountBySkill.skillId);
      if (reqCharSkill) {
        this.maxCount = Math.floor(reqCharSkill.count * this.skill.maxCountBySkill.multiplier);
      }
    }
  }

  public requirementsMet(): boolean {
    return this.character.skillRequirementsMet(this.skill);
  }

  public requiredByOtherSkill(): boolean {
    return this.character.requiredByOtherSkill(this.skill);
  }

  public canAffordSkill(): boolean {
    if (this.count === this.maxCount) return true;

    const charSkill = this.character.data.skills.find(s => s.skillId === this.skill.id);
    const cost = SkillHelper.getSkillCost(this.skill, charSkill ? charSkill.count + 1 : 1);
    return this.character.cpLeft >= cost;
  }

  public markForCheck(): void {
    this.calculateSkillData();
    this._cdr.markForCheck();
  }
}
