import { AttributesService } from './../services/attributes.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'attributeName'
})
export class AttributeNamePipe implements PipeTransform {
  constructor(private _attributesService: AttributesService) { }

  transform(attributeId: string): any {
    const attribute = this._attributesService.allAttributes.find(a => a.id === attributeId);
    return attribute ? attribute.name : 'Attribute not found';
  }

}
