import { MobileTooltipActionType } from './../../enums/mobileTooltipActionType';
import { PageService } from './../../services/page.service';
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  EventEmitter,
  Output,
  HostListener,
  ElementRef
} from '@angular/core';
import { TooltipVisibility, TooltipPosition, matTooltipAnimations } from '@angular/material/tooltip';
import { Subject, Observable } from 'rxjs';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { getMatTooltipInvalidPositionError } from '../../directives/tooltip-functions';

@Component({
  selector: 'app-tooltip',
  templateUrl: './app-tooltip.component.html',
  styleUrls: ['../button.scss', './app-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [matTooltipAnimations.tooltipState],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    // Forces the element to have a layout in IE and Edge. This fixes issues where the element
    // won't be rendered if the animations are disabled or there is no web animations polyfill.
    '[style.zoom]': '_visibility === "visible" ? 1 : null',
    '(body:click)': 'this._handleBodyInteraction()',
    'aria-hidden': 'true'
  }
})
export class AppTooltipComponent {
  public type: string;
  public target: any;
  public data: any;

  /** The timeout ID of any current timer set to show the tooltip */
  _showTimeoutId: number;

  /** The timeout ID of any current timer set to hide the tooltip */
  _hideTimeoutId: number;

  /** Property watched by the animation framework to show or hide the tooltip */
  _visibility: TooltipVisibility = 'initial';

  /** Whether interactions on the page should close the tooltip */
  private _closeOnInteraction = false;

  /** The transform origin used in the animation for showing and hiding the tooltip */
  _transformOrigin: 'top' | 'bottom' | 'left' | 'right' = 'bottom';

  /** Current position of the tooltip. */
  private _position: TooltipPosition;

  /** Subject for notifying that the tooltip has been hidden from the view */
  private readonly _onHide: Subject<void> = new Subject();

  private _ready = false;

  @Output() onBuy: EventEmitter<any> = new EventEmitter();
  @Output() onSell: EventEmitter<any> = new EventEmitter();

  public mobileTooltipActionType: MobileTooltipActionType;
  public MobileTooltipActionType = MobileTooltipActionType;

  constructor(private _elementRef: ElementRef, private _changeDetectorRef: ChangeDetectorRef, public pageService: PageService) { }

  /**
   * Shows the tooltip with an animation originating from the provided origin
   * @param position Position of the tooltip.
   * @param delay Amount of milliseconds to the delay showing the tooltip.
   */
  show(position: TooltipPosition, delay: number): void {
    // Cancel the delayed hide if it is scheduled
    if (this._hideTimeoutId) {
      clearTimeout(this._hideTimeoutId);
    }

    // Body interactions should cancel the tooltip if there is a delay in showing.
    this._closeOnInteraction = true;
    this._position = position;
    this._showTimeoutId = window.setTimeout(() => {
      this._visibility = 'visible';
      this._ready = true;

      // Mark for check so if any parent component has set the
      // ChangeDetectionStrategy to OnPush it will be checked anyways
      this._markForCheck();
    }, delay);
  }

  /**
   * Begins the animation to hide the tooltip after the provided delay in ms.
   * @param delay Amount of milliseconds to delay showing the tooltip.
   */
  hide(delay: number): void {
    // Cancel the delayed show if it is scheduled
    if (this._showTimeoutId) {
      clearTimeout(this._showTimeoutId);
    }

    this._hideTimeoutId = window.setTimeout(() => {
      this._visibility = 'hidden';

      // Mark for check so if any parent component has set the
      // ChangeDetectionStrategy to OnPush it will be checked anyways
      this._markForCheck();
    }, delay);
  }

  /** Returns an observable that notifies when the tooltip has been hidden from view. */
  afterHidden(): Observable<void> {
    return this._onHide.asObservable();
  }

  /** Whether the tooltip is being displayed. */
  isVisible(): boolean {
    return this._visibility === 'visible';
  }

  /** Sets the tooltip transform origin according to the position of the tooltip overlay. */
  _setTransformOrigin(overlayPosition: ConnectionPositionPair) {
    const axis = this._position === 'above' || this._position === 'below' ? 'Y' : 'X';
    const position = axis === 'X' ? overlayPosition.overlayX : overlayPosition.overlayY;

    if (position === 'top' || position === 'bottom') {
      this._transformOrigin = position;
    } else if (position === 'start') {
      this._transformOrigin = 'left';
    } else if (position === 'end') {
      this._transformOrigin = 'right';
    } else {
      throw getMatTooltipInvalidPositionError(this._position);
    }
  }

  _animationStart() {
    this._closeOnInteraction = false;
  }

  _animationDone(event: any): void {
    const toState = event.toState as TooltipVisibility;

    if (toState === 'hidden' && !this.isVisible()) {
      this._onHide.next();
    }

    if (toState === 'visible' || toState === 'hidden') {
      this._closeOnInteraction = true;
    }
  }

  /**
   * Interactions on the HTML body should close the tooltip immediately as defined in the
   * material design spec.
   * https://material.google.com/components/tooltips.html#tooltips-interaction
   */
  _handleBodyInteraction(): void {
    if (this.pageService.isMobile) return;

    if (this._closeOnInteraction) {
      this.hide(0);
    }
  }

  /**
   * Marks that the tooltip needs to be checked in the next change detection run.
   * Mainly used for rendering the initial text before positioning a tooltip, which
   * can be problematic in components with OnPush change detection.
   */
  _markForCheck(): void {
    this._changeDetectorRef.markForCheck();
  }

  public onClickBuy(): void {
    this.onBuy.emit();
  }

  public onClickSell(): void {
    this.onSell.emit();
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClickOutside(event: MouseEvent, targetElement: HTMLElement): void {
    // if (!this.pageService.isMobile) return;
    if (!targetElement) return;
    if (!this._ready) return;

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.hide(0);
    }
  }
}
