import { Component, OnInit, AfterViewInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { SkillsService } from '../../../../services/skills.service';
import { ISkill } from '../../../../interfaces/skill';
import { Character } from '../../../../interfaces/character';
import { CharacterCardSkillsGroupComponent } from './group/group.component';
import { PageService } from '../../../../services/page.service';
import { CharacterCardComponent } from '../card.component';

@Component({
  selector: 'app-character-card-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['../../../section.scss', './skills.component.scss']
})
export class CharacterCardSkillsComponent implements OnInit, AfterViewInit {
  public character: Character;
  public activeSkillGroup = 'Open';

  @ViewChildren(CharacterCardSkillsGroupComponent) skillGroups: QueryList<CharacterCardSkillsGroupComponent>;

  private _parent: CharacterCardComponent;

  public tabs = [
    { type: 'Open', tooltip: 'Open Skills', imageUrl: '../../../../../assets/images/open_skills.png' },
    { type: 'Stamina', tooltip: 'Stamina Skills', imageUrl: '../../../../../data/attributes/stamina.png' },
    { type: 'Arcane', tooltip: 'Arcane Skills', imageUrl: '../../../../../data/attributes/arcane.png' },
    { type: 'Agility', tooltip: 'Agility Skills', imageUrl: '../../../../../data/attributes/agility.png' },
    { type: 'Spirit', tooltip: 'Spirit Skills', imageUrl: '../../../../../data/attributes/spirit.png' }
  ];

  constructor(
    private _skillsService: SkillsService,
    public pageService: PageService) { }

  ngOnInit() { }

  public ngAfterViewInit(): void {

  }

  public updateCharacter(character: Character, parent: CharacterCardComponent): void {
    this.character = character;
    this._parent = parent;

    this.skillGroups.forEach(group => {
      group.updateCharacter(this.character, this);
    });
  }

  public onClickSkillGroupHeader(type: string): void {
    this.activeSkillGroup = type;
    this.markForCheck();
  }

  public markForCheck(): void {
    this.skillGroups.forEach(group => {
      group.markForCheck();
    });
  }

  public checkDetails(): void {
    this._parent.checkDetails();
  }

  public checkPrint(): void {
    this._parent.checkPrint();
  }
}
