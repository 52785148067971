export enum HomeTileType {
  Welcome,
  Origin,
  Handbook,
  Profession,
  NextEvent,
  PrevEvent,
  GettingStarted,
  Questline
}
