import { MembershipsComponent } from './components/memberships/memberships.component';
import { ProfessionsComponent } from './components/professions/professions.component';

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { CharacterCardComponent } from './components/characters/card/card.component';
import { HandbookComponent } from './components/handbook/handbook.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AuthGuard } from './services/auth.guard';
import { EventsComponent } from './components/events/events.component';
import { EventDetailComponent } from './components/events/detail/detail.component';
import { OriginsComponent } from './components/origins/origins.component';
import { OriginDetailComponent } from './components/origins/detail/detail.component';
import { ProfessionDetailComponent } from './components/professions/detail/detail.component';
import { LogisticsComponent } from './components/logistics/logistics.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'handbook', component: HandbookComponent },
  { path: 'logistics', component: LogisticsComponent },
  { path: 'characters/:id/:uid', component: CharacterCardComponent, canActivate: [AuthGuard] },
  { path: 'events', component: EventsComponent },
  { path: 'events/:id', component: EventsComponent },
  { path: 'origins', component: OriginsComponent },
  { path: 'origins/:id', component: OriginDetailComponent },
  { path: 'professions', component: ProfessionsComponent },
  { path: 'professions/:id', component: ProfessionDetailComponent },
  { path: 'memberships', component: MembershipsComponent },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
