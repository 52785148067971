import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Character, ICharacter } from '../interfaces/character';
import { AngularFireDatabase } from '@angular/fire/compat/database';

@Injectable()
export class CharacterService {
  private _uid: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public characters: ICharacter[] = [];

  constructor(private _authService: AuthService, private _db: AngularFireDatabase) {
    this._authService.user.subscribe(user => {
      if (user) {
        this._uid.next(user.uid);
      }
    });

    this.getAll();
  }

  public getAll(): void {
    this._uid.subscribe(uid => {
      if (uid) {
        const path = 'characters/' + this._uid.getValue();
        this._db.list<ICharacter>(path).valueChanges().subscribe(characters => {
          this.characters = characters;
        });
      }
    });
  }
}
