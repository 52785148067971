import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appAllowContextMenu]'
})
export class AllowContextMenuDirective {
  @HostListener('contextmenu', ['$event'])
  onContextMenu(event: MouseEvent) {
    event.stopPropagation();
    return true;
  }

  constructor() {}
}
