import { environment } from './../../environments/environment';
import { AccountService } from './account.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { INavTarget } from '../interfaces/navTarget';
import { SideNavMenuType } from '../enums/sideNavMenuType';

@Injectable()
export class PageService {
  private _pageTitle: BehaviorSubject<string> = new BehaviorSubject<string>('Home');
  public pageTitle: Observable<string> = this._pageTitle.asObservable();
  public mobileQuery: MediaQueryList;
  public normalQuery: MediaQueryList;
  public hdQuery: MediaQueryList;
  public allowAdminEdit = false;

  public activeTarget: number;
  public navTargets: INavTarget[] = [
    { target: 'home', text: 'Home', useAuth: false, useList: false },
    { target: 'handbook', text: 'Handbook', useAuth: false, useList: false },
    { target: 'logistics', text: 'Logistics', useAuth: false, useList: false },
    { target: 'origins', text: 'Origins', useAuth: false, useList: false },
    { target: 'professions', text: 'Professions', useAuth: false, useList: false },
    { target: 'events', text: 'Events', useAuth: false, useList: false },
    { target: 'characters', text: 'Characters', useAuth: true, useList: true, menuType: SideNavMenuType.Characters }
  ];

  public get isMobile(): boolean {
    return this.mobileQuery.matches;
  }

  public get isNormal(): boolean {
    return this.normalQuery.matches;
  }

  public get isHD(): boolean {
    return this.hdQuery.matches;
  }

  public get maxColumns(): number {
    if (this.isNormal) return 4;
    if (this.isHD) return 6;

    return 1;
  }

  public get isProd(): boolean {
    return environment.production;
  }

  constructor(private _accountService: AccountService) {
    this._accountService.accountInfo.subscribe(info => {
      if (info) {
        this.allowAdminEdit = info.roles ? info.roles.indexOf('admin') > -1 : false;
      }
    });
  }

  public setTarget(target: string): void {
    const index = this.navTargets.findIndex(nt => nt.target === target);
    this.activeTarget = index;
    this.setTitle(this.navTargets[index].text);
  }

  public setTitle(title: string): void {
    this._pageTitle.next(title);
  }
}
