import { ProfessionsService } from './../../../services/professions.service';
import { SpellsService } from './../../../services/spells.service';
import { ISpell } from './../../../interfaces/spell';
import { Component, OnInit, Input, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-tooltip-spell',
  templateUrl: './spell.component.html',
  styleUrls: ['./spell.component.scss']
})
export class TooltipSpellComponent implements OnInit, AfterViewInit {
  @Input() spell: ISpell;
  @Input() data: any;
  public requirementsText = '';

  constructor(private _profsService: ProfessionsService) {
  }

  ngOnInit() {
  }

  public ngAfterViewInit(): void {
    if (this.spell.requiredProfession && this.spell.requiredProfession.length > 0) {
      this.requirementsText = '';
      for (let i = 0; i < this.spell.requiredProfession.length; ++i) {
        const professionId = this.spell.requiredProfession[i];
        const requiredProf = this._profsService.allProfessions.find(s => s.id === professionId);
        if (requiredProf) {
          this.requirementsText += requiredProf.name + ', ' + (i === this.spell.requiredProfession.length - 1 ? 'and ' : '');
        }
      }

      this.requirementsText = this.requirementsText.substring(0, this.requirementsText.length - 6);
    }
  }

}
