import { SpellsService } from './../../../services/spells.service';
import { CharacterCardPrintComponent } from './print/print.component';
import { PrintService } from './../../../services/print.service';
import { OriginsService } from './../../../services/origins.service';
import { XpAwardDialogComponent } from './../../xp-award-dialog/xp-award-dialog.component';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, ViewChild, SimpleChanges, OnDestroy, ElementRef, ViewChildren, QueryList, ContentChild, AfterViewInit } from '@angular/core';
import { IOrigin } from '../../../interfaces/origin';
import { JsonService } from '../../../services/json.service';
import { ICharacter, Character } from '../../../interfaces/character';
import { CharacterCardOriginComponent } from './origin/origin.component';
import { CharacterCardDetailsComponent } from './details/details.component';
import { CharacterCardAttributesComponent } from './attributes/attributes.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteDialogComponent } from '../../delete-dialog/delete-dialog.component';
import { AuthService } from '../../../services/auth.service';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { SkillsService } from '../../../services/skills.service';
import { CharacterCardSkillsComponent } from './skills/skills.component';
import { CharacterCardSpellSlotsComponent } from './spells/slots/slots.component';
import { PageService } from '../../../services/page.service';
import { SpellSlotsService } from '../../../services/spell-slots.service';
import { CharacterCardSpellsComponent } from './spells/spells.component';
import { UuidHelper } from '../../../helpers/uuid.helper';
import { ProfessionsService } from '../../../services/professions.service';
import { CharacterCardProfessionComponent } from './profession/profession.component';
import { CharacterCardXpLogComponent } from './xp-log/xp-log.component';
import * as moment from 'moment';
import { ModalDialogComponent } from '../../modal-dialog/modal-dialog.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-character-card',
  templateUrl: './card.component.html',
  styleUrls: ['../../page.scss', '../../tiles.scss', '../../button.scss', '../../sheet.scss', './card.component.scss']
})
export class CharacterCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() characterId: string;
  public character: Character;
  public editName = false;
  public isNew = false;

  public activeProfessionsCount = 1;
  public gridColumns = 1;
  public isNameInUse = false;

  public Math = Math;

  private _characterRef: AngularFireObject<ICharacter>;
  private _oldName: string;
  private _charSub: Subscription;
  private _refSub: Subscription;
  private _dialogCloseSub: Subscription;
  private _minTileWidth = 250; // in pixels


  @ViewChild('page', { static: false }) pageElementRef: ElementRef;
  @ViewChild(CharacterCardDetailsComponent, { static: false }) detailsComponent: CharacterCardDetailsComponent;
  @ViewChild(CharacterCardPrintComponent, { static: false }) printComponent: CharacterCardPrintComponent;
  // @ViewChild(CharacterCardOriginComponent, { static: false }) originComponent: CharacterCardOriginComponent;
  // @ViewChild(CharacterCardDetailsComponent, { static: false }) experienceComponent: CharacterCardDetailsComponent;
  // @ViewChild(CharacterCardAttributesComponent, { static: false }) attributesComponent: CharacterCardAttributesComponent;
  // @ViewChild(CharacterCardSkillsComponent, { static: false }) skillsComponent: CharacterCardSkillsComponent;
  // @ViewChild(CharacterCardSpellSlotsComponent, { static: false }) spellSlotsComponent: CharacterCardSpellSlotsComponent;
  // @ViewChild(CharacterCardSpellsComponent, { static: false }) spellsComponent: CharacterCardSpellsComponent;
  // @ViewChildren(CharacterCardProfessionComponent) professionComponents: QueryList<CharacterCardProfessionComponent>;
  // @ViewChild(CharacterCardXpLogComponent, { static: false }) xpLogComponent: CharacterCardXpLogComponent;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _jsonService: JsonService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _authService: AuthService,
    private _db: AngularFireDatabase,
    private _router: Router,
    private _skillsService: SkillsService,
    private _spellSlotsService: SpellSlotsService,
    private _spellsService: SpellsService,
    private _professionsService: ProfessionsService,
    private _originsService: OriginsService,
    public pageService: PageService
  ) { }

  public ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      const id = params['id'] as string;
      const uid = params['uid'] as string;
      this._characterRef = this._db.object('characters/' + uid + '/' + id);
      this._refSub = this._characterRef
        .valueChanges()
        .pipe(take(1))
        .subscribe(character => {
          this.initCharacter(character);
        });
    });
  }

  public ngAfterViewInit(): void {
    this.calculateGridColumns();
  }

  public ngOnDestroy(): void {
    if (this._charSub) this._charSub.unsubscribe();
    if (this._refSub) this._refSub.unsubscribe();
    if (this._dialogCloseSub) this._dialogCloseSub.unsubscribe();
  }

  private initCharacter(character: ICharacter): void {
    this.character = new Character(character, this._skillsService, this._spellSlotsService, this._spellsService, this._professionsService, this._originsService);
    setTimeout(() => {
      // Hack to delay the initialization so the character can properly load
      this.detailsComponent?.updateCharacter(this.character, this);
      // this.experienceComponent?.updateCharacter(this.character, this);
      // this.attributesComponent?.updateCharacter(this.character, this);
      // this.originComponent?.updateCharacter(this.character, this);
      // this.skillsComponent?.updateCharacter(this.character, this);
      // this.spellSlotsComponent?.updateCharacter(this.character, this);
      // this.spellsComponent?.updateCharacter(this.character, this);
      // this.professionComponents.forEach(pc => {
      //   pc.updateCharacter(this.character, this);
      // });
      // this.xpLogComponent?.updateCharacter(this.character, this);
      this.activeProfessionsCount = this.character.data.professions.length;
      this._oldName = this.character.data.name;
    }, 100);
  }

  private calculateGridColumns(): void {
    const width = parseInt(this.pageElementRef.nativeElement['offsetWidth'], 10);

    setTimeout(() => {
      this.gridColumns = Math.trunc(width / this._minTileWidth);

      if (this.gridColumns < 1) {
        this.gridColumns = 1;
      }
    }, 1);
  }

  public check(): void {
    console.log(this.character);
  }

  public onClickDelete(): void {
    const dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '400px',
      height: '200px',
      data: {
        title: 'Delete ' + this.character.data.name + '?',
        text: 'Are you sure you wish to delete this character? You cannot undo this.'
      },
      panelClass: 'crux-dialog'
    });

    this._dialogCloseSub = dialogRef.afterClosed().subscribe(result => {
      // Yes, we have confirmed a delete request
      if (result) {
        this._characterRef.remove();
        this._router.navigate(['home']);
      }
    });
  }

  public onClickArchive(): void {
    const dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '400px',
      height: '200px',
      data: {
        title: 'Archive ' + this.character.data.name + '?',
        text: 'Are you sure you wish to archive this character?'
      },
      panelClass: 'crux-dialog'
    });

    this._dialogCloseSub = dialogRef.afterClosed().subscribe(result => {
      // Yes, we have confirmed a delete request
      if (result) {
        this.character.data.status = 'Archived';
        this.character.data.archiveDate = moment.utc().toISOString();
        this._characterRef.update(this.character.data);
        this._router.navigate(['home']);
      }
    });
  }

  public onClickAwardXp(): void {
    const dialogRef = this._dialog.open(XpAwardDialogComponent, {
      width: '400px',
      height: '500px',
      panelClass: 'crux-dialog'
    });

    this._dialogCloseSub = dialogRef.afterClosed().subscribe(result => {
      // Yes, we have confirmed a delete request
      if (result) {
        this.character.adjustXp(result);
        this._characterRef.update(this.character.data);
      }
    });
  }

  public onClickSave(): void {
    if (this.isNameInUse) {
      const dialogRef = this._dialog.open(ModalDialogComponent, {
        width: '400px',
        height: '200px',
        data: {
          title: 'Character Name',
          text: 'This name is already in use, please change it to something else.'
        },
        panelClass: 'crux-dialog'
      });

      this._dialogCloseSub = dialogRef.afterClosed().subscribe(result => {
        this.detailsComponent.setFocusOnName();
      });
    } else {
      this._characterRef.update(this.character.data);
      this.character.markClean();
    }
  }

  public onClickPrint(): void {
    if (this.character.isDirty) return; // Only print if we have been saved.

    this.printComponent.print();
  }

  public handleChangeProfessions(): void {
    this.activeProfessionsCount = this.character.data.professions.length;
    // this.professionComponents.forEach(pc => {
    //     pc.updateCharacter(this.character, this);
    // });
  }

  public checkDetails(): void {
    this.detailsComponent?.markForCheck();
  }

  // public checkSkills(): void {
  //   // this.skillsComponent.markForCheck();
  // }

  // public checkSpellSlots(): void {
  //   // this.spellSlotsComponent.markForCheck();
  // }

  // public checkSpells(): void {
  //   // this.spellsComponent.markForCheck();
  // }

  public checkProfessions(): void {
    this.activeProfessionsCount = this.character.data.professions.length;
    // this.professionComponents.forEach(pc => {
    //   pc.markForCheck();
    // });
  }

  // public checkOrigin(): void {
  //   // this.originComponent.markForCheck();
  // }

  // public checkAttributes(): void {
  //   // this.attributesComponent.checkAttributes();
  // }

  public checkPrint(): void {
    this.printComponent.markForCheck();
  }

  public onNameInUse(value: boolean): void {
    this.isNameInUse = value;
  }
}
