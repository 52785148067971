import { JsonService } from './json.service';
import { Injectable } from '@angular/core';
import { IMembershipDetails } from '../interfaces/memberships';

@Injectable({
  providedIn: 'root'
})
export class MembershipsService {
  private _allMemberships: IMembershipDetails[] = [];
  private _loading = false;

  constructor(private _jsonService: JsonService) {
  }

  public getAll(): Promise<IMembershipDetails[]> {
    return new Promise((resolve, reject) => {
      if (this._loading) {
        setTimeout(() => {
          resolve(this.getAll());
        }, 100);
      } else {
        if (this._allMemberships.length === 0) {
          this._loading = true;
          this._jsonService.get('memberships').then(memberships => {
            this._allMemberships = memberships;
            this._loading = false;
            resolve(memberships);
          });
        } else {
          resolve(this._allMemberships);
        }
      }
    });
  }

  public get(id: string): Promise<IMembershipDetails> {
    return new Promise((resolve, reject) => {
      if (this._loading) {
        setTimeout(() => {
          resolve(this.get(id));
        }, 100);
      } else {
        if (this._allMemberships.length === 0) {
          this._jsonService.get('memberships').then(memberships => {
            this._allMemberships = memberships;
            resolve(this.findSkill(id));
          });
        } else {
          resolve(this.findSkill(id));
        }
      }
    });
  }

  private findSkill(id: string): IMembershipDetails {
    return this._allMemberships.find(r => r.id === id);
  }
}
