import { MobileTooltipActionType } from './../../../../enums/mobileTooltipActionType';
import { CharacterCardProfessionSlotDropdownComponent } from './../profession/slot-dropdown/slot-dropdown.component';
import { ProfessionsService } from './../../../../services/professions.service';
import { ProfessionHelper } from './../../../../helpers/profession.helper';
import { IProfessionTier } from './../profession/profession.component';
import { ISpellSlot } from './../../../../interfaces/spell';
import { SpellSlotsService } from './../../../../services/spell-slots.service';
import { CharacterCardSkillSlotComponent } from './../skills/slot/slot.component';
import { ISkill } from './../../../../interfaces/skill';
import { IAttribute } from './../../../../interfaces/attribute';
import { IOrigin } from './../../../../interfaces/origin';
import { AttributesService } from './../../../../services/attributes.service';
import { SkillsService } from './../../../../services/skills.service';
import { OriginsService } from './../../../../services/origins.service';
import { PageService } from './../../../../services/page.service';
import { Character, IXpLogEntry } from './../../../../interfaces/character';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  QueryList,
  ViewChildren
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { CharacterService } from '../../../../services/character.service';
import moment, { max } from 'moment';
import marked from 'marked';
import { CharacterSheetTabType } from '../../../../enums/characterSheetTabType';

import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { AttributeHelper } from '../../../../helpers/attribute.helper';
import { CharacterCardSkillsGroupComponent } from '../skills/group/group.component';
import { CharacterCardSpellsGroupComponent } from '../spells/group/group.component';
import { CraftingType, ICraftingSkill } from '../../../../interfaces/crafting';
import { CraftingService } from '../../../../services/crafting.service';
import { CustomImageSpec, QuillHelper } from '../../../../helpers/quill.helper';



@Component({
  selector: 'app-character-card-details',
  templateUrl: './details.component.html',
  styleUrls: [
    '../../../section.scss',
    '../../../sheet.scss',
    '../../../quill.scss',
    './details.component.scss',
    '../../../button.scss'
  ],
})
export class CharacterCardDetailsComponent implements OnInit {
  @Output() nameInUse: EventEmitter<any> = new EventEmitter();
  @ViewChild('characterName', { static: false }) characterNameElementRef: ElementRef;
  @ViewChildren(CharacterCardSkillSlotComponent) skillSlots: QueryList<CharacterCardSkillSlotComponent>;
  @ViewChildren(CharacterCardSkillsGroupComponent) skillGroups: QueryList<CharacterCardSkillsGroupComponent>;
  @ViewChildren(CharacterCardSpellsGroupComponent) spellGroups: QueryList<CharacterCardSpellsGroupComponent>;
  @ViewChildren(CharacterCardProfessionSlotDropdownComponent) dropdownSlots: QueryList<CharacterCardProfessionSlotDropdownComponent>;

  public TABS = [
    { name: 'Background', type: CharacterSheetTabType.Background },
    { name: 'Skills', type: CharacterSheetTabType.Skills },
    { name: 'Spells', type: CharacterSheetTabType.Spells },
    { name: 'Professions', type: CharacterSheetTabType.Professions },
    { name: 'Crafting', type: CharacterSheetTabType.Crafting }
    // { name: 'Journal', type: CharacterSheetTabType.Journal }
    // { name: 'Inventory', type: CharacterSheetTabType.Inventory }
  ];
  public TabTypes = CharacterSheetTabType;
  public MobileTooltipActionType = MobileTooltipActionType;
  public CraftingType = CraftingType;
  public defaultImage = '/assets/images/default.png';

  public character: Character;
  public isNameUsed = false;
  public splitName: string[];
  public splitTeamName: string[];
  public xpToNextLevel = 20;
  public xpInCurrentLevel = 0;
  public xpProgress = 0;
  public origin: IOrigin;
  public allOrigins: IOrigin[] = [];
  public allAttributes: IAttribute[] = [];
  public originSkills: ISkill[] = [];
  public logEntries: IXpLogEntry[] = [];
  public currentTab = CharacterSheetTabType.Background;
  public background = '';
  public modules = {};
  public slotMax: number[] = [];
  public arcaneMax = 0;
  public allSpellSlots: ISpellSlot[] = [];
  public allSpellSlotSkills: ISkill[] = [];
  public professionTiers: IProfessionTier[] = [];
  public maxProfessionTier = 0;
  public craftingSkills: ICraftingSkill[] = [];
  public loreSkills: ICraftingSkill[] = [];
  public talentSkills: ICraftingSkill[] = [];

  public slotsTooltipData = {
    title: 'Spell Slots Total',
    description: `Spells are learned by allocating an area of your mind to understanding how the magic of that spell works. Thus, you create slots in your mind for each spell you learn. Once you have come to understand how the powers of a particular class of spell function, you may call upon that power once for every slot you have allocated. In this way, you may cast any spell you have learned of the same circle with that slot. Spells of the first circle are Class 1. You may learn as many of these slots as you can without limit. Some magic users spend a lifetime only using Class 1 spells. Spells of the second circle are Class 2. Because of the nature of the power of magic, the higher a class of spell is, the more unstable it becomes. This means more temper and focus is required in order to allocate them to your memory slots. The lowest class of spell you have in a pyramid or "stack" is your base. So if you had 3 Class 1 spells, your base would be 3. You may only stack slots up to a degree equivalent to your base. What this means is, if your base slots of Class 1 spells were three, you could learn up to three slots for Class 2 and Class 3 spells (3-3-3). Once you reach the limits of the degree of your base, you must reduce the next Class of slots by 1 (3-3-3-2). This is the new degree on which you may stack. Continuing with the previous example, you could still learn Class 4 spells, but you would be limited to only learning two. Thus, you could also learn two Class 5 spells, but then you would be limited to learning only one Class 6 spell (3-3-3-2-2-1). As such, spell slots are learned in a pyramid type fashion.

This also means he has a total of 14 spell slots. Now, you may decide to only learn up to a certain class or learn your slots in a square fashion. This would be mean only having as many slots as your base up to the limit of that degree. One example would be having four Class 1 spell slots and four in each Class all the way up to Class 4 (4-4-4-4). This would also make your number of slots total 16. The number of slots is also important because it is used to determine your magic pool.`
  };

  public editorStyles = {
    fontFamily: 'Gentium Book Basic'
  };

  private _parent: any;
  private _editorReady = false;

  constructor(public pageService: PageService,
    private _characterService: CharacterService,
    private _originsService: OriginsService,
    private _skillsService: SkillsService,
    private _attributesService: AttributesService,
    private _spellSlotsService: SpellSlotsService,
    private _profService: ProfessionsService,
    private _craftingService: CraftingService,
    private _cdr: ChangeDetectorRef) {
    this.modules = {
      blotFormatter: {
        specs: [CustomImageSpec]
      },
      toolbar: QuillHelper.TOOLBAR_OPTIONS
    };

    this.allSpellSlots = this._spellSlotsService.allSpellSlots;

    this.allSpellSlots.forEach(spellSlot => {
      this._skillsService.get(spellSlot.skillId).then(spellSlotSkill => {
        this.allSpellSlotSkills.push(spellSlotSkill);
      });
    });
  }

  ngOnInit() {
    this._originsService.getAll().then(origins => (this.allOrigins = origins));
    this.allAttributes = this._attributesService.allAttributes;
    this._craftingService.getAll().then(skills => {
      this.craftingSkills = skills.filter(s => s.type === CraftingType.Skill);
      this.loreSkills = skills.filter(s => s.type === CraftingType.Lore);
      this.talentSkills = skills.filter(s => s.type === CraftingType.Talent);
    });
  }

  public updateCharacter(character: Character, parent: any): void {
    this.character = character;
    this._parent = parent;
    this.updateExperience();

    this.splitName = this.character.data.name.split(' ');
    this.splitTeamName = this.character.data.team.split(' ');

    const baseXpForCurrentLevel = 20 * character.level;
    this.xpInCurrentLevel = character.data.xp - baseXpForCurrentLevel;
    this.xpProgress = this.xpInCurrentLevel / this.xpToNextLevel * 100;

    this.background = '';
    if (character.data.background) {
      this.background = character.data.background;
    } else if (character.data.backStory?.trim().length > 0) {
      this.background = marked(character.data.backStory);
      this.character.data.background = this.background;
      this.character.data.backStory = null;
    }

    this.initProfessionTiers();
    this.maxProfessionTier = this.character.maxProfessionTier;

    this.updateOrigin(true);
    this.updateXpLog();
    this.updateSkillGroups();
    this.updateSpellGroups();
    this.updateProfessions();
    this.calculateMaxSlots();
  }

  public updateOrigin(onlySetOrigin: boolean = false): void {
    // Remove the current Origin skills from the character
    if (!onlySetOrigin && this.origin) {
      this.origin.skills.forEach(skillId => {
        const skill = this.originSkills.find(s => s.id === skillId);
        if (skill) {
          this.character.sellSkill(skill);
        }
      });
    }

    // Set the new origin
    this.origin = this.allOrigins.find(r => r.id === this.character.data.originId);
    this.character.setOrigin(this.origin);

    // Updated the default bonus attribute when selecting a new origin
    if (!onlySetOrigin && this.origin.attributeModifiers.advantages.length > 0) {
      this.character.data.racialBonusAttributes = [this.origin.attributeModifiers.advantages[0].type[0]];
    }

    this._skillsService.getAll().then(allSkills => {
      this.originSkills = [];

      // Add any zero cost skills to the character
      this.origin.skills.forEach(skillId => {
        const skill = allSkills.find(s => s.id === skillId);
        this.originSkills.push(skill);
        if (skill && skill.cost === 0) {
          this.character.purchaseSkill(skill);
        }
      });

      // Set the default portrait
      if (!onlySetOrigin) {
        this.character.data.portrait = 'data/origins/' + this.origin.portraits[0];
      }

      this._cdr.detectChanges();

      // Now that we have skills we should update the skills section.
      this.updateSkillSlots();
    });
  }

  public updateXpLog(): void {
    this.logEntries = this.character.data.xpLog.sort((a, b) => {
      const aDate = moment.utc(a.date);
      const bDate = moment.utc(b.date);

      if (aDate > bDate) return -1;
      if (aDate < bDate) return 1;
      return 0;
    });
  }

  private updateExperience(): void { }

  private updateSkillSlots(): void {
    this.skillSlots.forEach(slot => {
      slot.updateCharacter(this.character, this);
    });
  }

  private updateSkillGroups(): void {
    setTimeout(() => {
      this.skillGroups.forEach(group => {
        group.updateCharacter(this.character, this);
      });
    }, 1);
  }

  private updateSpellGroups(): void {
    setTimeout(() => {
      this.spellGroups.forEach(group => {
        group.updateCharacter(this.character, this);
      });
    }, 1);
  }

  private updateProfessions(): void {
    setTimeout(() => {
      this.initProfessionTiers();
      this._skillsService.getAll().then(allSkills => {
        this.professionTiers.forEach(tier => {
          const charProf = this.character.data.professions.find(p => p.tier === tier.tier);
          if (charProf) {
            const prof = this._profService.allProfessions.find(p => p.id === charProf.professionId);
            if (prof) {
              tier.activeProfession = prof;
              tier.skills = allSkills.filter(s => prof.skills.includes(s.id));
            }
          }
        });

        this._cdr.detectChanges();

        this.updateSkillSlots();
        this.updateDropdownSlots();
        this.markForCheck();
        this._parent.checkPrint();
      });
    }, 1);
  }

  private updateDropdownSlots(): void {
    this.dropdownSlots.forEach(slot => {
      slot.updateCharacter(this.character, this);
    });
    this.checkDropdownSlots();
  }

  private checkDropdownSlots(): void {
    this.dropdownSlots.forEach(slot => {
      slot.markForCheck();
    });
  }

  public onKeyup(): void {
    this.character.markDirty();
    this._parent.checkPrint();
  }

  public onKeyupCharacterName(): void {
    this.character.markDirty();
    this._parent.checkPrint();

    this.isNameUsed = false;
    for (let i = 0; i < this._characterService.characters.length; ++i) {
      const character = this._characterService.characters[i];
      if (this.character.data.id === character.id) continue;

      if (character.name.toLowerCase() === this.character.data.name.toLowerCase()) {
        this.isNameUsed = true;
        break;
      }
    }

    this.nameInUse.emit(this.isNameUsed);
  }

  public setFocusOnName(): void {
    this.characterNameElementRef.nativeElement.focus();
    this.characterNameElementRef.nativeElement.select();
  }

  public onClickType(type: string): void {
    this.character.markDirty();
    this.character.setType(type);
  }

  public isAttributeSelected(type: string): boolean {
    return this.character.data.racialBonusAttributes.includes(type);
  }

  public getAttributeBonus(attribute: IAttribute): number {
    const bonus = this.character?.getAttributeBonuses(attribute.id) || 0;
    return bonus;
  }

  public onClickTab(type: CharacterSheetTabType): void {
    this.currentTab = type;

    this.updateSkillGroups();
    this.updateSpellGroups();
    this.updateProfessions();
  }

  public changedEditor(event: any): void {
    if (!this._editorReady) {
      this._editorReady = true;
      return;
    }

    if (event.event === 'text-change' && event.html?.length > 0) {
      this.character.markDirty();
      this.character.data.background = event.html;
    }
  }

  public changeName(name: string): void {
    this.character.markDirty();
    this.splitName = name.split(' ');
    this._parent.checkPrint();
  }

  public changeTeamName(team: string): void {
    this.character.markDirty();
    this.splitTeamName = team.split(' ');
    this._parent.checkPrint();
  }

  public changeDetail(): void {
    this.character.markDirty();
    this._parent.checkPrint();
  }

  public canAffordAttribute(rank: number, attribute: IAttribute): boolean {
    if (!this.character) {
      return false;
    }

    const bonus = this.character.getAttributeBonuses(attribute.id);
    rank -= bonus;

    // Check for any origin disadvantages to make sure we can't go above a certain value
    if (
      this.origin &&
      this.origin.attributeModifiers &&
      this.origin.attributeModifiers.disadvantages &&
      this.origin.attributeModifiers.disadvantages.length > 0
    ) {
      const attributeDisadvantage = this.origin.attributeModifiers.disadvantages.find(d => d.type.includes(attribute.id));
      if (attributeDisadvantage && attributeDisadvantage.value < rank) {
        return false;
      }
    }

    return rank - 1 <= this.character.apLeft;
  }

  public addAttribute(attribute: IAttribute): void {
    const nextValue = this.character.data[attribute.id] + 1;
    const bonus = this.character.getAttributeBonuses(attribute.id);
    const nextValueWithBonus = nextValue + bonus;

    if (this.canAffordAttribute(nextValueWithBonus, attribute)) {
      this.character.data[attribute.id] = nextValue;
      this.character.calculateRemainingAp();
      this.character.addPurchased(attribute.id);
      this.character.markDirty();
      this.markForCheck();
      // this._parent.checkSkills();
      // this._parent.checkSpellSlots();
      this._parent.checkPrint();
    }
  }

  public canRemoveAttribute(attribute: IAttribute): boolean {
    if (!this.character) return false;
    if (!this.character.canSell(attribute.id)) return false;

    const bonus = this.character.getAttributeBonuses(attribute.id);
    const attributeValue = this.character ? this.character[attribute.id] : 0;
    if (attributeValue - 1 <= bonus) return false;

    return true;
  }

  public removeAttribute(attribute: IAttribute): void {
    if (!this.character.canSell(attribute.id)) return;

    const prevValue = this.character.data[attribute.id] - 1;
    if (prevValue < 1) {
      return;
    }

    this.character.data[attribute.id] = prevValue;
    this.character.calculateRemainingAp();
    this.character.removePurchased(attribute.id);
    this.character.markDirty();
    this.markForCheck();
    // this._parent.checkSkills();
    // this._parent.checkSpellSlots();
    this._parent.checkPrint();
  }

  public onClickPreviousOrigin(): void {
    let index = this.allOrigins.findIndex(origin => origin.id === this.character.data.originId);
    if (--index < 0) {
      index = this.allOrigins.length - 1;
    }

    const originId = this.allOrigins[index].id;

    if (this.character.data.isLocked) return;

    this.character.data.originId = originId;
    this.updateOrigin();
    this._parent.checkSkills();
    this._parent.checkSpellSlots();
    this._parent.checkAttributes();
    this._parent.checkPrint();
    this.character.markDirty();
  }

  public onClickNextOrigin(): void {
    let index = this.allOrigins.findIndex(origin => origin.id === this.character.data.originId);
    if (++index === this.allOrigins.length) {
      index = 0;
    }

    const originId = this.allOrigins[index].id;

    if (this.character.data.isLocked) return;

    this.character.data.originId = originId;
    this.updateOrigin();
    this.markForCheck();
    this._parent.checkPrint();
    this.character.markDirty();
  }

  public onClickNextPortrait(): void {
    const pathList = this.character.data.portrait.split('/');
    const portraitName = pathList[pathList.length - 1];
    let newIndex = this.origin.portraits.findIndex(p => p === portraitName) + 1;
    if (newIndex >= this.origin.portraits.length) {
      newIndex = 0;
    }

    this.character.data.portrait = 'data/origins/' + this.origin.portraits[newIndex];
    this.character.markDirty();
    this.updateOrigin(true);
    this._parent.checkPrint();
  }

  public onClickPreviousPortrait(): void {
    const pathList = this.character.data.portrait.split('/');
    const portraitName = pathList[pathList.length - 1];
    let newIndex = this.origin.portraits.findIndex(p => p === portraitName) - 1;
    if (newIndex < 0) {
      newIndex = this.origin.portraits.length - 1;
    }

    this.character.data.portrait = 'data/origins/' + this.origin.portraits[newIndex];
    this.character.markDirty();
    this.updateOrigin(true);
    this._parent.checkPrint();
  }

  public onClickAttributeBonus(attribute: IAttribute): void {
    const attributeBonusName = attribute.id + '-bonus';
    if (!this.character.canSell(attributeBonusName)) return;

    if (this.character.data.racialBonusAttributes.length > 0) {
      const prevAttributeBonusName = this.character.data.racialBonusAttributes[0] + '-bonus';
      this.character.removePurchased(prevAttributeBonusName);
    }

    this.character.data.racialBonusAttributes = [attribute.id];
    this.character.addPurchased(attributeBonusName);
    this.character.markDirty();
    this.markForCheck();
    this._parent.checkPrint();
  }


  public handleSkillClicked(): void {
    this.markForCheck();
  }

  public handleSkillRightClicked(): void {
    this.markForCheck();
  }

  public checkDetails(): void {
    this.markForCheck();
  }

  public markForCheck(): void {
    this._cdr.markForCheck();

    this.skillSlots.forEach(slot => {
      slot.markForCheck();
    });

    this.skillGroups.forEach(group => {
      group.markForCheck();
    });

    this.spellGroups.forEach(group => {
      group.markForCheck();
    });

    this.calculateMaxSlots();
  }

  private calculateMaxSlots(): void {
    this.slotMax = [0, 0, 0, 0, 0, 0];
    const slotCount = this._spellSlotsService.allSpellSlots.length;

    this.arcaneMax = this.character.data.arcane + this.character.getAttributeBonuses('arcane');
    const meetsSkillReqs = this.character.skillRequirementsMet(this.allSpellSlotSkills[0]);
    if (!meetsSkillReqs) {
      this.arcaneMax = 0;
    }

    // 3, 2, 1, 0, 0, 0 = 3, 2, 2, 0, 0, 0
    // 4, 4, 2, 2, 1, 0 = 4, 4, 4, 4, 3, 3 = 4, 4, 4, 2, 1, 0
    // 4, 4, 0, 0, 0, 0 = 4, 4, 4, 4, 3, 3 = 4, 4, 4, 0, 0, 0

    this.slotMax[0] = this.arcaneMax;
    const baseValue = this.character.data.spellSlots[0].slots;

    let maxValue = baseValue;
    let stackCount = 1;

    for (let i = 1; i < this.slotMax.length; ++i) {
      const prevValue = this.character.data.spellSlots[i - 1].slots;
      const currentValue = this.character.data.spellSlots[i].slots;

      if (prevValue === currentValue) {
        stackCount++;
        maxValue = prevValue;
      } else if (currentValue < prevValue) {
        stackCount = 1;
      }

      if (maxValue < 0) {
        maxValue = 0;
      }

      if (prevValue < maxValue) {
        this.slotMax[i] = prevValue;
      } else {
        this.slotMax[i] = maxValue;
      }

      if (stackCount >= maxValue) {
        --maxValue;
      }

      if (this.slotMax[i] < 0 || prevValue <= 1) {
        this.slotMax[i] = 0;
      }

      // console.log(`Slot Level ${i + 1}: Prev: ${prevValue}, Curr: ${currentValue}, StackCount: ${stackCount}, Max: ${maxValue}, SlotMax: ${this.slotMax[i]}`);
    }
  }

  public getMaxSpellSlots(spellSlot: ISpellSlot): number {
    const characterSpellSlot = this.character.data.spellSlots.find(ss => ss.spellSlotId === spellSlot.id);
    if (characterSpellSlot) {
      return characterSpellSlot.slots;
    }

    return 0;
  }

  public getFreeSpellSlots(spellSlot: ISpellSlot): number {
    const characterSpellSlot = this.character.data.spellSlots.find(ss => ss.spellSlotId === spellSlot.id);
    if (characterSpellSlot) {
      return characterSpellSlot.slots - characterSpellSlot.spells.length;
    }

    return 0;
  }

  public getLevelText(level: number): string {
    switch (level) {
      case 1: return 'I';
      case 2: return 'II';
      case 3: return 'III';
      case 4: return 'IV';
      case 5: return 'V';
      case 6: return 'VI';
    }

    return '';
  }

  public addSpellSlot(index: number, spellSlot: ISpellSlot): void {
    if (!this.canAddSpellSlot(index, spellSlot)) return;

    this.character.purchaseSpellSlot(spellSlot).then(() => {
      this.calculateMaxSlots();
      this.markForCheck();
      this._parent.checkPrint();
    });
  }

  public removeSpellSlot(index: number, spellSlot: ISpellSlot): void {
    if (this.hasSpellInSlot(index, spellSlot)) return;
    if (!this.canSellSlot(index, spellSlot)) return;

    this.character.sellSpellSlot(spellSlot).then(() => {
      this.calculateMaxSlots();
      this.markForCheck();
      this._parent.checkPrint();
    });
  }

  public canAddSpellSlot(index: number, spellSlot: ISpellSlot): boolean {
    if (spellSlot.level > this.arcaneMax) return false;
    if (index === 0) return true;
    if (this.slotMax[index] === 0) return false;

    const characterSpellSlot = this.character.data.spellSlots.find(ss => ss.spellSlotId === this.allSpellSlots[index].id);
    if (characterSpellSlot.slots >= this.slotMax[index]) return false;

    const prevCharacterSpellSlot = this.character.data.spellSlots.find(ss => ss.spellSlotId === this.allSpellSlots[index - 1].id);
    if (prevCharacterSpellSlot && prevCharacterSpellSlot.slots > 0) {
      return true;
    }

    return false;
  }

  public canRemoveSpellSlot(index: number, spellSlot: ISpellSlot): boolean {
    const characterSpellSlot = this.character.data.spellSlots.find(ss => ss.spellSlotId === this.allSpellSlots[index].id);

    if (characterSpellSlot.slots === 0) return false;
    if (this.hasSpellInSlot(index, spellSlot)) return false;

    const nextIndex = index + 1;
    if (nextIndex < this.allSpellSlots.length) {
      const nextCharacterSpellSlot = this.character.data.spellSlots.find(ss => ss.spellSlotId === this.allSpellSlots[nextIndex].id);
      if (nextCharacterSpellSlot && nextCharacterSpellSlot.slots >= characterSpellSlot.slots) return false;
    }

    return this.canSellSlot(index, spellSlot);
  }

  public hasSpellInSlot(index: number, spellSlot: ISpellSlot): boolean {
    const charSpellSlot = this.character.data.spellSlots.find(ss => ss.spellSlotId === spellSlot.id);
    return charSpellSlot.spells.length > 0 && charSpellSlot.spells.length >= charSpellSlot.slots;
  }

  public canSellSlot(index: number, spellSlot: ISpellSlot): boolean {
    if (!this.character.canSell(spellSlot.skillId)) return false;

    const currentSlots = this.character.data.spellSlots[index].slots;
    if (currentSlots === 0) return true;

    if (index + 1 < this.slotMax.length) {
      const nextSlots = this.character.data.spellSlots[index + 1].slots;
      if (currentSlots <= nextSlots) {
        return false;
      }

      if (nextSlots > 0 && currentSlots === nextSlots) {
        return false;
      }
    }

    return true;
  }

  public getArcaneTotal(): number {
    const arcane = this.allAttributes.find(a => a.name === 'Arcane');
    const arcaneBonus = this.getAttributeBonus(arcane);
    const attributeValue = this.character['arcane'];
    return attributeValue + arcaneBonus;
  }

  public handleChangeProfessions(event: any): void {
    this._parent.handleChangeProfessions(event);
  }

  private initProfessionTiers(): void {
    // Build our tiers
    this.professionTiers = [];
    for (let i = 1; i <= 4; ++i) {
      this.professionTiers.push({
        tier: i,
        cost: ProfessionHelper.tierCPCosts[i - 1],
        activeProfession: null,
        skills: []
      });
    }
  }

  public getTierText(tier: IProfessionTier): string {
    switch (tier.tier) {
      case 1:
        return 'I';
      case 2:
        return 'II';
      case 3:
        return 'III';
      case 4:
        return 'IV';
    }

    return '';
  }

  public removeProfession(tier: IProfessionTier): void {
    this.character.removeProfession(tier.activeProfession).then(() => {
      this.updateProfessions();
    });
  }

  public checkPrint(): void {
    this._parent.checkPrint();
  }

  public getCraftingSkillLevel(skill: ICraftingSkill, type: CraftingType): number {
    const array = this.character.getCraftingArray(type);
    const craftingSkill = array.find(a => a.skillId === skill.id);
    if (craftingSkill) return craftingSkill.count;

    return 0;
  }

  public canAddCraftingSkill(skill: ICraftingSkill, type: CraftingType): boolean {
    const array = this.character.getCraftingArray(type);
    const craftingSkill = array.find(a => a.skillId === skill.id);

    if (craftingSkill) {
      const nextLevel = craftingSkill.count + 1;
      if (nextLevel > skill.maxCount) return false;
    }

    if ((type === CraftingType.Skill || type === CraftingType.Lore) && this.character.craftingLevelLeft === 0) return false;
    if (type === CraftingType.Talent && this.character.craftingTalentsLeft === 0) return false;

    return true;
  }

  public canRemoveCraftingSkill(skill: ICraftingSkill, type: CraftingType): boolean {
    if (!this.character.canSell(skill.id)) return false;

    const array = this.character.getCraftingArray(type);
    const craftingSkill = array.find(a => a.skillId === skill.id);

    if (!craftingSkill) return false;
    const prevLevel = craftingSkill.count - 1;
    if (prevLevel < 0) return false;

    return true;
  }

  public clickCraftingSkill(e: any, skill: ICraftingSkill, type: CraftingType): void {
    e.preventDefault();

    this.addCraftingSkill(skill, type);
  }

  public rightClickCraftingSkill(e: any, skill: ICraftingSkill, type: CraftingType): void {
    e.preventDefault();

    this.removeCraftingSkill(skill, type);
  }

  public addCraftingSkill(skill: ICraftingSkill, type: CraftingType): void {
    this.character.purchaseCraftingSkill(skill, type);
  }

  public removeCraftingSkill(skill: ICraftingSkill, type: CraftingType): void {
    this.character.sellCraftingSkill(skill, type);
  }

  public hasCraftingSkill(skill: ICraftingSkill, type: CraftingType): boolean {
    const array = this.character.getCraftingArray(type);
    const craftingSkill = array.find(a => a.skillId === skill.id);
    if (craftingSkill && craftingSkill.count > 0) return true;

    return false;
  }

  public getCraftingSkillTier(skill: ICraftingSkill): string {
    const skillLevel = this.getCraftingSkillLevel(skill, CraftingType.Skill);

    if (skillLevel === 0) return 'Unskilled';
    if (skillLevel === 15) return 'Expert';
    if (skillLevel >= 10) return 'Proficient';
    if (skillLevel >= 5) return 'Skilled';
    if (skillLevel > 0) return 'Novice';

    return '';
  }

  public getCraftingTitle(): string {
    if (this.character.craftingLevel === 0) return '';
    if (this.character.craftingLevel === 50) return 'Master';
    if (this.character.craftingLevel >= 40) return 'Artisan';
    if (this.character.craftingLevel >= 30) return 'Journeyman';
    if (this.character.craftingLevel >= 20) return 'Craftsman';
    if (this.character.craftingLevel >= 10) return 'Apprentice';
    if (this.character.craftingLevel > 0) return 'Novice';
  }

  public clickLock(): void {
    if (!this.pageService.allowAdminEdit) return;

    this.character.data.isLocked = !this.character.data.isLocked;
    this.character.markDirty();
  }
}
