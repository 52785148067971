import { ISpellSlot } from './../interfaces/spell';
import { JsonService } from './json.service';
import { Injectable } from '@angular/core';

@Injectable()
export class SpellSlotsService {
  private _allSpellSlots: ISpellSlot[] = [];

  public get allSpellSlots(): ISpellSlot[] {
    return this._allSpellSlots;
  }

  constructor(private _jsonService: JsonService) {
    if (this._allSpellSlots.length === 0) {
      this._jsonService.get('spellSlots').then(spellSlots => this._allSpellSlots = spellSlots);
    }
  }
}
