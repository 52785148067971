import { PrintService } from './../../services/print.service';
import { XpAwardDialogComponent } from './../xp-award-dialog/xp-award-dialog.component';
import { EventUnregisterDialogComponent } from './unregister/unregister.component';
import { IEventRegisterData, EventRegisterDialogComponent, IEventRegisterDataResult } from './register/register.component';
import { MatDialog } from '@angular/material/dialog';
import { Character, ICharacter } from './../../interfaces/character';
import { IProfile } from './../../interfaces/accountInfo';
import { IPlayerData } from './../../interfaces/playerData';
import { Subscription, Observable, firstValueFrom } from 'rxjs';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ElementRef, ViewChild, enableProdMode } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { IEvent, IEventPlayer } from '../../interfaces/event';
import { UuidHelper } from '../../helpers/uuid.helper';
import { PageService } from '../../services/page.service';
import { first, map, take } from 'rxjs/operators';
import { IDay, IWeek } from '../../interfaces/calendar';
import moment from 'moment';
import { AccountService } from '../../services/account.service';
import { EventTabType } from '../../enums/eventsTabType';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { SkillsService } from '../../services/skills.service';
import { SpellSlotsService } from '../../services/spell-slots.service';
import { SpellsService } from '../../services/spells.service';
import { ProfessionsService } from '../../services/professions.service';
import { OriginsService } from '../../services/origins.service';
import { ChapterService, MAIN_CHAPTER_GUID } from '../../services/chapter.service';
import { CustomImageSpec, QuillHelper } from '../../helpers/quill.helper';
import { IChapter } from '../../interfaces/chapter';
import { AuthService } from '../../services/auth.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { User } from 'firebase/auth';
import { PayDialogComponent } from '../pay-dialog/pay-dialog.component';

interface ITab {
  name: string;
  type: EventTabType;
  adminAccess: boolean;
}

enum EventStatusType {
  CLOSED = 'Closed',
  OPEN = 'Open',
  COMPLETED = 'Completed'
}

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['../toolbar.scss', '../page.scss', '../sheet.scss', '../quill.scss', '../button.scss', '../tabs.scss', './events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {
  @ViewChild('printContainer', { static: false }) printContainer: ElementRef;

  public events: Map<string, IEvent[]> = new Map();
  private _allEvents: IEvent[] = [];
  private _urlSub: Subscription;
  private _editorReady = false;
  private _userSub: Subscription;
  private _chaptersSub: Subscription;
  private _eventsSub: Subscription;
  private _dialogSub: Subscription;
  private _charactersSub: Subscription;
  private _eventPlayerSub: Subscription;
  private _eventPlayersSub: Subscription;
  private _dialogCloseSub: Subscription;
  private _paramsSub: Subscription;
  private _uid: string;
  private _homeChapterId: string;

  public TABS: ITab[] = [
    { name: 'Summary', type: EventTabType.Summary, adminAccess: false },
    { name: 'Admin', type: EventTabType.Admin, adminAccess: true }
  ];

  public TabTypes = EventTabType;
  public EventStatusType = EventStatusType;
  public userTabs: ITab[] = [];
  public currentMonthName: string;
  public currentYear: number;
  public weeks: IWeek[] = [];
  public currentDate: moment.Moment;
  public currentMonth: moment.Moment;
  public isMember = false;
  public currentTab = EventTabType.Summary;
  public currentEvent: IEvent;
  public currentEventDescription = '';
  public currentEventOpen = false;
  public showRegister = false;
  public eventPlayer: IEventPlayer;
  public eventPlayerPaid = false;
  public currentEventDirty = false;
  public eventPlayers: IPlayerData[] = [];
  public playerCount = 0;
  public npcCount = 0;
  public paidTotal = 0;
  public commentsCount = 0;
  public modules = {};
  public isSaving = false;
  public isNew = false;
  public playerChapters: IChapter[] = [];
  public allowAdminEdit = false;
  public currentChapter: IChapter;
  public showOpenRegistration = false;
  public allowRegistration = false;
  public allowComplete = false;
  public allowUnregister = false;
  public printing = false;

  public editorStyles = {
    fontFamily: 'Gentium Book Basic'
  };

  private sortByName(a: IChapter, b: IChapter): number {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    public _router: Router,
    private _db: AngularFireDatabase,
    private _authService: AuthService,
    public pageService: PageService,
    private _accountService: AccountService,
    private _scrollToService: ScrollToService,
    private _skillsService: SkillsService,
    private _spellSlotsService: SpellSlotsService,
    private _spellsService: SpellsService,
    private _professionsService: ProfessionsService,
    private _originsService: OriginsService,
    private _chapterService: ChapterService,
    private _dialog: MatDialog,
    private _printService: PrintService,
    private _cdr: ChangeDetectorRef
  ) {
    this.pageService.setTarget('events');

    this.modules = {
      blotFormatter: {
        specs: [CustomImageSpec]
      },
      toolbar: QuillHelper.TOOLBAR_OPTIONS
    };
  }

  public async ngOnInit(): Promise<void> {
    if (this._paramsSub) this._paramsSub.unsubscribe();
    this._paramsSub = this._activatedRoute.params.subscribe(params => {
      const eventId = params['id'] as string;

      this._accountService.accountInfo.subscribe(info => {
        if (info) {
          this._homeChapterId = info.profile.homeChapterId ?? MAIN_CHAPTER_GUID;
          this.allowAdminEdit = info.roles ? info.roles.indexOf('admin') > -1 : false;
          this.userTabs = this.TABS.filter(t => !t.adminAccess || (t.adminAccess && this.allowAdminEdit));

          this._userSub = this._authService.user.subscribe(user => {
            if (user) {
              this._uid = user.uid;

              if (this._charactersSub) this._charactersSub.unsubscribe();
              this._charactersSub = this._db
                .list<ICharacter>('characters/' + user.uid)
                .valueChanges()
                .pipe(take(1))
                .subscribe(chars => {
                  this.showRegister = chars.length > 0;
                });

              this._chaptersSub = this._chapterService.isLoaded.subscribe(chaptersLoaded => {
                if (chaptersLoaded) {
                  this.playerChapters = this._chapterService.chapters.filter(c => this.allowAdminEdit || c.admins.includes(user.uid)).sort(this.sortByName);

                  const today = moment().startOf('day');

                  this._eventsSub = this._db.list<IEvent>('events', ref => ref.orderByChild('startDate')).valueChanges().subscribe(allEvents => {
                    this._allEvents = allEvents;
                    this.events = new Map();
                    let scrollToEvent = false;

                    for (const event of allEvents) {
                      if (!event.npcCost) event.npcCost = 0;
                      if (!event.memberDiscount) event.memberDiscount = 0;
                      if (event.chapterId === undefined) {
                        event.chapterId = MAIN_CHAPTER_GUID;
                      }

                      const startDate = moment(event.startDate).startOf('day');
                      const startDateISO = startDate.toISOString();
                      const daysEvents = this.events.get(startDateISO);
                      if (daysEvents) {
                        daysEvents.push({ ...event });
                      } else {
                        this.events.set(startDateISO, [{ ...event }]);
                      }

                      if (eventId && eventId === event.id && !this.currentEvent) {
                        this.onClickEvent(event);
                        scrollToEvent = true;
                      } else if (!this.currentEvent && startDate.isSameOrAfter(today)) {
                        this.onClickEvent(event);
                        scrollToEvent = true;
                      }
                    }

                    if (scrollToEvent) {
                      setTimeout(() => {
                        this.scrollTo(this.currentEvent.id);
                      }, 10);
                    }

                    this.events = new Map([...this.events].sort());

                    if (!this.currentEvent) this.onClickEvent(allEvents[allEvents.length - 1]);

                    const updatedEvent = allEvents.find(e => e.id === this.currentEvent.id);
                    if (this.currentEvent && !this.currentEventDirty) {
                      this.currentEvent = { ...this.currentEvent, ...updatedEvent };
                      this.checkEventActions();
                    } else if (this.currentEvent && this.currentEventDirty) {
                      this.currentEvent = { ...this.currentEvent, players: updatedEvent.players };
                      this.checkEventActions();
                    }

                    this.buildWeeks(this._allEvents);
                  });

                  this.isMember = this._accountService.isMember;

                  this.currentDate = moment().startOf('day');
                  this.currentMonth = this.currentDate.startOf('month');
                  this.buildWeeks(this._allEvents);
                }
              });
            }
          });
        }
      });
    });
  }

  public ngOnDestroy(): void {
    if (this._urlSub) this._urlSub.unsubscribe();
    if (this._userSub) this._userSub.unsubscribe();
    if (this._chaptersSub) this._chaptersSub.unsubscribe();
    if (this._eventsSub) this._eventsSub.unsubscribe();
    if (this._dialogSub) this._dialogSub.unsubscribe();
    if (this._charactersSub) this._charactersSub.unsubscribe();
    if (this._eventPlayerSub) this._eventPlayerSub.unsubscribe();
    if (this._eventPlayersSub) this._eventPlayersSub.unsubscribe();
    if (this._dialogCloseSub) this._dialogCloseSub.unsubscribe();
  }

  private buildWeeks(allEvents: IEvent[]): void {
    const today = moment().startOf('day');
    this.currentMonthName = this.currentMonth.format('MMMM');
    this.currentYear = this.currentMonth.year();
    const dayOfWeek = this.currentMonth.day();

    let start = moment(this.currentMonth).subtract({ days: dayOfWeek + 1 });

    this.weeks = [];
    for (let k = 0; k < 6; ++k) {
      const week: IWeek = { days: [] };
      for (let i = 0; i < 7; ++i) {
        start = start.add({ days: 1 });
        const value = moment(start);

        const eventList = this._allEvents.filter(e => {
          const exactDay = e.startDate && !e.endDate;
          const startDay = moment(e.startDate).startOf('day');
          const endDay = e.endDate ? moment(e.endDate).endOf('day') : null;

          if (exactDay) {
            return value.isSame(startDay);
          } else {
            return value.isBetween(startDay, endDay, undefined, '[]');
          }
        });
        const events = eventList.map(event => ({ id: event.id, chapterId: event.chapterId, color: this._chapterService.chapters.find(c => c.id === event.chapterId)?.color || 'grey' }));

        week.days.push({
          value: value,
          text: value.date().toString(),
          isCurrentMonth: value.isSame(this.currentMonth, 'month'),
          isSelected: value.isSame(this.currentDate),
          isToday: value.isSame(today),
          events,
        });
      }

      this.weeks.push(week);
    }
  }

  private checkEventActions(): void {
    const now = moment.utc();
    const startDate = moment.utc(this.currentEvent.startDate);
    const endDate = moment.utc(this.currentEvent.endDate);

    this.showOpenRegistration = this.currentEvent.status === EventStatusType.CLOSED;
    this.allowRegistration = this.currentEvent.status === EventStatusType.OPEN && now.isBefore(endDate);
    this.allowComplete = now.isAfter(endDate);
    this.allowUnregister = this.currentEvent.status === EventStatusType.OPEN && now.isBefore(startDate);
    this.eventPlayerPaid = false;

    this.currentEventOpen = this.currentEvent.status === EventStatusType.OPEN;
    this.currentEventDescription = this.currentEvent.description;
  }

  public onClickEvent(event: IEvent): void {
    // this._router.navigate(['events', event.id]);
    this.currentEvent = event;

    if (this.currentEvent.status === 'Active') {
      // Convert the old active status to 'Closed' to start with
      this.currentEvent.status = EventStatusType.CLOSED;

      // If we have a registrationStartDate then change it to opened
      if (!!this.currentEvent.registrationStartDate) {
        this.currentEvent.status = EventStatusType.OPEN;
        delete this.currentEvent.registrationStartDate;
      }
    }

    this.currentChapter = this._chapterService.chapters.find(c => c.id === (this.currentEvent.chapterId || MAIN_CHAPTER_GUID));
    this.checkEventActions();

    if (this._eventPlayerSub) this._eventPlayerSub.unsubscribe();
    this._eventPlayerSub = this._db
      .object<IEventPlayer>(
        'events/' + this.currentEvent.id + '/players/' + this._uid
      )
      .valueChanges()
      .subscribe(ep => {
        this.eventPlayer = ep;
        this.eventPlayerPaid = event.status !== EventStatusType.COMPLETED && ep?.datePaid && moment(ep.datePaid).isValid();
      });

    if (this._eventPlayersSub) this._eventPlayersSub.unsubscribe();
    this._eventPlayersSub = this._db
      .object('events/' + event.id + '/players')
      .valueChanges()
      .subscribe((players: any) => {
        this.playerCount = 0;
        this.npcCount = 0;
        this.paidTotal = 0;
        this.eventPlayers = [];

        if (players) {
          for (const key in players) {
            if (!players.hasOwnProperty(key)) continue;

            const eventPlayer = players[key] as IEventPlayer;
            if (eventPlayer.status === 'Cancelled') continue;

            this.paidTotal += eventPlayer.paidValue;

            // for (let i = 0; i < 40; ++i) { // This for loop is a dummy loop to pad the players to check for speed
            this._db
              .object('accounts/' + key + '/profile')
              .valueChanges()
              .pipe(take(1))
              .subscribe((profile: IProfile) => {
                this._db
                  .object(
                    'characters/' +
                    eventPlayer.playerUid +
                    '/' +
                    eventPlayer.characterId
                  )
                  .valueChanges()
                  .pipe(take(1))
                  .subscribe((character: ICharacter) => {
                    if (character) {
                      // if (EventHelper.isCharacterCounted(this.event, character)) {
                      if (
                        !character.archiveDate ||
                        moment
                          .utc(character.archiveDate)
                          .isAfter(moment.utc(event.startDate))
                      ) {
                        if (character.type === 'npc') {
                          this.npcCount++;
                        } else {
                          this.playerCount++;
                        }

                        this.eventPlayers.push({
                          character: new Character(
                            character,
                            this._skillsService,
                            this._spellSlotsService,
                            this._spellsService,
                            this._professionsService,
                            this._originsService
                          ),
                          profile: profile,
                          eventPlayer: eventPlayer
                        });
                      }
                    }
                  });
              });
            // }
          }
        }
      });
  }

  public onClickDay(day: IDay): void {
    const date = day.value.toISOString();

    if (day.events.length > 0) {
      const event = this._allEvents.find(e => e.id === day.events[0].id);
      this.onClickEvent(event);
    }
  }

  public onClickDayEvent(ev: any, eventId: string): void {
    if (!eventId) return;

    const event = this._allEvents.find(e => e.id === eventId);
    if (!event) return;

    ev.stopPropagation();
    ev.preventDefault();
    this.onClickEvent(event);
  }

  public onClickPrevMonth(): void {
    this.currentMonth.subtract({ months: 1 });
    this.buildWeeks(this._allEvents);
  }

  public onClickNextMonth(): void {
    this.currentMonth.add({ months: 1 });
    this.buildWeeks(this._allEvents);
  }

  public onClickTab(type: EventTabType): void {
    this.currentTab = type;
  }

  public scrollTo(destination: string): void {
    const config: ScrollToConfigOptions = {
      target: destination
    };

    this._scrollToService.scrollTo(config);
  }

  public markCurrentEventDirty(): void {
    this.currentEventDirty = true;
  }

  public handleCommentsCount(count: number): void {
    this.commentsCount = count;
  }

  public changedEditor(event: any): void {
    if (!this._editorReady) {
      this._editorReady = true;
      return;
    }

    if (event.event === 'text-change' && event.html?.length > 0) {
      this.markCurrentEventDirty();
      this.currentEvent.description = event.html;
    }
  }

  private canSave(): boolean {
    if (!this.currentEventDirty) return false;

    return true;
  }

  public onClickSave(): void {
    if (!this.canSave()) return;

    const eventRef = this._db.object('events/' + this.currentEvent.id);
    let savePromise: Promise<any>;
    if (this.isNew) {
      savePromise = eventRef.set(this.currentEvent);
    } else {
      savePromise = eventRef.update(this.currentEvent);
    }

    this.isSaving = true;
    savePromise.then(() => {
      this.currentEventDirty = false;
      this.isSaving = false;
      this.buildWeeks(this._allEvents);
    });
  }

  public handleDateChanged(event: any, property: string): void {
    this.currentEventDirty = true;

    const date = moment(event.target.value);
    if (!date.isValid()) return;

    const isoDate = date.toISOString();
    if (isoDate) {
      this.currentEvent[property] = isoDate;
    }
  }

  public handleChapterChanged(event: any): void {
    const chapterId = event.target.value;
    this.currentEvent.chapterId = chapterId;
    this.currentEventDirty = true;
  }

  public onClickToggleStatus(statusType: EventStatusType): void {
    const dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '400px',
      height: '200px',
      data: {
        title: 'Open Registration?',
        text: `Are you sure you wish to ${statusType === EventStatusType.CLOSED ? 'close' : 'open'} registrations for the event: ${this.currentEvent.name}?`
      },
      panelClass: 'crux-dialog'
    });

    if (this._dialogSub) this._dialogSub.unsubscribe();
    this._dialogSub = dialogRef.afterClosed().subscribe(result => {
      // Yes, we have confirmed a open request
      if (result) {
        const eventRef = this._db.object('events/' + this.currentEvent.id);
        this.currentEvent.status = statusType;
        eventRef.update(this.currentEvent).then(() => {
          this.checkEventActions();
        });
      }
    });
  }

  public onClickRegister(): void {
    const data: IEventRegisterData = {
      uid: this._uid,
      event: this.currentEvent,
      eventPlayer: this.eventPlayer
    };

    const dialogRef = this._dialog.open(EventRegisterDialogComponent, {
      width: '300px',
      height: '500px',
      data: data,
      panelClass: 'crux-dialog'
    });

    if (this._dialogSub) this._dialogSub.unsubscribe();
    this._dialogSub = dialogRef
      .afterClosed()
      .subscribe((result: IEventRegisterDataResult) => {
        // if (result) {
        //   this.event = result.event;
        //   this.eventPlayer = result.eventPlayer;
        // }
      });
  }

  public onClickUnregister(): void {
    const data: IEventRegisterData = {
      uid: this._uid,
      event: this.currentEvent,
      eventPlayer: this.eventPlayer
    };

    const dialogRef = this._dialog.open(EventUnregisterDialogComponent, {
      width: '300px',
      height: '200px',
      data: data,
      panelClass: 'crux-dialog'
    });

    if (this._dialogSub) this._dialogSub.unsubscribe();
    this._dialogSub = dialogRef
      .afterClosed()
      .subscribe((result: IEventRegisterDataResult) => {
        // if (result) {
        //   this.event = result.event;
        //   this.eventPlayer = result.eventPlayer;
        // }
      });
  }

  public onClickCancelPlayerRegistration(eventPlayer: IEventPlayer): void {
    const data: IEventRegisterData = {
      uid: eventPlayer.playerUid,
      event: this.currentEvent,
      eventPlayer: eventPlayer
    };

    const dialogRef = this._dialog.open(EventUnregisterDialogComponent, {
      width: '300px',
      height: '200px',
      data: data,
      panelClass: 'crux-dialog'
    });

    // this._dialogSub = dialogRef.afterClosed().subscribe((result: IEventRegisterDataResult) => {
    // });
  }

  public onClickMarkPaid(eventPlayer: IEventPlayer): void {
    if (!this.currentEventOpen) return;

    const eventPlayerRef = this._db.object(
      'events/' + this.currentEvent.id + '/players/' + eventPlayer.playerUid
    );
    eventPlayer.datePaid = moment.utc().toISOString();
    eventPlayer.paidValue = this.currentEvent.cost;
    eventPlayerRef.update(eventPlayer);
  }

  public onClickOpenCharacter(eventPlayer: IEventPlayer): void {
    this._router.navigate([
      'characters',
      eventPlayer.characterId,
      eventPlayer.playerUid
    ]);
  }

  public onClickPay(e: any): void {
    const dialogRef = this._dialog.open(PayDialogComponent, {
      width: '300px',
      height: '200px',
      data: { event: this.currentEvent, eventPlayer: this.eventPlayer },
      panelClass: 'crux-dialog'
    });

    if (this._dialogSub) this._dialogSub.unsubscribe();
    this._dialogSub = dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.eventPlayer = result;
      }
    });
  }

  public onClickEndEvent(): void {
    this.currentEvent.status = 'Completed';
    const eventRef = this._db.object('events/' + this.currentEvent.id);
    eventRef.update(this.currentEvent);
  }

  public onClickRewardEvent(): void {
    const dialogRef = this._dialog.open(XpAwardDialogComponent, {
      width: '400px',
      height: '500px',
      panelClass: 'crux-dialog'
    });

    if (this._dialogCloseSub) this._dialogCloseSub.unsubscribe();
    this._dialogCloseSub = dialogRef.afterClosed().subscribe(result => {
      // Yes, we have confirmed a delete request
      if (result) {
        this.currentEvent.xpRewarded = true;

        const activePlayers = this.eventPlayers.filter(
          p => p.eventPlayer.status !== 'Cancelled'
        );
        activePlayers.forEach(playerData => {
          const characterRef = this._db.object(
            'characters/' +
            playerData.eventPlayer.playerUid +
            '/' +
            playerData.eventPlayer.characterId
          );

          playerData.character.adjustXp(result);
          playerData.character.data.isLocked = true;
          characterRef.update(playerData.character.data);
        });

        const eventRef = this._db.object('events/' + this.currentEvent.id);
        eventRef.update(this.currentEvent);
      }
    });
  }

  public onClickPrintAll(): void {
    this.printing = true;
    this._cdr.detectChanges();

    setTimeout(() => {
      this._printService
        .print(this.currentEvent.name + ' Character Print', this.printContainer)
        .then(() => {
          this.printing = false;
        });
    }, 100);
  }

  public onClickNew(): void {
    this.currentEvent = {
      id: UuidHelper.uuid(),
      name: 'New Event',
      description: '',
      portrait: '',
      startDate: moment.utc().toISOString(),
      endDate: moment.utc().toISOString(),
      players: [],
      cost: 0,
      npcCost: 0,
      memberDiscount: 0,
      status: EventStatusType.CLOSED,
      xpRewarded: false,
      length: 0,
      chapterId: this._homeChapterId
    };
    this.currentEventDescription = '';
    this.currentEventDirty = true;
    this.checkEventActions();
    this.currentTab = EventTabType.Admin;
  }

  public onClickDelete(): void {
    const dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '400px',
      height: '200px',
      data: {
        title: 'Delete Event?',
        text:
          `Are you sure you wish to delete this event: ${this.currentEvent.name}? You cannot undo this.`
      },
      panelClass: 'crux-dialog'
    });

    this._dialogSub = dialogRef.afterClosed().subscribe(result => {
      // Yes, we have confirmed a delete request
      if (result) {
        const id = this.currentEvent.id;
        this.currentEvent = null;
        this.events.delete(id);
        const eventRef = this._db.object('events/' + id);
        eventRef.remove().then(() => {

        });
      }
    });
  }
}
