import { AccountService } from './../../services/account.service';
import { AuthDialogComponent } from './../auth-dialog/auth-dialog.component';
import { PageService } from './../../services/page.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IAccountInfo } from '../../interfaces/accountInfo';
import { INavTarget } from '../../interfaces/navTarget';
import { SideNavMenuType } from '../../enums/sideNavMenuType';
import FirebaseAuth from 'firebase/auth';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./list.scss', './side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  public authenticated = false;
  public accountInfo: IAccountInfo;
  public user: FirebaseAuth.User;
  public menuType = SideNavMenuType.Main;
  public SideNavMenuType = SideNavMenuType;

  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _accountService: AccountService,
    public pageService: PageService,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    this._authService.user.subscribe(user => {
      if (user) {
        this.user = user;
        this.authenticated = true;
        this.menuType = SideNavMenuType.Main;

        this._accountService.accountInfo.subscribe(info => this.accountInfo = info);
      }
    });
  }

  public onClickLogin(): void {
    // this._authService.login();
    const dialogRef = this._dialog.open(AuthDialogComponent, {
      width: '300px',
      height: '400px',
      data: null,
      panelClass: 'crux-dialog'
    });

    // this._dialogSub = dialogRef.afterClosed().subscribe((result: IEventRegisterDataResult) => {
    //   if (result) {
    //     this.nextEvent = result.event;
    //     this.nextEventPlayer = result.eventPlayer;
    //   }
    // });
  }

  public onClickLogout(): void {
    this.authenticated = false;
    this._authService.logout();
    this._router.navigate(['/home']);
  }

  public onClickNav(navTarget: INavTarget): void {
    if (navTarget.useList) {
      // this.pageService.setTarget(navTarget.target);
      this.menuType = navTarget.menuType as SideNavMenuType;
      return;
    }

    this._router.navigate(['/' + navTarget.target]);

    if (this.pageService.isMobile) {
      this.close.emit();
    }
  }

  public handleClose(): void {
    this.close.emit();
  }

  public onClickBack(): void {
    this.menuType = SideNavMenuType.Main;
  }
}
