import { SpellSlotHelper } from './../../../../../helpers/spell-slot.helper';
import { MobileTooltipActionType } from './../../../../../enums/mobileTooltipActionType';
import { PageService } from './../../../../../services/page.service';
import { SkillsService } from './../../../../../services/skills.service';
import { SpellSlotsService } from './../../../../../services/spell-slots.service';
import { Component, OnInit, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { ISkill } from '../../../../../interfaces/skill';
import { ICharacter, Character } from '../../../../../interfaces/character';
import { ISpellSlot } from '../../../../../interfaces/spell';
import { CharacterCardComponent } from '../../card.component';

@Component({
  selector: 'app-character-card-spell-slots',
  templateUrl: './slots.component.html',
  styleUrls: ['../../../../section.scss', './slots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardSpellSlotsComponent implements OnInit {
  public allSpellSlots: ISpellSlot[] = [];
  public allSpellSlotSkills: ISkill[] = [];
  // public allSpellSlotCountMax: number[] = [];
  public slotMax: number[] = [];

  // public treeSlotIndex = [5, 5, 5, 5, 5, 5];

  public character: Character;
  public pips: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  public arcaneMax = 0;
  public show = false;
  public MobileTooltipActionType = MobileTooltipActionType;

  public total = 0;
  public mana = 0;
  public focus = 0;

  private _parent: CharacterCardComponent;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _spellSlotsService: SpellSlotsService,
    private _skillsService: SkillsService,
    public pageService: PageService
  ) {
    this.allSpellSlots = this._spellSlotsService.allSpellSlots;

    this.allSpellSlots.forEach(spellSlot => {
      this._skillsService.get(spellSlot.skillId).then(spellSlotSkill => {
        this.allSpellSlotSkills.push(spellSlotSkill);
      });
    });
  }

  ngOnInit() { }

  public updateCharacter(character: Character, parent: CharacterCardComponent): void {
    this.character = character;
    this._parent = parent;
    this.calculateMaxSlots();
    this.calculateSummary();

    this.show = true;
    this.markForCheck();
    this._parent.checkDetails();
  }

  private calculateMaxSlots(): void {
    this.slotMax = [0, 0, 0, 0, 0, 0];
    const slotCount = this._spellSlotsService.allSpellSlots.length;

    this.arcaneMax = this.character.data.arcane + this.character.getAttributeBonuses('arcane');
    const meetsSkillReqs = this.character.skillRequirementsMet(this.allSpellSlotSkills[0]);
    if (!meetsSkillReqs) {
      this.arcaneMax = 0;
    }

    this.pips = [];
    for (let i = 0; i < this.arcaneMax; ++i) {
      this.pips.push(i);
    }

    const baseValue = this.character.data.spellSlots[0].slots;
    this.slotMax[0] = this.arcaneMax;
    for (let i = this.slotMax.length - 1; i > 0; --i) {
      // If the number above me is the same, my value is my max
      const prevValue = this.character.data.spellSlots[i - 1].slots;
      const currentValue = this.character.data.spellSlots[i].slots;
      if (currentValue === prevValue) {
        this.slotMax[i] = currentValue;
        continue;
      }

      // If the number above me is different I add one to my value and lookup the value at that level above.
      if (currentValue < prevValue) {
        let prevCheckValue = baseValue + 1; // Note: The default value is the base value +1 for out of range checks
        const checkValue = currentValue + 1;
        if (i - checkValue >= 0) {
          // In range checks grab the value from the level
          prevCheckValue = this.character.data.spellSlots[i - checkValue].slots;
        }

        if (checkValue === prevCheckValue) {
          // If we are the same my value is my max.
          this.slotMax[i] = currentValue;
        } else if (checkValue < prevCheckValue) {
          // If we are less, my value is +1
          this.slotMax[i] = currentValue + 1;
        }
      }
    }
  }

  private calculateSummary(): void {
    this.total = this.character.spellSlotTotal;
    this.mana = this.character.spellSlotMana;
    this.focus = this.character.spellSlotFocus;
  }

  public onClickSpellSlot(index: number, spellSlot: ISpellSlot): void {
    if (this.pageService.isMobile) return;
    this.addSpellSlot(index, spellSlot);
  }

  public addSpellSlot(index: number, spellSlot: ISpellSlot): void {
    if (!this.requirementsMet(index, spellSlot)) return;

    this.character.purchaseSpellSlot(spellSlot).then(() => {
      this.calculateSummary();
      this._parent.checkDetails();
      this._parent.checkPrint();
      this.markForCheck();
    });
  }

  public onRightClickSpellSlot(index: number, spellSlot: ISpellSlot): void {
    if (this.pageService.isMobile) return;
    this.removeSpellSlot(index, spellSlot);
  }

  public removeSpellSlot(index: number, spellSlot: ISpellSlot): void {
    if (this.hasSpellInSlot(index, spellSlot)) return;
    if (!this.canSellSlot(index, spellSlot)) return;

    this.character.sellSpellSlot(spellSlot).then(() => {
      this.calculateSummary();
      this._parent.checkDetails();
      this._parent.checkProfessions();
      this._parent.checkPrint();
      this.markForCheck();
    });
  }

  public getSpellSlotCount(spellSlot: ISpellSlot): number {
    const characterSpellSlot = this.character.data.spellSlots.find(ss => ss.spellSlotId === spellSlot.id);
    if (characterSpellSlot) {
      return characterSpellSlot.slots;
    }

    return 0;
  }

  // public getMaxSlots(index: number, spellSlot: ISpellSlot): number {
  //   if (index === 0) return this.arcaneMax;

  //   const maxPyramidSlots = SpellSlotHelper.MAX_TREE_SLOTS[this.treeSlotIndex[index]];

  //   // const baseValue = this.character.data.spellSlots[0].slots;
  //   // if (baseValue > 5) return baseValue;

  //   if (this.arcaneMax < maxPyramidSlots) return this.arcaneMax;

  //   return maxPyramidSlots;
  // }

  public requirementsMet(index: number, spellSlot: ISpellSlot): boolean {
    if (spellSlot.level > this.arcaneMax) return false;
    if (index === 0) return true;
    if (this.slotMax[index] === 0) return false;
    // if (this.character.data.spellSlots[index].slots === this.slotMax[index]) return false;

    // if (index + 1 < this.slotMax.length) {
    //   if (this.character.data.spellSlots[index].slots > this.character.data.spellSlots[index + 1].slots) {
    //     return true;
    //   }
    // }

    const prevCharacterSpellSlot = this.character.data.spellSlots.find(ss => ss.spellSlotId === this.allSpellSlots[index - 1].id);
    if (prevCharacterSpellSlot && prevCharacterSpellSlot.slots > 0) {
      return true;
    }

    return false;
  }

  public hasSpellInSlot(index: number, spellSlot: ISpellSlot): boolean {
    const charSpellSlot = this.character.data.spellSlots.find(ss => ss.spellSlotId === spellSlot.id);
    return charSpellSlot.spells.length > 0 && charSpellSlot.spells.length >= charSpellSlot.slots;
  }

  public isValidStep(index: number, spellSlot: ISpellSlot): boolean {
    if (index === 0) return true;
    if (!this.character) return true;

    const currentCharacterSpellSlot = this.character.data.spellSlots.find(ss => ss.spellSlotId === this.allSpellSlots[index].id);
    const prevCharacterSpellSlot = this.character.data.spellSlots.find(ss => ss.spellSlotId === this.allSpellSlots[index - 1].id);
    if (prevCharacterSpellSlot.slots - currentCharacterSpellSlot.slots > index) {
      return false;
    }

    return true;
  }

  public canSellSlot(index: number, spellSlot: ISpellSlot): boolean {
    const currentSlots = this.character.data.spellSlots[index].slots;
    if (currentSlots === 0) return true;

    if (index + 1 < this.slotMax.length) {
      const nextSlots = this.character.data.spellSlots[index + 1].slots;
      if (currentSlots <= nextSlots) {
        return false;
      }

      if (nextSlots > 0 && currentSlots === nextSlots + 1) {
        return false;
      }
    }

    return true;
  }

  public onClickPip(index: number): void { }

  public isPipActive(index: number, spellSlot: ISpellSlot): boolean {
    if (!this.character) return false;

    const characterSkillSlot = this.character.data.spellSlots.find(s => s.spellSlotId === spellSlot.id);
    if (!characterSkillSlot) {
      return false;
    }

    return index < characterSkillSlot.spells.length;
  }

  public canFillPip(index: number, spellSlot: ISpellSlot): boolean {
    if (!this.character) return false;

    const characterSkillSlot = this.character.data.spellSlots.find(s => s.spellSlotId === spellSlot.id);
    if (!characterSkillSlot) {
      return false;
    }

    return index < characterSkillSlot.slots;
  }

  public isLastActivePip(index: number): boolean {
    return false;
  }

  public markForCheck(): void {
    this.calculateMaxSlots();
    this._cdr.markForCheck();
  }
}
