import { Subscription } from 'rxjs';
import { ICharacter } from './../../../interfaces/character';
import { IEventRegisterData, IEventRegisterDataResult } from './../register/register.component';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { IEvent, IEventPlayer } from './../../../interfaces/event';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-event-unregister-dialog',
  templateUrl: './unregister.component.html',
  styleUrls: ['../../tiles.scss', '../../button.scss', './unregister.component.scss']
})
export class EventUnregisterDialogComponent implements OnInit, OnDestroy {
  public eventPlayer: IEventPlayer;
  public event: IEvent;
  public characterName: string;

  private _isNew = false;
  private _eventPlayerRef: AngularFireObject<IEventPlayer>;

  private _characterSub: Subscription;

  constructor(
    public dialogRef: MatDialogRef<EventUnregisterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEventRegisterData,
    private _db: AngularFireDatabase
  ) { }

  public ngOnInit() {
    this.event = this.data.event;
    this.eventPlayer = this.data.eventPlayer;

    this._eventPlayerRef = this._db.object('events/' + this.event.id + '/players/' + this.data.uid);
    this._characterSub = this._db
      .object<ICharacter>('characters/' + this.eventPlayer.playerUid + '/' + this.eventPlayer.characterId)
      .valueChanges()
      .pipe(take(1))
      .subscribe(character => {
        this.characterName = character.name;
      });
  }

  public ngOnDestroy(): void {
    if (this._characterSub) this._characterSub.unsubscribe();
  }

  public onClickUnregister(): void {
    this.eventPlayer.status = 'Cancelled';
    this.eventPlayer.dateUpdated = moment.utc().toISOString();

    this._eventPlayerRef.update(this.eventPlayer).then(results => {
      const dataResult: IEventRegisterDataResult = {
        event: this.event,
        eventPlayer: this.eventPlayer
      };

      this.dialogRef.close(dataResult);
    });
  }

  public onClickCancel(): void {
    this.dialogRef.close();
  }
}
