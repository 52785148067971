import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { IOrigin } from '../../../interfaces/origin';
import { ISkill } from '../../../interfaces/skill';
import { JsonService } from '../../../services/json.service';
import { SkillsService } from '../../../services/skills.service';

@Component({
  selector: 'app-origin-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class OriginCardComponent implements OnInit {
  @Input() public origin: IOrigin;

  constructor(private _router: Router) {}

  ngOnInit() {}

  public onClickOrigin(origin: IOrigin): void {
    this._router.navigate(['origins', origin.id]);
  }
}
