import { IPlayerData } from './../../../interfaces/playerData';
import { SpellsService } from './../../../services/spells.service';
import { PrintService } from './../../../services/print.service';
import { PayDialogComponent } from './../../pay-dialog/pay-dialog.component';
import { OriginsService } from './../../../services/origins.service';
import { XpAwardDialogComponent } from './../../xp-award-dialog/xp-award-dialog.component';
import { EventUnregisterDialogComponent } from './../unregister/unregister.component';
import { ProfessionsService } from './../../../services/professions.service';
import { SkillsService } from './../../../services/skills.service';
import { ICharacter, Character } from './../../../interfaces/character';
import { IProfile } from './../../../interfaces/accountInfo';
import { IEventPlayer } from './../../../interfaces/event';
import { Subject, Observable, Subscription } from 'rxjs';
import { PageService } from './../../../services/page.service';
import { UuidHelper } from './../../../helpers/uuid.helper';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import {
  AngularFireDatabase,
  AngularFireObject,
  AngularFireList
} from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeleteDialogComponent } from '../../delete-dialog/delete-dialog.component';
import { IEvent } from '../../../interfaces/event';
import * as moment from 'moment';
import * as marked from 'marked';
import { SpellSlotsService } from '../../../services/spell-slots.service';
import { IComment } from '../../../interfaces/comment';
import {
  IEventRegisterData,
  EventRegisterDialogComponent,
  IEventRegisterDataResult
} from '../register/register.component';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-event-detail',
  templateUrl: './detail.component.html',
  styleUrls: [
    '../../tiles.scss',
    '../../section.scss',
    '../../toolbar.scss',
    '../../page.scss',
    '../../button.scss',
    './detail.component.scss'
  ]
})
export class EventDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('page', { static: false }) page: ElementRef;
  @ViewChild('printContainer', { static: false }) printContainer: ElementRef;

  public gridColumns = 1;
  private _minTileWidth = 425; // in pixels

  public event: IEvent;
  public isNew = false;
  public isDirty = false;
  public players: IPlayerData[] = [];
  public playerCount = 0;
  public npcCount = 0;
  public paidTotal = 0;
  public commentsCount = 0;
  public uid: string;
  public eventPlayer: IEventPlayer;
  public showRegister = false;
  public printing = false;
  public showOpenRegistration = false;
  public allowRegistration = false;
  public allowComplete = false;
  public allowUnregister = false;
  public eventDescription = '';

  public comments: Observable<IComment[]>;

  private _eventRef: AngularFireObject<IEvent>;
  private _commentsRef: AngularFireList<IComment>;
  private _descUpdate: Subject<string> = new Subject<string>();
  private _descObs: Observable<string> = this._descUpdate.asObservable();

  private _paramsSub: Subscription;
  private _eventSub: Subscription;
  private _characterSub: Subscription;
  private _dialogSub: Subscription;
  private _userSub: Subscription;
  private _eventPlayerSub: Subscription;
  private _dialogCloseSub: Subscription;
  private _charactersSub: Subscription;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _db: AngularFireDatabase,
    private firebaseAuth: AngularFireAuth,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    public pageService: PageService,
    private _skillsService: SkillsService,
    private _spellSlotsService: SpellSlotsService,
    private _spellsService: SpellsService,
    private _professionsService: ProfessionsService,
    private _originsService: OriginsService,
    private _printService: PrintService,
    private _cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if (this._paramsSub) this._paramsSub.unsubscribe();
    this._paramsSub = this._activatedRoute.params.subscribe(params => {
      const id = params['id'] as string;
      if (UuidHelper.isEmpty(id)) {
        this.event = {
          id: UuidHelper.uuid(),
          name: 'New Event',
          description: '',
          portrait: '',
          startDate: moment.utc().toISOString(),
          endDate: moment.utc().toISOString(),
          players: [],
          cost: 0,
          npcCost: 0,
          memberDiscount: 0,
          status: 'Active',
          xpRewarded: false,
          length: 0
        };
        this.isNew = true;
      } else {
        this._eventRef = this._db.object('events/' + id);

        if (this._eventSub) this._eventSub.unsubscribe();
        this._eventSub = this._eventRef.valueChanges().subscribe(event => {
          this.event = event;

          const now = moment.utc();
          const registrationStartMoment = event.registrationStartDate
            ? moment.utc(this.event.registrationStartDate)
            : moment.utc(this.event.startDate).subtract(2, 'week');

          this.showOpenRegistration =
            event &&
            event.status !== 'Completed' &&
            now.isBefore(registrationStartMoment);
          this.allowRegistration = now.isSameOrAfter(registrationStartMoment);
          this.allowComplete = now.isAfter(moment.utc(this.event.endDate));
          this.allowUnregister = now.isBefore(moment.utc(this.event.startDate));
          this.eventDescription = marked(event.description);

          if (this._userSub) this._userSub.unsubscribe();
          this._userSub = this._authService.user.subscribe(user => {
            if (user) {
              this.uid = user.uid;

              if (this._charactersSub) this._charactersSub.unsubscribe();
              this._charactersSub = this._db
                .list<ICharacter>('characters/' + user.uid)
                .valueChanges()
                .pipe(take(1))
                .subscribe(chars => {
                  this.showRegister = chars.length > 0;
                });

              if (this._eventPlayerSub) this._eventPlayerSub.unsubscribe();
              this._eventPlayerSub = this._db
                .object<IEventPlayer>(
                  'events/' + this.event.id + '/players/' + this.uid
                )
                .valueChanges()
                .subscribe(ep => {
                  this.eventPlayer = ep;
                });
            }
          });

          this.players = [];
          this.playerCount = 0;

          this._db
            .object('events/' + this.event.id + '/players')
            .valueChanges()
            .pipe(take(1))
            .subscribe((players: any) => {
              this.playerCount = 0;
              this.npcCount = 0;
              this.paidTotal = 0;
              this.players = [];

              if (players) {
                for (const key in players) {
                  if (!players.hasOwnProperty(key)) continue;

                  const eventPlayer = players[key] as IEventPlayer;
                  if (eventPlayer.status === 'Cancelled') continue;

                  this.paidTotal += eventPlayer.paidValue;

                  // for (let i = 0; i < 40; ++i) { // This for loop is a dummy loop to pad the players to check for speed
                  this._db
                    .object('accounts/' + key + '/profile')
                    .valueChanges()
                    .pipe(take(1))
                    .subscribe((profile: IProfile) => {
                      this._db
                        .object(
                          'characters/' +
                          eventPlayer.playerUid +
                          '/' +
                          eventPlayer.characterId
                        )
                        .valueChanges()
                        .pipe(take(1))
                        .subscribe((character: ICharacter) => {
                          if (character) {
                            // if (EventHelper.isCharacterCounted(this.event, character)) {
                            if (
                              !character.archiveDate ||
                              moment
                                .utc(character.archiveDate)
                                .isAfter(moment.utc(this.event.startDate))
                            ) {
                              if (character.type === 'npc') {
                                this.npcCount++;
                              } else {
                                this.playerCount++;
                              }

                              this.players.push({
                                character: new Character(
                                  character,
                                  this._skillsService,
                                  this._spellSlotsService,
                                  this._spellsService,
                                  this._professionsService,
                                  this._originsService
                                ),
                                profile: profile,
                                eventPlayer: eventPlayer
                              });
                            }
                          }
                        });
                    });
                  // }
                }
              }
            });
        });
      }
    });
  }

  public ngAfterViewInit(): void {
    this.calculateGridColumns();
  }

  public ngOnDestroy(): void {
    if (this._paramsSub) this._paramsSub.unsubscribe();
    if (this._characterSub) this._characterSub.unsubscribe();
    if (this._dialogSub) this._dialogSub.unsubscribe();
    if (this._userSub) this._userSub.unsubscribe();
    if (this._eventPlayerSub) this._eventPlayerSub.unsubscribe();
    if (this._dialogCloseSub) this._dialogCloseSub.unsubscribe();
    if (this._charactersSub) this._charactersSub.unsubscribe();
  }

  private calculateGridColumns(): void {
    const width = parseInt(this.page.nativeElement['offsetWidth'], 10);
    setTimeout(() => {
      this.gridColumns = Math.trunc(width / this._minTileWidth);

      if (this.gridColumns < 1) {
        this.gridColumns = 1;
      }
    }, 1);
  }

  private canSave(): boolean {
    if (!this.isDirty) return false;

    return true;
  }

  public onClickSave(): void {
    if (!this.canSave()) return;

    let savePromise: Promise<any>;
    if (this.isNew) {
      this._eventRef = this._db.object('events/' + this.event.id);
      savePromise = this._eventRef.set(this.event);
    } else {
      savePromise = this._eventRef.update(this.event);
    }

    savePromise.then(() => {
      this.isDirty = false;
      this._snackBar.open('Event saved!', 'Close', {
        duration: 2000
      });
    });
  }

  public onClickDelete(): void {
    const dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '400px',
      height: '200px',
      data: {
        title: 'Delete ' + this.event.name + '?',
        text:
          'Are you sure you wish to delete this event? You cannot undo this.'
      },
      panelClass: 'crux-dialog'
    });

    this._dialogSub = dialogRef.afterClosed().subscribe(result => {
      // Yes, we have confirmed a delete request
      if (result) {
        this._eventRef.remove().then(() => {
          this._router.navigate(['home']);
        });
      }
    });
  }

  public onClickRegister(): void {
    const data: IEventRegisterData = {
      uid: this.uid,
      event: this.event,
      eventPlayer: this.eventPlayer
    };

    const dialogRef = this._dialog.open(EventRegisterDialogComponent, {
      width: '300px',
      height: '500px',
      data: data,
      panelClass: 'crux-dialog'
    });

    if (this._dialogSub) this._dialogSub.unsubscribe();
    this._dialogSub = dialogRef
      .afterClosed()
      .subscribe((result: IEventRegisterDataResult) => {
        // if (result) {
        //   this.event = result.event;
        //   this.eventPlayer = result.eventPlayer;
        // }
      });
  }

  public onClickUnregister(): void {
    const data: IEventRegisterData = {
      uid: this.uid,
      event: this.event,
      eventPlayer: this.eventPlayer
    };

    const dialogRef = this._dialog.open(EventUnregisterDialogComponent, {
      width: '300px',
      height: '200px',
      data: data,
      panelClass: 'crux-dialog'
    });

    if (this._dialogSub) this._dialogSub.unsubscribe();
    this._dialogSub = dialogRef
      .afterClosed()
      .subscribe((result: IEventRegisterDataResult) => {
        // if (result) {
        //   this.event = result.event;
        //   this.eventPlayer = result.eventPlayer;
        // }
      });
  }

  public onClickCancelPlayerRegistration(eventPlayer: IEventPlayer): void {
    const data: IEventRegisterData = {
      uid: eventPlayer.playerUid,
      event: this.event,
      eventPlayer: eventPlayer
    };

    const dialogRef = this._dialog.open(EventUnregisterDialogComponent, {
      width: '300px',
      height: '200px',
      data: data,
      panelClass: 'crux-dialog'
    });

    // this._dialogSub = dialogRef.afterClosed().subscribe((result: IEventRegisterDataResult) => {
    // });
  }

  public onClickMarkPaid(eventPlayer: IEventPlayer): void {
    const eventPlayerRef = this._db.object(
      'events/' + this.event.id + '/players/' + eventPlayer.playerUid
    );
    eventPlayer.datePaid = moment.utc().toISOString();
    eventPlayer.paidValue = this.event.cost;
    eventPlayerRef.update(eventPlayer);
  }

  public onClickOpenCharacter(eventPlayer: IEventPlayer): void {
    this._router.navigate([
      'characters',
      eventPlayer.characterId,
      eventPlayer.playerUid
    ]);
  }

  public onClickPay(e: any): void {
    const dialogRef = this._dialog.open(PayDialogComponent, {
      width: '300px',
      height: '200px',
      data: { event: this.event, eventPlayer: this.eventPlayer },
      panelClass: 'crux-dialog'
    });

    if (this._dialogSub) this._dialogSub.unsubscribe();
    this._dialogSub = dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.eventPlayer = result;
      }
    });
  }

  public onClickEndEvent(): void {
    this.event.status = 'Completed';
    this._eventRef.update(this.event);
  }

  public onClickRewardEvent(): void {
    const dialogRef = this._dialog.open(XpAwardDialogComponent, {
      width: '400px',
      height: '500px',
      panelClass: 'crux-dialog'
    });

    if (this._dialogCloseSub) this._dialogCloseSub.unsubscribe();
    this._dialogCloseSub = dialogRef.afterClosed().subscribe(result => {
      // Yes, we have confirmed a delete request
      if (result) {
        this.event.xpRewarded = true;

        const activePlayers = this.players.filter(
          p => p.eventPlayer.status !== 'Cancelled'
        );
        activePlayers.forEach(playerData => {
          const characterRef = this._db.object(
            'characters/' +
            playerData.eventPlayer.playerUid +
            '/' +
            playerData.eventPlayer.characterId
          );

          playerData.character.adjustXp(result);
          playerData.character.data.isLocked = true;
          characterRef.update(playerData.character.data);
        });

        this._eventRef.update(this.event);
      }
    });
  }

  public onClickPrintAll(): void {
    this.printing = true;
    this._cdr.detectChanges();

    setTimeout(() => {
      this._printService
        .print(this.event.name + ' Character Print', this.printContainer)
        .then(() => {
          this.printing = false;
        });
    }, 100);
  }

  public onKeyup(): void {
    this.isDirty = true;
  }

  public handleDateChanged(isoDate: string, property: string): void {
    this.isDirty = true;

    if (isoDate) {
      this.event[property] = isoDate;
    }
  }

  public handleCommentsCount(count: number): void {
    this.commentsCount = count;
  }

  public onClickOpenRegistration(): void {
    const dialogRef = this._dialog.open(DeleteDialogComponent, {
      width: '400px',
      height: '200px',
      data: {
        title: 'Open ' + this.event.name + ' for Registration?',
        text: 'Are you sure you wish to allow registrations for this event?'
      },
      panelClass: 'crux-dialog'
    });

    if (this._dialogSub) this._dialogSub.unsubscribe();
    this._dialogSub = dialogRef.afterClosed().subscribe(result => {
      // Yes, we have confirmed a open request
      if (result) {
        this.event.registrationStartDate = moment.utc().toISOString();
        this._eventRef.update(this.event).then(() => {
          this.showOpenRegistration = false;
        });
      }
    });
  }
}
