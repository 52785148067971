import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { IDay, IWeek } from '../../interfaces/calendar';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() date: string;
  @Input() disabled: boolean;

  @Output() dateChanged: EventEmitter<any> = new EventEmitter();

  public weeks: IWeek[] = [];
  public currentDate: moment.Moment;
  public currentMonth: moment.Moment;
  public monthName = 'January';
  public year = 1900;
  public titleText = 'Date';
  public time = '12:00';

  public mask = [/[0-9]/, /\d/, ':', /\d/, /\d/];

  constructor(private _cdr: ChangeDetectorRef) { }

  ngOnInit() { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['title'] && changes['title'].currentValue) {
      this.titleText = changes['title'].currentValue;
    }

    if (changes['date'] && changes['date'].currentValue) {
      const value = moment(changes['date'].currentValue);
      this.time = this.zeroPad(value.hours()) + ':' + this.zeroPad(value.minutes());

      this.currentDate = moment(changes['date'].currentValue).startOf('day');
      this.currentMonth = moment(changes['date'].currentValue).startOf('month');
      this.calculate();
    }
  }

  private calculate(): void {
    const today = moment().startOf('day');
    this.monthName = this.currentMonth.format('MMMM');
    this.year = this.currentMonth.year();
    const dayOfWeek = this.currentMonth.day();

    let start = moment(this.currentMonth).subtract({ days: dayOfWeek + 1 });

    this.weeks = [];
    for (let k = 0; k < 5; ++k) {
      const week: IWeek = { days: [] };
      for (let i = 0; i < 7; ++i) {
        start = start.add({ days: 1 });
        const value = moment(start);

        week.days.push({
          value: value,
          text: value.date().toString(),
          isCurrentMonth: value.isSame(this.currentMonth, 'month'),
          isSelected: value.isSame(this.currentDate),
          isToday: value.isSame(today),
          events: []
        });
      }

      this.weeks.push(week);
    }
  }

  public onClickDay(day: IDay): void {
    if (this.disabled) return;

    this.date = day.value.toISOString();

    this.currentDate = moment(this.date).startOf('day');
    this.currentMonth = moment(this.date).startOf('month');
    this.calculate();

    this.dateChanged.emit(this.date);
  }

  public onKeyup(): void {
    if (this.disabled) return;

    this.dateChanged.emit();
  }

  public onBlur(): void {
    if (this.disabled) return;

    const values = this.time.split(':');
    const hours = values.length >= 1 ? values[0].replace(/_/g, '0') : '00';
    const minutes = values.length > 1 ? values[1].replace(/_/g, '0') : '00';
    const dateValue = moment(this.date);
    dateValue.hours(+hours);
    dateValue.minutes(+minutes);
    console.log(dateValue.toLocaleString());

    this.date = dateValue.toISOString();
    this.dateChanged.emit(this.date);
  }

  private zeroPad(value: number, size?: number) {
    let s = String(value);
    while (s.length < (size || 2)) {
      s = '0' + s;
    }
    return s;
  }

  public onClickPrevMonth(): void {
    if (this.disabled) return;

    this.currentMonth.subtract({ months: 1 });
    this.calculate();
  }

  public onClickNextMonth(): void {
    if (this.disabled) return;

    this.currentMonth.add({ months: 1 });
    this.calculate();
  }
}
