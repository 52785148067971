import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Router } from '@angular/router';
import { IAccountInfo, IProfile } from './../../../interfaces/accountInfo';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { IAccount } from '../../../interfaces/account';
import { MAIN_CHAPTER_GUID } from '../../../services/chapter.service';

@Component({
  selector: 'app-account-selector',
  templateUrl: './selector.component.html',
  styleUrls: ['./selector.component.scss']
})
export class AccountSelectorComponent implements OnInit, OnChanges {
  @Input() account: IAccount;
  @Input() profile: IProfile;
  @Output() selected: EventEmitter<IAccountInfo> = new EventEmitter();
  @Output() clickCharacter: EventEmitter<IAccountCharacterClickData> = new EventEmitter();
  @ViewChild('editBux') editBux;
  @ViewChild('editTrains') editTrains;
  @ViewChild('editEvents') editEvents;

  public MAIN_CHAPTER_GUID = MAIN_CHAPTER_GUID;
  public originName: string;
  public lastPaidDate: Date;
  public editingType = 'none';
  public editValue = 0;

  constructor(private _router: Router, private _db: AngularFireDatabase) { }

  ngOnInit() { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['account'] && changes['account'].currentValue) {
      // this._originsService.get(this.account.data.originId).then(origin => {
      //   this.originName = origin.name;
      // });
      if (this.account.info.membership && this.account.info.membership.lastPaidDate) {
        this.lastPaidDate = new Date(this.account.info.membership.lastPaidDate);
      } else {
        this.lastPaidDate = null;
      }
    }
  }

  public onClickAccount(): void {
    this.selected.emit(this.account.info);
  }

  public onClickCharacter(characterId: string, account: IAccount): void {
    const data: IAccountCharacterClickData = {
      characterId: characterId,
      uid: account.uid
    };

    this.clickCharacter.emit(data);
  }

  public onClickEditValue(event: any, type: string): void {
    event.preventDefault();
    event.stopPropagation();

    this.editingType = type;
    switch (type) {
      case 'bux': this.editValue = this.account.info.profile.cruxBucks || 0; break;
      case 'trains': this.editValue = this.account.info.profile.freeTrainings || 0; break;
      case 'events': this.editValue = this.account.info.profile.compEvents || 0; break;
    }

    setTimeout(() => {
      let nativeElement = null;
      switch (type) {
        case 'bux': nativeElement = this.editBux.nativeElement; break;
        case 'trains': nativeElement = this.editTrains.nativeElement; break;
        case 'events': nativeElement = this.editEvents.nativeElement; break;
      }

      nativeElement.focus();
      nativeElement.select();
    }, 10);
  }

  public onBlurEditValue(event: any): void {
    const accountProfileRef = this._db.object<any>('accounts/' + this.account.uid + '/profile');

    switch (this.editingType) {
      case 'bux':
        this.account.info.profile.cruxBucks = this.editValue;
        accountProfileRef.update({ cruxBucks: this.editValue });
        break;
      case 'trains': this.account.info.profile.freeTrainings = this.editValue;
        accountProfileRef.update({ freeTrainings: this.editValue });
        break;
      case 'events': this.account.info.profile.compEvents = this.editValue;
        accountProfileRef.update({ compEvents: this.editValue });
        break;
    }

    this.editingType = 'none';
    this.editValue = 0;
  }
}

export interface IAccountCharacterClickData {
  characterId: string;
  uid: string;
}
