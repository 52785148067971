import { TooltipPosition } from '@angular/material/tooltip';
import { Overlay, OverlayRef, HorizontalConnectionPos, VerticalConnectionPos, OverlayConnectionPosition, OriginConnectionPosition, OverlayConfig, ConnectionPositionPair } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { MobileTooltipActionType } from './../enums/mobileTooltipActionType';
import { Subscription, merge } from 'rxjs';
import { PageService } from './../services/page.service';
import { ISkill } from './../interfaces/skill';
import { Directive, ElementRef, OnDestroy, ViewContainerRef, NgZone, Input, Renderer2, Optional, Output, EventEmitter } from '@angular/core';

import { AppTooltipComponent } from '../components/tooltip/app-tooltip.component';
import { getMatTooltipInvalidPositionError } from './tooltip-functions';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[appTooltip]',
  exportAs: 'appTooltip'
})
export class AppTooltipDirective implements OnDestroy {
  private _overlayRef: OverlayRef | null;
  private _tooltipInstance: AppTooltipComponent | null;

  /** Current position of the tooltip. */
  private _position: TooltipPosition;

  private _manualListeners = new Map<string, Function>();

  /** Allows the user to define the position of the tooltip relative to the parent element */
  @Input('appTooltipPosition')
  get position(): TooltipPosition {
    return this._position;
  }
  set position(value: TooltipPosition) {
    if (value !== this._position) {
      this._position = value;

      // When the overlay's position can be dynamically changed, do not destroy the tooltip.
      if (this._tooltipInstance) {
        this._disposeTooltip();
      }
    }
  }

  private _type: string;
  /** The message to be displayed in the tooltip */
  @Input('appTooltipType')
  get type() {
    return this._type;
  }
  set type(value: any) {
    this._type = value;

    if (!this._type && this._isTooltipVisible()) {
      this.hide(0);
    } else {
      this._updateTooltipMessage();
    }
  }

  private _target: any;
  /** The message to be displayed in the tooltip */
  @Input('appTooltipTarget')
  get target() {
    return this._target;
  }
  set target(value: any) {
    this._target = value;

    if (!this._target && this._isTooltipVisible()) {
      this.hide(0);
    } else {
      this._updateTooltipMessage();
    }
  }

  private _data: any;
  /** The message to be displayed in the tooltip */
  @Input('appTooltipData')
  get data() {
    return this._data;
  }
  set data(value: any) {
    this._data = value;

    if (!this._data && this._isTooltipVisible()) {
      this.hide(0);
    } else {
      this._updateTooltipMessage();
    }
  }

  private _width: number;
  @Input('appTooltipWidth')
  get width() {
    return this._width;
  }
  set width(value: number) {
    this._width = value;
  }

  private _height: number;
  @Input('appTooltipHeight')
  get height() {
    return this._height;
  }
  set height(value: number) {
    this._height = value;
  }

  private _mobileTooltipActionType = MobileTooltipActionType.None;
  @Input('appMobileTooltipActionType')
  get mobileTooltipActionType() {
    return this._mobileTooltipActionType;
  }
  set mobileTooltipActionType(value: MobileTooltipActionType) {
    this._mobileTooltipActionType = value;
  }

  @Output() buy: EventEmitter<any> = new EventEmitter();
  @Output() sell: EventEmitter<any> = new EventEmitter();
  private _buySub: Subscription;
  private _sellSub: Subscription;

  /** The default delay in ms before showing the tooltip after show is called */
  // tslint:disable-next-line:no-input-rename
  @Input('appTooltipShowDelay') showDelay = 100;

  /** The default delay in ms before hiding the tooltip after hide is called */
  // tslint:disable-next-line:no-input-rename
  @Input('appTooltipHideDelay') hideDelay = 100;

  constructor(
    private _overlay: Overlay,
    private _elementRef: ElementRef,
    private _viewContainerRef: ViewContainerRef,
    private _ngZone: NgZone,
    private _focusMonitor: FocusMonitor,
    private _pageService: PageService,
    @Optional() private _dir: Directionality
  ) {
    const element: HTMLElement = _elementRef.nativeElement;

    this._manualListeners.set('mouseenter', () => this.show());
    this._manualListeners.set('mouseleave', () => this.hide());

    this._manualListeners.forEach((listener, event) => _elementRef.nativeElement.addEventListener(event, listener));

    this._focusMonitor.monitor(element, false).subscribe(origin => {
      // Note that the focus monitor runs outside the Angular zone.
      if (!origin) {
        _ngZone.run(() => this.hide(0));
      } else if (origin !== 'program') {
        _ngZone.run(() => this.show());
      }
    });
  }

  public ngOnDestroy(): void {
    if (this._tooltipInstance) {
      this._disposeTooltip();
    }

    this._manualListeners.forEach((listener, event) => {
      this._elementRef.nativeElement.removeEventListener(event, listener);
    });

    this._manualListeners.clear();
    this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
  }

  /** Shows the tooltip after the delay in ms, defaults to tooltip-delay-show or 0ms if no input */
  show(delay: number = this.showDelay): void {
    const bodyRect = document.body.getBoundingClientRect();
    const iconRect = this._elementRef.nativeElement.getBoundingClientRect() as ClientRect;
    const maxRightPos = iconRect.right + (this._width ? this._width : 100) + 25;
    if (maxRightPos >= bodyRect.width) {
      this.position = 'left';
    }

    if (!this._tooltipInstance) {
      this._createTooltip();
    }

    this._updateTooltipMessage();
    if (this._tooltipInstance) {
      this._tooltipInstance.show(this._position, delay);
    }
  }

  /** Hides the tooltip after the delay in ms, defaults to tooltip-delay-hide or 0ms if no input */
  hide(delay: number = this.hideDelay): void {
    if (this._tooltipInstance && !this._pageService.isMobile) {
      this._tooltipInstance.hide(delay);
    }
  }

  /** Returns true if the tooltip is currently visible to the user */
  private _isTooltipVisible(): boolean {
    return !!this._tooltipInstance && this._tooltipInstance.isVisible();
  }

  /** Create the tooltip to display */
  private _createTooltip(): void {
    const overlayRef = this._createOverlay();
    const portal = new ComponentPortal(AppTooltipComponent, this._viewContainerRef);

    this._tooltipInstance = overlayRef.attach(portal).instance;

    if (this._tooltipInstance) {
      this._buySub = this._tooltipInstance.onBuy.subscribe(() => this.buy.emit());
      this._sellSub = this._tooltipInstance.onSell.subscribe(() => this.sell.emit());

      // Dispose of the tooltip when the overlay is detached.
      merge(this._tooltipInstance.afterHidden(), overlayRef.detachments()).subscribe(() => {
        // Check first if the tooltip has already been removed through this components destroy.
        if (this._tooltipInstance) {
          this._disposeTooltip();
        }
      });
    }
  }

  /** Create the overlay config and position strategy */
  private _createOverlay(): OverlayRef {
    const origin = this._getOrigin();
    const overlay = this._getOverlayPosition();

    // Create connected position strategy that listens for scroll events to reposition.
    const strategy = this._overlay
      .position()
      .flexibleConnectedTo(this._elementRef)
      .withPositions([
        new ConnectionPositionPair(
          { originX: 'end', originY: 'bottom' },
          { overlayX: 'start', overlayY: 'top' },
        ),
        new ConnectionPositionPair(
          { originX: 'end', originY: 'bottom' },
          { overlayX: 'start', overlayY: 'bottom' },
        ),
        new ConnectionPositionPair(
          { originX: 'start', originY: 'bottom' },
          { overlayX: 'end', overlayY: 'top' },
        ),
      ]).withPush(false);
    // .connectedTo(this._elementRef, origin.main, overlay.main)
    // .withFallbackPosition(origin.fallback, overlay.fallback);

    // const scrollableAncestors = this._scrollDispatcher.getAncestorScrollContainers(this._elementRef);

    // strategy.withScrollableContainers(scrollableAncestors);

    // strategy.onPositionChange.subscribe(change => {
    //   if (this._tooltipInstance) {
    //     if (change.scrollableViewProperties.isOverlayClipped && this._tooltipInstance.isVisible()) {
    //       // After position changes occur and the overlay is clipped by
    //       // a parent scrollable then close the tooltip.
    //       this._ngZone.run(() => this.hide(0));
    //     } else {
    //       // Otherwise recalculate the origin based on the new position.
    //       this._tooltipInstance._setTransformOrigin(change.connectionPair);
    //     }
    //   }
    // });

    const config = new OverlayConfig({
      positionStrategy: strategy,
      width: this._width,
      height: this._height
    });

    this._overlayRef = this._overlay.create(config);

    return this._overlayRef;
  }

  /** Disposes the current tooltip and the overlay it is attached to */
  private _disposeTooltip(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
    }

    this._tooltipInstance = null;
    if (this._buySub) this._buySub.unsubscribe();
    if (this._sellSub) this._sellSub.unsubscribe();
  }

  /**
   * Returns the origin position and a fallback position based on the user's position preference.
   * The fallback position is the inverse of the origin (e.g. `'below' -> 'above'`).
   */
  _getOrigin(): { main: OriginConnectionPosition; fallback: OriginConnectionPosition } {
    const isDirectionLtr = !this._dir || this._dir.value === 'ltr';
    let position: OriginConnectionPosition;

    if (this.position === 'above' || this.position === 'below') {
      position = { originX: 'end', originY: this.position === 'above' ? 'top' : 'bottom' };
    } else if (this.position === 'left' || (this.position === 'before' && isDirectionLtr) || (this.position === 'after' && !isDirectionLtr)) {
      position = { originX: 'start', originY: 'bottom' };
    } else if (this.position === 'right' || (this.position === 'after' && isDirectionLtr) || (this.position === 'before' && !isDirectionLtr)) {
      position = { originX: 'end', originY: 'bottom' };
    } else {
      throw getMatTooltipInvalidPositionError(this.position);
    }

    const { x, y } = this._invertPosition(position.originX, position.originY);

    return {
      main: position,
      fallback: { originX: x, originY: y }
    };
  }

  /** Returns the overlay position and a fallback position based on the user's preference */
  _getOverlayPosition(): { main: OverlayConnectionPosition; fallback: OverlayConnectionPosition } {
    const isLtr = !this._dir || this._dir.value === 'ltr';
    let position: OverlayConnectionPosition;

    if (this.position === 'above') {
      position = { overlayX: 'start', overlayY: 'bottom' };
    } else if (this.position === 'below') {
      position = { overlayX: 'start', overlayY: 'top' };
    } else if (this.position === 'left' || (this.position === 'before' && isLtr) || (this.position === 'after' && !isLtr)) {
      position = { overlayX: 'end', overlayY: 'top' };
    } else if (this.position === 'right' || (this.position === 'after' && isLtr) || (this.position === 'before' && !isLtr)) {
      position = { overlayX: 'start', overlayY: 'top' };
    } else {
      throw getMatTooltipInvalidPositionError(this.position);
    }

    const { x, y } = this._invertPosition(position.overlayX, position.overlayY);

    return {
      main: position,
      fallback: { overlayX: x, overlayY: y }
    };
  }

  /** Updates the tooltip message and repositions the overlay according to the new message length */
  private _updateTooltipMessage() {
    // Must wait for the message to be painted to the tooltip so that the overlay can properly
    // calculate the correct positioning based on the size of the text.
    if (this._tooltipInstance) {
      this._tooltipInstance.type = this.type;
      this._tooltipInstance.target = this.target;
      this._tooltipInstance.data = this.data;

      this._tooltipInstance.mobileTooltipActionType = this.mobileTooltipActionType;

      this._tooltipInstance._markForCheck();

      this._ngZone.onMicrotaskEmpty
        .asObservable()
        .pipe(take(1))
        .subscribe(() => {
          if (this._tooltipInstance) {
            if (this._overlayRef) {
              this._overlayRef.updatePosition();
            }
          }
        });
    }
  }

  /** Inverts an overlay position. */
  private _invertPosition(x: HorizontalConnectionPos, y: VerticalConnectionPos) {
    if (this.position === 'above' || this.position === 'below') {
      if (y === 'top') {
        y = 'bottom';
      } else if (y === 'bottom') {
        y = 'top';
      }
    } else {
      if (x === 'end') {
        x = 'start';
      } else if (x === 'start') {
        x = 'end';
      }
    }

    return { x, y };
  }
}
