import { ChapterService } from './../../../services/chapter.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { MembershipDialogComponent } from './../../membership-dialog/membership-dialog.component';
import { AccountService } from './../../../services/account.service';
import { IAccountInfo } from './../../../interfaces/accountInfo';
import { AuthService } from './../../../services/auth.service';
import { PageService } from './../../../services/page.service';
import { INavTarget } from './../../../interfaces/navTarget';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireObject, AngularFireDatabase } from '@angular/fire/compat/database';
import FirebaseAuth from 'firebase/auth';
import { PaypalHelper } from '../../../helpers/paypal.helper';
import { MAIN_CHAPTER_GUID } from '../../../services/chapter.service';
import { IChapter } from '../../../interfaces/chapter';

declare let paypal: any;

@Component({
  selector: 'app-drop-menu-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['../../section.scss', '../../tiles.scss', '../../button.scss', './avatar.component.scss']
})
export class DropMenuAvatarComponent implements OnInit, OnDestroy {
  @Output() close: EventEmitter<any> = new EventEmitter();

  public accountInfo: IAccountInfo;
  public profileDirty = false;
  public subscriptionStatus = '';
  public membershipTypeText = 'None';
  public nextBillingDate: Date;
  public lastPaidDate: Date;
  public editingChapter = false;
  public canEditChapter = true;
  public allChapters: IChapter[] = [];
  public months = [
    { value: 1, name: 'January' },
    { value: 2, name: 'February' },
    { value: 3, name: 'March' },
    { value: 4, name: 'April' },
    { value: 5, name: 'May' },
    { value: 6, name: 'June' },
    { value: 7, name: 'July' },
    { value: 8, name: 'August' },
    { value: 9, name: 'September' },
    { value: 10, name: 'October' },
    { value: 11, name: 'November' },
    { value: 12, name: 'December' }
  ];
  public editingDetails = false;

  private _user: FirebaseAuth.User;
  private _accountRef: AngularFireObject<IAccountInfo>;
  private _dialogSub: Subscription;
  private _chaptersSub: Subscription;
  private _oldHomeChapterId: string;

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _accountService: AccountService,
    private _db: AngularFireDatabase,
    private _dialog: MatDialog,
    private _chapterService: ChapterService,
    public pageService: PageService,
  ) { }

  ngOnInit() {
    this._accountService.accountInfo.subscribe(info => {
      this.accountInfo = info;
      if (!this.lastPaidDate) {
        this.lastPaidDate = new Date(info.membership.lastPaidDate);
      }

      if (!this.accountInfo.profile.homeChapterId) {
        this.accountInfo.profile.homeChapterId = MAIN_CHAPTER_GUID;
      }

      if (this.accountInfo.profile.canEditChapter !== undefined) {
        this.canEditChapter = this.accountInfo.profile.canEditChapter;
      }
    });

    this._accountService.paypalSubscription.subscribe(paypalSub => {
      if (paypalSub) {
        const isActive = paypalSub.status === 'ACTIVE';
        this.subscriptionStatus = isActive ? 'Active' : 'None';

        if (isActive) {
          this.membershipTypeText = PaypalHelper.getMembershipNameByPlanId(paypalSub.plan_id);
        } else {
          this.membershipTypeText = 'None';
        }

        if (paypalSub.billing_info) {
          this.nextBillingDate = new Date(paypalSub.billing_info.next_billing_time);
          this.lastPaidDate = new Date(paypalSub.billing_info.last_payment.time);
        }
      }
    });

    this._authService.user.subscribe(user => {
      this._user = user;
    });

    this._chaptersSub = this._chapterService.isLoaded.subscribe(chaptersLoaded => {
      if (chaptersLoaded) {
        this.allChapters = this._chapterService.chapters;
      }
    });
  }

  public ngOnDestroy(): void {
    if (this._dialogSub) this._dialogSub.unsubscribe();
    if (this._chaptersSub) this._chaptersSub.unsubscribe();
  }

  public onClickNav(navTarget: INavTarget): void {
    if (navTarget.useList) {
      this.pageService.setTarget(navTarget.target);
      return;
    }

    this._router.navigate(['/' + navTarget.target]);
    this.close.emit();
  }

  public onClickMembership(): void {
    const dialogRef = this._dialog.open(MembershipDialogComponent, {
      width: '350px',
      height: '300px',
      data: {},
      panelClass: 'crux-dialog'
    });

    this._dialogSub = dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // Membership close here
      }
    });
  }

  public onClickLogout(): void {
    this._authService.logout();
    this.close.emit();
    this._router.navigate(['/home']);
  }

  public onClickSaveAccountInfo(): void {
    this._accountRef = this._db.object('accounts/' + this._user.uid);
    this._accountRef.update(this.accountInfo).then(() => {
      this.profileDirty = false;
    });
  }

  public onClickClose(): void {
    this.close.emit();
  }

  public markProfileDirty(): void {
    this.profileDirty = true;
  }

  public onToggleEditChapter(event: any, value: boolean): void {
    event.preventDefault();
    event.stopPropagation();

    this.editingChapter = value;
    if (value) {
      this._oldHomeChapterId = this.accountInfo.profile.homeChapterId;
    }
  }

  public handleChapterChanged(event: any): void {
    const chapterId = event.target.value;
    this.accountInfo.profile.homeChapterId = chapterId;
    this.accountInfo.profile.canEditChapter = false;
  }

  public handleChangeBirthMonth(event: any): void {
    const birthMonth = parseInt(event.target.value, 10);
    this.accountInfo.profile.birthMonth = birthMonth;
    this.markProfileDirty();
  }

  public onCancelEditChapter(event: any): void {
    event.preventDefault();
    event.stopPropagation();

    this.editingChapter = false;
    this.accountInfo.profile.homeChapterId = this._oldHomeChapterId;

    this._oldHomeChapterId = null;
    this.canEditChapter = true;
  }

  public onAcceptEditChapter(event: any): void {
    this.onToggleEditChapter(event, false);
    this.markProfileDirty();
    this.canEditChapter = false;
  }

  public getMonthName(value: number): string {
    const month = this.months.find((m) => m.value === value);
    return month.name;
  }

  public onToggleEditDetails(event: any, value: boolean): void {
    event.preventDefault();
    event.stopPropagation();

    this.editingDetails = value;
  }
}
