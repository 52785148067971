import { MatDialog } from '@angular/material/dialog';
import { IAccountInfo, IProfile } from './../../../interfaces/accountInfo';
import { AngularFireDatabase, snapshotChanges } from '@angular/fire/compat/database';
import { AuthService } from './../../../services/auth.service';
import { PageService } from './../../../services/page.service';
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { ICharacter } from './../../../interfaces/character';
import { IAccount } from '../../../interfaces/account';
import { IAccountCharacterClickData } from '../../accounts/selector/selector.component';
import { Subject, Observable, Subscription } from 'rxjs';
import { take, debounceTime } from 'rxjs/operators';
import moment from 'moment';

@Component({
  selector: 'app-drop-menu-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['../../tiles.scss', '../../button.scss', './accounts.component.scss']
})
export class DropMenuAccountsComponent implements OnInit, OnDestroy {
  @Output()
  close: EventEmitter<any> = new EventEmitter();
  @Output()
  preventClose: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('accountsContainer', { static: false })
  accountsElement: ElementRef;
  @ViewChild('search', { static: false })
  searchTextElementRef: ElementRef;

  public accounts: IAccount[] = [];
  public accountColumns = 1;
  public viewStatus = 'Active';
  public maxAccounts = 0;
  public emails = '';
  public emailsCopied = false;
  public showSearch = false;
  public searchText = '';
  public selectedAccount = '';
  public selectedAccountIsAdmin = false;
  public selectedAccountCanEditChapters = true;

  private _allAccounts: IAccount[] = [];
  private _minAccountWidth = 250; // In pixels
  private _accountsSub: Subscription;
  private _authSub: Subscription;
  private _charactersSub: Subscription;
  private _uid: string;

  private _filterUpdate: Subject<string> = new Subject<string>();
  private _filterObs: Observable<string> = this._filterUpdate.asObservable();

  constructor(
    private _router: Router,
    private _authService: AuthService,
    public pageService: PageService,
    private _db: AngularFireDatabase,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    this._authSub = this._authService.user.subscribe(user => {
      if (user) {
        this._uid = user.uid;
        this._accountsSub = this._db
          .object<any>('accounts')
          .valueChanges()
          .pipe(take(1))
          .subscribe(results => {
            this._charactersSub = this._db
              .object<any>('characters')
              .valueChanges()
              .pipe(take(1))
              .subscribe(characters => {
                this._allAccounts = [];

                for (const key in results) {
                  if (results.hasOwnProperty(key)) {
                    const accountInfo = results[key];

                    const account: IAccount = {
                      uid: key,
                      info: accountInfo,
                      characterList: [],
                      characters: {}
                    };

                    const accountCharacters = characters[key];
                    for (const property in accountCharacters) {
                      if (accountCharacters.hasOwnProperty(property)) {
                        account.characterList.push(property);
                        account.characters[property] = accountCharacters[property];
                      }
                    }

                    this._allAccounts.push(account);
                  }
                }

                this.accounts = this._allAccounts;
                this.updateEmailList();
                this.calculateAccountColumns();
              });
          });
      }
    });

    this._filterObs.pipe(debounceTime(300)).subscribe(value => {
      const filter = value.trim().toLowerCase();
      if (filter.length > 1) {
        this.accounts = this._allAccounts.filter(account => {
          if (filter === ':sub' && account.info.membership && account.info.membership.isActive) {
            return true;
          }

          if (filter === ':admin' && account.info.roles && account.info.roles.indexOf('admin') > -1) {
            return true;
          }

          const currentMonth = new Date().getMonth() + 1;
          if (filter === ':bd' && account.info?.profile?.birthMonth === currentMonth) {
            return true;
          }

          if ((account.info.profile.displayName && account.info.profile.displayName.toLowerCase().indexOf(filter) > -1)
            || (account.info.profile.email && account.info.profile.email.toLowerCase().indexOf(filter) > -1)) {
            return true;
          }

          for (let i = 0; i < account.characterList.length; ++i) {
            const id = account.characterList[i];
            const character = account.characters[id] as ICharacter;
            if (character.name.toLowerCase().indexOf(filter) > -1) {
              return true;
            }
          }

          return false;
        });
      } else {
        this.accounts = this._allAccounts;
      }

      this.selectedAccount = '';
      this.selectedAccountIsAdmin = false;
      this.updateEmailList();
    });
  }

  public ngOnDestroy(): void {
    if (this._authSub) this._authSub.unsubscribe();
    if (this._accountsSub) this._accountsSub.unsubscribe();
  }

  private calculateAccountColumns(): void {
    const currentWidth = parseInt(this.accountsElement.nativeElement['offsetWidth'], 10);
    this.accountColumns = Math.trunc(currentWidth / this._minAccountWidth);
  }

  public onResizeAccounts(): void {
    this.calculateAccountColumns();
  }

  public onClickAccount(account: IAccount): void {
    // this._router.navigate(['accounts', account.data.id, this._uid]);
    // this.close.emit();
    this.selectedAccount = account.uid;
    this.selectedAccountIsAdmin = account.info.roles !== undefined && account.info.roles.indexOf('admin') > -1;
    this.selectedAccountCanEditChapters = account.info.profile.canEditChapter !== undefined ? account.info.profile.canEditChapter : true;
  }

  public onClickCharacter(data: IAccountCharacterClickData): void {
    this._router.navigate(['characters', data.characterId, data.uid]);
    this.close.emit();
  }

  private updateEmailList(): void {
    this.emails = '';
    this.accounts.forEach(account => {
      this.emails += account.info.profile.email + ', ';
    });

    this.emails = this.emails.substring(0, this.emails.length - 2);
  }

  public onCopyEmails(): void {
    this.emailsCopied = true;

    setTimeout(() => {
      this.emailsCopied = false;
    }, 5000);
  }

  public toggleSearch(): void {
    this.showSearch = !this.showSearch;
    if (this.showSearch) {
      this.searchTextElementRef.nativeElement.focus();
    }
  }

  public onSearchKeyup(value: string): void {
    this._filterUpdate.next(value);
  }

  public toggleAdminRole(): void {
    if (this.selectedAccount.length === 0) return;

    const account = this._allAccounts.find(a => a.uid === this.selectedAccount);
    const accountRolesRef = this._db.object<string[]>('accounts/' + this.selectedAccount + '/roles');
    accountRolesRef
      .valueChanges()
      .pipe(take(1))
      .subscribe(results => {
        if (this.selectedAccountIsAdmin) {
          const index = results.indexOf('admin');
          results.splice(index, 1);
        } else {
          if (!results) results = [];
          results.push('admin');
        }

        if (results.length === 0) {
          accountRolesRef.remove();
        } else {
          accountRolesRef.set(results);
        }

        account.info.roles = results;
        this.selectedAccountIsAdmin = !this.selectedAccountIsAdmin;
      });
  }

  public onClickAllowChapterChange(): void {
    if (this.selectedAccount.length === 0) return;

    const account = this._allAccounts.find(a => a.uid === this.selectedAccount);
    const accountProfileRef = this._db.object<IProfile>('accounts/' + this.selectedAccount + '/profile');
    accountProfileRef
      .valueChanges()
      .pipe(take(1))
      .subscribe(results => {
        results.canEditChapter = true;
        accountProfileRef.update(results);

        account.info.profile = results;
        this.selectedAccountCanEditChapters = true;
      });
  }
}
