import { PageService } from './../../../../services/page.service';
import { ProfessionsService } from './../../../../services/professions.service';
import { ISkill } from './../../../../interfaces/skill';
import { Character } from './../../../../interfaces/character';
import { PrintService } from './../../../../services/print.service';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { SkillsService } from '../../../../services/skills.service';
import { CharacterCardPrintSkillShieldComponent } from './skill-shield/skill-shield.component';
import { CharacterCardPrintSkillGroupComponent } from './skill-group/skill-group.component';
import { CharacterCardPrintProfessionGroupComponent } from './profession-group/profession-group.component';

@Component({
  selector: 'app-character-card-print',
  templateUrl: './print.component.html',
  styleUrls: ['../../../../../print.css', './print.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardPrintComponent implements OnInit, OnChanges {
  @Input() character: Character;
  @ViewChild('print', { static: false }) printElementRef: ElementRef;

  public show = false;

  public skillGroups = {};
  public professions = [];
  public spellSlotTotal = 0;
  public spellSlotMana = 0;
  public spellSlotFocus = 0;
  public yearsServed = 0;

  @ViewChildren(CharacterCardPrintSkillShieldComponent) skillShieldComponents: QueryList<CharacterCardPrintSkillShieldComponent>;
  @ViewChildren(CharacterCardPrintSkillGroupComponent) skillGroupComponent: QueryList<CharacterCardPrintSkillGroupComponent>;
  @ViewChildren(CharacterCardPrintProfessionGroupComponent) professionGroupComponent: QueryList<CharacterCardPrintProfessionGroupComponent>;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _printService: PrintService,
    private _skillsService: SkillsService,
    private _profsService: ProfessionsService,
    public pageService: PageService
  ) { }

  public ngOnInit(): void { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['character'] && changes['character'].currentValue) {
      this.init();
      this.show = true;
    }
  }

  private init(): void {
    this.skillGroups = {};
    const types = [];

    const promises: Promise<any>[] = [];

    promises.push(
      this._skillsService.getAll().then(allSkills => {
        this.character.data.skills.forEach(charSkill => {
          const skill = allSkills.find(s => s.id === charSkill.skillId);
          if (skill) {
            const type = skill.type.toLowerCase();

            if (!this.skillGroups[type]) {
              this.skillGroups[type] = [];
              types.push(type);
            }

            this.skillGroups[type].push({ skill: skill, count: charSkill.count });
          }
        });
      })
    );

    this.professions = [];
    promises.push(
      this._profsService.getAll().then(allProfessions => {
        this.character.data.professions.forEach(charProf => {
          const prof = allProfessions.find(p => p.id === charProf.professionId);
          this.professions.push({ id: prof.id, name: prof.name });
        });
      })
    );

    Promise.all(promises).then(() => {
      types.forEach(t => {
        this.skillGroups[t].sort(this.sortByName);
      });

      this.calculateSpellSlotSummary();
      this.yearsServed = parseInt(this.character.data.citizenship.yearsServed.toString(), 10);

      this.checkAll();
    });
  }

  private calculateSpellSlotSummary(): void {
    this.spellSlotTotal = this.character.spellSlotTotal;
    this.spellSlotMana = this.character.spellSlotMana;
    this.spellSlotFocus = this.character.spellSlotFocus;
  }

  public print(): void {
    this._printService.print(this.character.data.name, this.printElementRef);
  }

  private sortByName(a: IPrintSkill, b: IPrintSkill): number {
    if (a.skill.name < b.skill.name) return -1;
    if (a.skill.name > b.skill.name) return 1;
    return 0;
  }

  public markForCheck(): void {
    this.init();
    // this.checkAll();
  }

  private checkAll(): void {
    this._cdr.markForCheck();

    this.skillShieldComponents.forEach(comp => {
      comp.markForCheck();
    });

    this.skillGroupComponent.forEach(comp => {
      comp.markForCheck();
    });

    this.professionGroupComponent.forEach(comp => {
      comp.markForCheck();
    });
  }
}

export interface IPrintSkill {
  skill: ISkill;
  count: number;
}
