import { ISkill } from './../interfaces/skill';

export class SkillHelper {
  public static getSkillCost(skill: ISkill, nextCount: number): number {
    // We don't have any odd calculation, just result the count factored by the cost.
    if (!skill.costModifier) return skill.cost;

    nextCount--;

    const remainder = nextCount % skill.costModifier.count;
    const steps = (nextCount - remainder) / skill.costModifier.count;
    let skillCost = skill.cost;

    for (let i = 0; i < steps; ++i) {
      skillCost++;
    }

    return skillCost;
  }

  public static getTotalSkillCost(skill: ISkill, currentCount: number): number {
    // We don't have any odd calculation, just result the count factored by the cost.
    if (!skill.costModifier) return skill.cost * currentCount;

    const remainder = currentCount % skill.costModifier.count;
    const steps = (currentCount - remainder) / skill.costModifier.count;
    let skillCost = skill.cost;
    let costTotal = 0;

    for (let i = 0; i < steps; ++i) {
      costTotal += skillCost * skill.costModifier.count;
      skillCost++;
    }

    costTotal += skillCost * remainder;
    return costTotal;
  }

  public static getVerbalText(skill: ISkill, count: number): string {
    if (!skill.verbal) return '';

    let verbalText = skill.verbal.text;

    switch (skill.verbal.type) {
      case 'multiplier': {
        const value = count * skill.verbal.value[0];
        verbalText = verbalText.replace('%count%', value.toString());
        break;
      }
      case 'flurry': {
        skill.verbal.value.forEach((value, i) => {
          const currentValue = count + value;
          verbalText = verbalText.replace(`%count_${i}%`, currentValue.toString());
        });
        break;
      }
      case 'flat': {
        // Do nothing
        break;
      }
    }

    return `"${verbalText}"`;
  }

  public static getPoolText(skill: ISkill, count: number): string {
    if (!skill.pool) return '';

    const poolText = `${skill.pool.type} Pool: ${(skill.pool.multiplier * count)}`;
    return poolText;
  }
}
