import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TooltipTextComponent implements OnInit {
  @Input() text: string;
  @Input() data: any;

  constructor() { }

  ngOnInit() {
  }

}
