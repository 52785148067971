import { v4 as uuid } from 'uuid';

export class UuidHelper {

  constructor() { }

  static uuid(): string {
    return uuid();
  }

  static empty(): string {
    return '00000000-0000-0000-0000-000000000000';
  }

  static isEmpty(value: string): boolean {
    return value === this.empty();
  }
}
