import { ProfessionHelper } from './../../../../helpers/profession.helper';
import { ISkill } from './../../../../interfaces/skill';
import { Subscription } from 'rxjs';
import { CharacterCardSkillSlotComponent } from './../skills/slot/slot.component';
import { IProfession } from './../../../../interfaces/profession';
import { ProfessionsService } from './../../../../services/professions.service';
import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { PageService } from '../../../../services/page.service';
import { Character } from '../../../../interfaces/character';
import { CharacterCardComponent } from '../card.component';
import { CharacterCardProfessionSlotDropdownComponent } from './slot-dropdown/slot-dropdown.component';
import { SkillsService } from '../../../../services/skills.service';

export interface IProfessionTier {
  tier: number;
  cost: number;
  activeProfession?: IProfession;
  skills: ISkill[];
}

@Component({
  selector: 'app-character-card-profession',
  templateUrl: './profession.component.html',
  styleUrls: ['../../../section.scss', '../../../slots.scss', './profession.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharacterCardProfessionComponent implements OnInit, OnDestroy {
  @Output() changeProfessions: EventEmitter<any> = new EventEmitter();

  public character: Character;
  public show = false;

  public professionTiers: IProfessionTier[] = [];
  public maxTier = 0;

  private _parent: CharacterCardComponent;
  private _skillSlotsSub: Subscription;

  @ViewChildren(CharacterCardProfessionSlotDropdownComponent) dropdownSlots: QueryList<CharacterCardProfessionSlotDropdownComponent>;
  @ViewChildren(CharacterCardSkillSlotComponent) skillSlots: QueryList<CharacterCardSkillSlotComponent>;

  constructor(
    public pageService: PageService,
    private _profService: ProfessionsService,
    private _skillsService: SkillsService,
    private _cdr: ChangeDetectorRef
  ) { }

  ngOnInit() { }

  public ngOnDestroy(): void {
    if (this._skillSlotsSub) this._skillSlotsSub.unsubscribe();
  }

  private initTiers(): void {
    // Build our tiers
    this.professionTiers = [];
    for (let i = 1; i <= 4; ++i) {
      this.professionTiers.push({
        tier: i,
        cost: ProfessionHelper.tierCPCosts[i - 1],
        activeProfession: null,
        skills: []
      });
    }
  }

  public updateCharacter(character: Character, parent: CharacterCardComponent): void {
    this.character = character;
    this._parent = parent;
    this.initTiers();
    this.maxTier = this.character.maxProfessionTier;
    this.show = true;

    this._cdr.detectChanges();

    if (this._skillSlotsSub) this._skillSlotsSub.unsubscribe();
    this._skillSlotsSub = this.skillSlots.changes.subscribe(slots => {
      this.updateSkills();
      this._parent.checkPrint();
      this.markForCheck();
    });

    this.updateProfession();
  }

  private updateProfession(): void {
    this.initTiers();
    this._skillsService.getAll().then(allSkills => {
      this.professionTiers.forEach(tier => {
        const charProf = this.character.data.professions.find(p => p.tier === tier.tier);
        if (charProf) {
          const prof = this._profService.allProfessions.find(p => p.id === charProf.professionId);
          if (prof) {
            tier.activeProfession = prof;
            tier.skills = allSkills.filter(s => prof.skills.includes(s.id));
          }
        }
      });

      this._cdr.detectChanges();

      this.updateDropdownSlots();
      this.updateSkills();
      this.markForCheck();
    });
  }

  private updateDropdownSlots(): void {
    this.dropdownSlots.forEach(slot => {
      slot.updateCharacter(this.character, this);
    });
    this.checkDropdownSlots();
  }

  private checkDropdownSlots(): void {
    this.dropdownSlots.forEach(slot => {
      slot.markForCheck();
    });
  }

  private updateSkills(): void {
    this.skillSlots.forEach(slot => {
      slot.updateCharacter(this.character, this);
    });

    this.checkSkillSlots();
  }

  public markForCheck(): void {
    if (!this.character) return;

    this.maxTier = this.character.maxProfessionTier;
    this.checkDropdownSlots();
    this.checkSkillSlots();
    this._cdr.markForCheck();
  }

  public checkSkillSlots(): void {
    this.skillSlots.forEach(slot => {
      slot.markForCheck();
    });
  }

  public requirementsMet(tier: IProfessionTier): boolean {
    // if (this.character.hasAnyProfession(profession.tier)) {
    //   return this.character.hasProfession(profession);
    // }

    return this.character.maxProfessionTier >= tier.tier;
  }

  public getTierText(tier: IProfessionTier): string {
    switch (tier.tier) {
      case 1:
        return 'I';
      case 2:
        return 'II';
      case 3:
        return 'III';
      case 4:
        return 'IV';
    }

    return '';
  }

  public checkDetails(): void {
    this._parent.checkDetails();
  }

  public checkProfessions(): void {
    this._parent.checkProfessions();
  }

  public changeProfession(e: any): void {
    this.changeProfessions.emit();
  }

  public checkPrint(): void {
    this._parent.checkPrint();
  }
}
